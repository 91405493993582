import { AppContext } from "components/app/RemExprertProvider";
import CardBox from "components/ui/controls/card-box/card-box";
import TextField from "components/ui/controls/text-field/text-field";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";
import { applyFormat } from "components/ui/ui-helpers";
import React, { useContext } from "react";

const FormatLigneDepart = ({ keyId, MiseEnForme, Libelle, AgesDepart, InfosDepart, id, id2, id3, id4 }) => {
  let { InfosDepart0, InfosDepart1, InfosDepart2, InfosDepart3, InfosDepart4, InfosDepart5 } = "";
  if (id4.length > 0) {
    InfosDepart0 = InfosDepart[AgesDepart[0]][id][id2][id3][id4];
    InfosDepart1 = InfosDepart[AgesDepart[1]][id][id2][id3][id4];
    InfosDepart2 = InfosDepart[AgesDepart[2]][id][id2][id3][id4];
    InfosDepart3 = InfosDepart[AgesDepart[3]][id][id2][id3][id4];
    InfosDepart4 = InfosDepart[AgesDepart[4]][id][id2][id3][id4];
    InfosDepart5 = InfosDepart[AgesDepart[5]][id][id2][id3][id4];
  } else if (id3.length > 0) {
    InfosDepart0 = InfosDepart[AgesDepart[0]][id][id2][id3];
    InfosDepart1 = InfosDepart[AgesDepart[1]][id][id2][id3];
    InfosDepart2 = InfosDepart[AgesDepart[2]][id][id2][id3];
    InfosDepart3 = InfosDepart[AgesDepart[3]][id][id2][id3];
    InfosDepart4 = InfosDepart[AgesDepart[4]][id][id2][id3];
    InfosDepart5 = InfosDepart[AgesDepart[5]][id][id2][id3];
  } else if (id2.length > 0) {
    InfosDepart0 = InfosDepart[AgesDepart[0]][id][id2];
    InfosDepart1 = InfosDepart[AgesDepart[1]][id][id2];
    InfosDepart2 = InfosDepart[AgesDepart[2]][id][id2];
    InfosDepart3 = InfosDepart[AgesDepart[3]][id][id2];
    InfosDepart4 = InfosDepart[AgesDepart[4]][id][id2];
    InfosDepart5 = InfosDepart[AgesDepart[5]][id][id2];
  } else {
    InfosDepart0 = InfosDepart[AgesDepart[0]][id];
    InfosDepart1 = InfosDepart[AgesDepart[1]][id];
    InfosDepart2 = InfosDepart[AgesDepart[2]][id];
    InfosDepart3 = InfosDepart[AgesDepart[3]][id];
    InfosDepart4 = InfosDepart[AgesDepart[4]][id];
    InfosDepart5 = InfosDepart[AgesDepart[5]][id];
  }
  return (
    <tr className={MiseEnForme} key={"DetaiDepart" + keyId}>
      <td>{Libelle}</td>
      <td className="text-center">{applyFormat(Math.max(0, InfosDepart0), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
      <td className="text-center">{applyFormat(Math.max(0, InfosDepart1), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
      <td className="text-center">{applyFormat(Math.max(0, InfosDepart2), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
      <td className="text-center">{applyFormat(Math.max(0, InfosDepart3), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
      <td className="text-center">{applyFormat(Math.max(0, InfosDepart4), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
      <td className="text-center">{applyFormat(Math.max(0, InfosDepart5), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
    </tr>
  );
};

//LA FONCTION QUI CALCULE LES PRESTATS SUIVANT L'AGE SE TROUVE DANS LE USEFFECT de resultat.js
const DetailDepart = ({ InfosDepart, ClientConjoint }) => {
  var AgesDepart = Object.keys(InfosDepart);
  let index = 0;

  const context = useContext(AppContext);
  const TexteProjection = context.state.AgeDepartRetraite.toString().slice(2);

  return (
    <Column fill>
      <CardBox>
        <Column fill>
          <Row>
            <Column style={{ alignSelf: "center" }}>De </Column>
            <Column>
              <TextField value={context.state["AgeDepartProjection" + ClientConjoint]} onChange={context.handleInputChange("AgeDepartProjection" + ClientConjoint)} xs textAlign="center" />
            </Column>
            <Column style={{ alignSelf: "center" }}>
              {TexteProjection} à {Number(context.state["AgeDepartProjection" + ClientConjoint]) + 5} {TexteProjection}{" "}
            </Column>
          </Row>
          <Row tpad>
            <table>
              <thead>
                <tr key={"DetaiDepart" + index++} className="bg-primary text-white">
                  <th>Départ à l’âge de</th>
                  <th className="text-center">{InfosDepart[AgesDepart[0]].Infos.AgeDepartRetraite}</th>
                  <th className="text-center">{InfosDepart[AgesDepart[1]].Infos.AgeDepartRetraite}</th>
                  <th className="text-center">{InfosDepart[AgesDepart[2]].Infos.AgeDepartRetraite}</th>
                  <th className="text-center">{InfosDepart[AgesDepart[3]].Infos.AgeDepartRetraite}</th>
                  <th className="text-center">{InfosDepart[AgesDepart[4]].Infos.AgeDepartRetraite}</th>
                  <th className="text-center">{InfosDepart[AgesDepart[5]].Infos.AgeDepartRetraite}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td key={"DetaiDepart" + index++}>Départ le 01/{InfosDepart[AgesDepart[0]].Infos.MoisDepartRetraite}</td>
                  <td className="text-center">{AgesDepart[0]}</td>
                  <td className="text-center">{AgesDepart[1]}</td>
                  <td className="text-center">{AgesDepart[2]}</td>
                  <td className="text-center">{AgesDepart[3]}</td>
                  <td className="text-center">{AgesDepart[4]}</td>
                  <td className="text-center">{AgesDepart[5]}</td>
                </tr>
                <tr>
                  <td key={"DetaiDepart" + index++}>Trimestres</td>
                  <td className="text-center">{InfosDepart[AgesDepart[0]].InfosRetraite.General.TotalTrimestre}</td>
                  <td className="text-center">{InfosDepart[AgesDepart[1]].InfosRetraite.General.TotalTrimestre}</td>
                  <td className="text-center">{InfosDepart[AgesDepart[2]].InfosRetraite.General.TotalTrimestre}</td>
                  <td className="text-center">{InfosDepart[AgesDepart[3]].InfosRetraite.General.TotalTrimestre}</td>
                  <td className="text-center">{InfosDepart[AgesDepart[4]].InfosRetraite.General.TotalTrimestre}</td>
                  <td className="text-center">{InfosDepart[AgesDepart[5]].InfosRetraite.General.TotalTrimestre}</td>
                </tr>
                <FormatLigneDepart
                  keyId={index++}
                  MiseEnForme={"dark-title"}
                  Libelle={"Pensions brutes"}
                  AgesDepart={AgesDepart}
                  InfosDepart={InfosDepart}
                  id={"TotalPensionRenteBrute"}
                  id2={""}
                  id3={""}
                  id4={""}
                />
                <FormatLigneDepart
                  keyId={index++}
                  MiseEnForme={"light-text"}
                  Libelle={"Régimes de base"}
                  AgesDepart={AgesDepart}
                  InfosDepart={InfosDepart}
                  id={"TotalPensionBase"}
                  id2={""}
                  id3={""}
                  id4={""}
                />
                {"RegimeUnifie" in InfosDepart[AgesDepart[0]].InfosRetraite && (
                  <FormatLigneDepart
                    keyId={index++}
                    MiseEnForme={""}
                    Libelle={"Régime unifié"}
                    AgesDepart={AgesDepart}
                    InfosDepart={InfosDepart}
                    id={"InfosRetraite"}
                    id2={"RegimeUnifie"}
                    id3={"Pension"}
                    id4={""}
                  />
                )}
                {"Cnavpl" in InfosDepart[AgesDepart[0]].InfosRetraite && (
                  <FormatLigneDepart
                    keyId={index++}
                    MiseEnForme={""}
                    Libelle={"CNAVPL"}
                    AgesDepart={AgesDepart}
                    InfosDepart={InfosDepart}
                    id={"InfosRetraite"}
                    id2={"Cnavpl"}
                    id3={"General"}
                    id4={"Pension"}
                  />
                )}
                {"FonctionPubliqueBase" in InfosDepart[AgesDepart[0]].InfosRetraite &&
                  (InfosDepart[AgesDepart[0]].InfosRetraite["FonctionPubliqueBase"]["General"]["Pension"] +
                    InfosDepart[AgesDepart[1]].InfosRetraite["FonctionPubliqueBase"]["General"]["Pension"] +
                    InfosDepart[AgesDepart[2]].InfosRetraite["FonctionPubliqueBase"]["General"]["Pension"] +
                    InfosDepart[AgesDepart[3]].InfosRetraite["FonctionPubliqueBase"]["General"]["Pension"] +
                    InfosDepart[AgesDepart[4]].InfosRetraite["FonctionPubliqueBase"]["General"]["Pension"] +
                    InfosDepart[AgesDepart[5]].InfosRetraite["FonctionPubliqueBase"]["General"]["Pension"] >
                  0 ? (
                    <FormatLigneDepart
                      keyId={index++}
                      MiseEnForme={""}
                      Libelle={"Fonction publique"}
                      AgesDepart={AgesDepart}
                      InfosDepart={InfosDepart}
                      id={"InfosRetraite"}
                      id2={"FonctionPubliqueBase"}
                      id3={"General"}
                      id4={"Pension"}
                    />
                  ) : (
                    ""
                  ))}
                {"Crpcen" in InfosDepart[AgesDepart[0]].InfosRetraite && (
                  <FormatLigneDepart
                    keyId={index++}
                    MiseEnForme={""}
                    Libelle={"CRPCEN"}
                    AgesDepart={AgesDepart}
                    InfosDepart={InfosDepart}
                    id={"InfosRetraite"}
                    id2={"Crpcen"}
                    id3={"General"}
                    id4={"Pension"}
                  />
                )}
                {"Cnbf" in InfosDepart[AgesDepart[0]].InfosRetraite && (
                  <FormatLigneDepart
                    keyId={index++}
                    MiseEnForme={""}
                    Libelle={"CNBF"}
                    AgesDepart={AgesDepart}
                    InfosDepart={InfosDepart}
                    id={"InfosRetraite"}
                    id2={"Cnbf"}
                    id3={"General"}
                    id4={"Pension"}
                  />
                )}
                {"Msa" in InfosDepart[AgesDepart[0]].InfosRetraite && (
                  <FormatLigneDepart
                    keyId={index++}
                    MiseEnForme={""}
                    Libelle={"MSA"}
                    AgesDepart={AgesDepart}
                    InfosDepart={InfosDepart}
                    id={"InfosRetraite"}
                    id2={"Msa"}
                    id3={"General"}
                    id4={"PensionBase"}
                  />
                )}
                <FormatLigneDepart
                  keyId={index++}
                  MiseEnForme={"light-text"}
                  Libelle={"Régimes complémentaires"}
                  AgesDepart={AgesDepart}
                  InfosDepart={InfosDepart}
                  id={"TotalPensionComplementaire"}
                  id2={""}
                  id3={""}
                  id4={""}
                />
                {"Salarie" in InfosDepart[AgesDepart[0]].InfosRetraite && (
                  <React.Fragment>
                    {InfosDepart[AgesDepart[0]].InfosRetraite["Salarie"]["General"]["CapitalAgircArrco"] === 0 ||
                    InfosDepart[AgesDepart[1]].InfosRetraite["Salarie"]["General"]["CapitalAgircArrco"] === 0 ||
                    InfosDepart[AgesDepart[2]].InfosRetraite["Salarie"]["General"]["CapitalAgircArrco"] === 0 ||
                    InfosDepart[AgesDepart[3]].InfosRetraite["Salarie"]["General"]["CapitalAgircArrco"] === 0 ||
                    InfosDepart[AgesDepart[4]].InfosRetraite["Salarie"]["General"]["CapitalAgircArrco"] === 0 ||
                    InfosDepart[AgesDepart[5]].InfosRetraite["Salarie"]["General"]["CapitalAgircArrco"] === 0 ? (
                      <FormatLigneDepart
                        keyId={index++}
                        MiseEnForme={""}
                        Libelle={"AGIRC-ARRCO"}
                        AgesDepart={AgesDepart}
                        InfosDepart={InfosDepart}
                        id={"InfosRetraite"}
                        id2={"Salarie"}
                        id3={"General"}
                        id4={"AgircArrcoPensionAvecMajo"}
                      />
                    ) : (
                      ""
                    )}
                  </React.Fragment>
                )}
                {"Ircantec" in InfosDepart[AgesDepart[0]].InfosRetraite && (
                  <React.Fragment>
                    {InfosDepart[AgesDepart[0]].InfosRetraite["Ircantec"]["General"]["CapitalIrcantec"] === 0 ||
                    InfosDepart[AgesDepart[1]].InfosRetraite["Ircantec"]["General"]["CapitalIrcantec"] === 0 ||
                    InfosDepart[AgesDepart[2]].InfosRetraite["Ircantec"]["General"]["CapitalIrcantec"] === 0 ||
                    InfosDepart[AgesDepart[3]].InfosRetraite["Ircantec"]["General"]["CapitalIrcantec"] === 0 ||
                    InfosDepart[AgesDepart[4]].InfosRetraite["Ircantec"]["General"]["CapitalIrcantec"] === 0 ||
                    InfosDepart[AgesDepart[5]].InfosRetraite["Ircantec"]["General"]["CapitalIrcantec"] === 0 ? (
                      <FormatLigneDepart
                        keyId={index++}
                        MiseEnForme={""}
                        Libelle={"IRCANTEC"}
                        AgesDepart={AgesDepart}
                        InfosDepart={InfosDepart}
                        id={"InfosRetraite"}
                        id2={"Ircantec"}
                        id3={"General"}
                        id4={"Pension"}
                      />
                    ) : (
                      ""
                    )}
                  </React.Fragment>
                )}
                {"RsiCommercant" in InfosDepart[AgesDepart[0]].InfosRetraite && (
                  <FormatLigneDepart
                    keyId={index++}
                    MiseEnForme={""}
                    Libelle={"RSI Commercant"}
                    AgesDepart={AgesDepart}
                    InfosDepart={InfosDepart}
                    id={"InfosRetraite"}
                    id2={"RsiCommercant"}
                    id3={"General"}
                    id4={"Pension"}
                  />
                )}
                {"RsiArtisan" in InfosDepart[AgesDepart[0]].InfosRetraite && (
                  <FormatLigneDepart
                    keyId={index++}
                    MiseEnForme={""}
                    Libelle={"RSI Artisan"}
                    AgesDepart={AgesDepart}
                    InfosDepart={InfosDepart}
                    id={"InfosRetraite"}
                    id2={"RsiArtisan"}
                    id3={"General"}
                    id4={"Pension"}
                  />
                )}
                {"RCI" in InfosDepart[AgesDepart[0]].InfosRetraite && (
                  <FormatLigneDepart
                    keyId={index++}
                    MiseEnForme={""}
                    Libelle={"RCI"}
                    AgesDepart={AgesDepart}
                    InfosDepart={InfosDepart}
                    id={"InfosRetraite"}
                    id2={"RCI"}
                    id3={"General"}
                    id4={"Pension"}
                  />
                )}
                {"Cipav" in InfosDepart[AgesDepart[0]].InfosRetraite && (
                  <FormatLigneDepart
                    keyId={index++}
                    MiseEnForme={""}
                    Libelle={"CIPAV"}
                    AgesDepart={AgesDepart}
                    InfosDepart={InfosDepart}
                    id={"InfosRetraite"}
                    id2={"Cipav"}
                    id3={"General"}
                    id4={"Pension"}
                  />
                )}
                {"Cavec" in InfosDepart[AgesDepart[0]].InfosRetraite && (
                  <FormatLigneDepart
                    keyId={index++}
                    MiseEnForme={""}
                    Libelle={"CAVEC"}
                    AgesDepart={AgesDepart}
                    InfosDepart={InfosDepart}
                    id={"InfosRetraite"}
                    id2={"Cavec"}
                    id3={"General"}
                    id4={"Pension"}
                  />
                )}
                {"Cavamac" in InfosDepart[AgesDepart[0]].InfosRetraite && (
                  <FormatLigneDepart
                    keyId={index++}
                    MiseEnForme={""}
                    Libelle={"CAVAMAC"}
                    AgesDepart={AgesDepart}
                    InfosDepart={InfosDepart}
                    id={"InfosRetraite"}
                    id2={"Cavamac"}
                    id3={"General"}
                    id4={"Pension"}
                  />
                )}
                {"Carpv" in InfosDepart[AgesDepart[0]].InfosRetraite && (
                  <FormatLigneDepart
                    keyId={index++}
                    MiseEnForme={""}
                    Libelle={"CARPV"}
                    AgesDepart={AgesDepart}
                    InfosDepart={InfosDepart}
                    id={"InfosRetraite"}
                    id2={"Carpv"}
                    id3={"General"}
                    id4={"Pension"}
                  />
                )}
                {"Carpimko" in InfosDepart[AgesDepart[0]].InfosRetraite && (
                  <FormatLigneDepart
                    keyId={index++}
                    MiseEnForme={""}
                    Libelle={"CARPIMKO"}
                    AgesDepart={AgesDepart}
                    InfosDepart={InfosDepart}
                    id={"InfosRetraite"}
                    id2={"Carpimko"}
                    id3={"General"}
                    id4={"Pension"}
                  />
                )}
                {"Crn" in InfosDepart[AgesDepart[0]].InfosRetraite && (
                  <FormatLigneDepart
                    keyId={index++}
                    MiseEnForme={""}
                    Libelle={"CRN"}
                    AgesDepart={AgesDepart}
                    InfosDepart={InfosDepart}
                    id={"InfosRetraite"}
                    id2={"Crn"}
                    id3={"General"}
                    id4={"Pension"}
                  />
                )}
                {"Carmf" in InfosDepart[AgesDepart[0]].InfosRetraite && (
                  <FormatLigneDepart
                    keyId={index++}
                    MiseEnForme={""}
                    Libelle={"CARMF"}
                    AgesDepart={AgesDepart}
                    InfosDepart={InfosDepart}
                    id={"InfosRetraite"}
                    id2={"Carmf"}
                    id3={"General"}
                    id4={"Pension"}
                  />
                )}
                {"Carcdsf" in InfosDepart[AgesDepart[0]].InfosRetraite && (
                  <FormatLigneDepart
                    keyId={index++}
                    MiseEnForme={""}
                    Libelle={"CARCD"}
                    AgesDepart={AgesDepart}
                    InfosDepart={InfosDepart}
                    id={"InfosRetraite"}
                    id2={"Carcdsf"}
                    id3={"General"}
                    id4={"Pension"}
                  />
                )}
                {"Carcdsf2" in InfosDepart[AgesDepart[0]].InfosRetraite && (
                  <FormatLigneDepart
                    keyId={index++}
                    MiseEnForme={""}
                    Libelle={"CARSF"}
                    AgesDepart={AgesDepart}
                    InfosDepart={InfosDepart}
                    id={"InfosRetraite"}
                    id2={"Carcdsf2"}
                    id3={"General"}
                    id4={"Pension"}
                  />
                )}
                {"Cavom" in InfosDepart[AgesDepart[0]].InfosRetraite && (
                  <FormatLigneDepart
                    keyId={index++}
                    MiseEnForme={""}
                    Libelle={"CAVOM"}
                    AgesDepart={AgesDepart}
                    InfosDepart={InfosDepart}
                    id={"InfosRetraite"}
                    id2={"Cavom"}
                    id3={"General"}
                    id4={"Pension"}
                  />
                )}
                {"Cavp" in InfosDepart[AgesDepart[0]].InfosRetraite && (
                  <FormatLigneDepart
                    keyId={index++}
                    MiseEnForme={""}
                    Libelle={"CAVP par capitalisation"}
                    AgesDepart={AgesDepart}
                    InfosDepart={InfosDepart}
                    id={"InfosRetraite"}
                    id2={"Cavp"}
                    id3={"General"}
                    id4={"PensionCapi"}
                  />
                )}
                {"Cavp" in InfosDepart[AgesDepart[0]].InfosRetraite && (
                  <FormatLigneDepart
                    keyId={index++}
                    MiseEnForme={""}
                    Libelle={"CAVP par répartition"}
                    AgesDepart={AgesDepart}
                    InfosDepart={InfosDepart}
                    id={"InfosRetraite"}
                    id2={"Cavp"}
                    id3={"General"}
                    id4={"PensionRepart"}
                  />
                )}
                {"Rafp" in InfosDepart[AgesDepart[0]].InfosRetraite && (
                  <React.Fragment>
                    {InfosDepart[AgesDepart[0]].InfosRetraite["Rafp"]["General"]["TransformationCapitalRafp"] === "Non" ||
                    InfosDepart[AgesDepart[1]].InfosRetraite["Rafp"]["General"]["TransformationCapitalRafp"] === "Non" ||
                    InfosDepart[AgesDepart[2]].InfosRetraite["Rafp"]["General"]["TransformationCapitalRafp"] === "Non" ||
                    InfosDepart[AgesDepart[3]].InfosRetraite["Rafp"]["General"]["TransformationCapitalRafp"] === "Non" ||
                    InfosDepart[AgesDepart[4]].InfosRetraite["Rafp"]["General"]["TransformationCapitalRafp"] === "Non" ||
                    InfosDepart[AgesDepart[5]].InfosRetraite["Rafp"]["General"]["TransformationCapitalRafp"] === "Non" ? (
                      <FormatLigneDepart
                        keyId={index++}
                        MiseEnForme={""}
                        Libelle={"RAFP"}
                        AgesDepart={AgesDepart}
                        InfosDepart={InfosDepart}
                        id={"InfosRetraite"}
                        id2={"Rafp"}
                        id3={"General"}
                        id4={"Pension"}
                      />
                    ) : (
                      ""
                    )}
                  </React.Fragment>
                )}
                {"Cnbf" in InfosDepart[AgesDepart[0]].InfosRetraite && (
                  <FormatLigneDepart
                    keyId={index++}
                    MiseEnForme={""}
                    Libelle={"CNBF"}
                    AgesDepart={AgesDepart}
                    InfosDepart={InfosDepart}
                    id={"InfosRetraite"}
                    id2={"Cnbf"}
                    id3={"General"}
                    id4={"PensionComp"}
                  />
                )}
                {"Msa" in InfosDepart[AgesDepart[0]].InfosRetraite && (
                  <FormatLigneDepart
                    keyId={index++}
                    MiseEnForme={""}
                    Libelle={"MSA RCO"}
                    AgesDepart={AgesDepart}
                    InfosDepart={InfosDepart}
                    id={"InfosRetraite"}
                    id2={"Msa"}
                    id3={"General"}
                    id4={"PensionComp"}
                  />
                )}
                {InfosDepart[AgesDepart[0]].TotalPensionSupplementaire +
                  InfosDepart[AgesDepart[1]].TotalPensionSupplementaire +
                  InfosDepart[AgesDepart[2]].TotalPensionSupplementaire +
                  InfosDepart[AgesDepart[3]].TotalPensionSupplementaire +
                  InfosDepart[AgesDepart[4]].TotalPensionSupplementaire +
                  InfosDepart[AgesDepart[5]].TotalPensionSupplementaire >
                  0 && (
                  <React.Fragment>
                    <FormatLigneDepart
                      keyId={index++}
                      MiseEnForme={"light-text"}
                      Libelle={"Régimes supplémentaires"}
                      AgesDepart={AgesDepart}
                      InfosDepart={InfosDepart}
                      id={"TotalPensionSupplementaire"}
                      id2={""}
                      id3={""}
                      id4={""}
                    />
                    {"Carpimko" in InfosDepart[AgesDepart[0]].InfosRetraite && (
                      <React.Fragment>
                        {InfosDepart[AgesDepart[0]].InfosRetraite["Carpimko"]["General"]["AsvPension"] +
                          InfosDepart[AgesDepart[1]].InfosRetraite["Carpimko"]["General"]["AsvPension"] +
                          InfosDepart[AgesDepart[2]].InfosRetraite["Carpimko"]["General"]["AsvPension"] +
                          InfosDepart[AgesDepart[3]].InfosRetraite["Carpimko"]["General"]["AsvPension"] +
                          InfosDepart[AgesDepart[4]].InfosRetraite["Carpimko"]["General"]["AsvPension"] +
                          InfosDepart[AgesDepart[5]].InfosRetraite["Carpimko"]["General"]["AsvPension"] >
                        0 ? (
                          <FormatLigneDepart
                            keyId={index++}
                            MiseEnForme={""}
                            Libelle={"CARPIMKO ASV"}
                            AgesDepart={AgesDepart}
                            InfosDepart={InfosDepart}
                            id={"InfosRetraite"}
                            id2={"Carpimko"}
                            id3={"General"}
                            id4={"AsvPension"}
                          />
                        ) : (
                          ""
                        )}
                      </React.Fragment>
                    )}
                    {"Carmf" in InfosDepart[AgesDepart[0]].InfosRetraite && (
                      <React.Fragment>
                        {InfosDepart[AgesDepart[0]].InfosRetraite["Carmf"]["General"]["AsvPension"] +
                          InfosDepart[AgesDepart[1]].InfosRetraite["Carmf"]["General"]["AsvPension"] +
                          InfosDepart[AgesDepart[2]].InfosRetraite["Carmf"]["General"]["AsvPension"] +
                          InfosDepart[AgesDepart[3]].InfosRetraite["Carmf"]["General"]["AsvPension"] +
                          InfosDepart[AgesDepart[4]].InfosRetraite["Carmf"]["General"]["AsvPension"] +
                          InfosDepart[AgesDepart[5]].InfosRetraite["Carmf"]["General"]["AsvPension"] >
                        0 ? (
                          <FormatLigneDepart
                            keyId={index++}
                            MiseEnForme={""}
                            Libelle={"CARMF ASV"}
                            AgesDepart={AgesDepart}
                            InfosDepart={InfosDepart}
                            id={"InfosRetraite"}
                            id2={"Carmf"}
                            id3={"General"}
                            id4={"AsvPension"}
                          />
                        ) : (
                          ""
                        )}
                      </React.Fragment>
                    )}
                    {"Carcdsf" in InfosDepart[AgesDepart[0]].InfosRetraite && (
                      <React.Fragment>
                        {InfosDepart[AgesDepart[0]].InfosRetraite["Carcdsf"]["General"]["AsvPension"] +
                          InfosDepart[AgesDepart[1]].InfosRetraite["Carcdsf"]["General"]["AsvPension"] +
                          InfosDepart[AgesDepart[2]].InfosRetraite["Carcdsf"]["General"]["AsvPension"] +
                          InfosDepart[AgesDepart[3]].InfosRetraite["Carcdsf"]["General"]["AsvPension"] +
                          InfosDepart[AgesDepart[4]].InfosRetraite["Carcdsf"]["General"]["AsvPension"] +
                          InfosDepart[AgesDepart[5]].InfosRetraite["Carcdsf"]["General"]["AsvPension"] >
                        0 ? (
                          <FormatLigneDepart
                            keyId={index++}
                            MiseEnForme={""}
                            Libelle={"CARCD PCV"}
                            AgesDepart={AgesDepart}
                            InfosDepart={InfosDepart}
                            id={"InfosRetraite"}
                            id2={"Carcdsf"}
                            id3={"General"}
                            id4={"AsvPension"}
                          />
                        ) : (
                          ""
                        )}
                      </React.Fragment>
                    )}
                    {"Carcdsf2" in InfosDepart[AgesDepart[0]].InfosRetraite && (
                      <React.Fragment>
                        {InfosDepart[AgesDepart[0]].InfosRetraite["Carcdsf2"]["General"]["AsvPension"] +
                          InfosDepart[AgesDepart[1]].InfosRetraite["Carcdsf2"]["General"]["AsvPension"] +
                          InfosDepart[AgesDepart[2]].InfosRetraite["Carcdsf2"]["General"]["AsvPension"] +
                          InfosDepart[AgesDepart[3]].InfosRetraite["Carcdsf2"]["General"]["AsvPension"] +
                          InfosDepart[AgesDepart[4]].InfosRetraite["Carcdsf2"]["General"]["AsvPension"] +
                          InfosDepart[AgesDepart[5]].InfosRetraite["Carcdsf2"]["General"]["AsvPension"] >
                        0 ? (
                          <FormatLigneDepart
                            keyId={index++}
                            MiseEnForme={""}
                            Libelle={"CARSF PCV"}
                            AgesDepart={AgesDepart}
                            InfosDepart={InfosDepart}
                            id={"InfosRetraite"}
                            id2={"Carcdsf2"}
                            id3={"General"}
                            id4={"AsvPension"}
                          />
                        ) : (
                          ""
                        )}
                      </React.Fragment>
                    )}
                    {"Cavp" in InfosDepart[AgesDepart[0]].InfosRetraite && (
                      <React.Fragment>
                        {InfosDepart[AgesDepart[0]].InfosRetraite["Cavp"]["General"]["AsvPension"] +
                          InfosDepart[AgesDepart[1]].InfosRetraite["Cavp"]["General"]["AsvPension"] +
                          InfosDepart[AgesDepart[2]].InfosRetraite["Cavp"]["General"]["AsvPension"] +
                          InfosDepart[AgesDepart[3]].InfosRetraite["Cavp"]["General"]["AsvPension"] +
                          InfosDepart[AgesDepart[4]].InfosRetraite["Cavp"]["General"]["AsvPension"] +
                          InfosDepart[AgesDepart[5]].InfosRetraite["Cavp"]["General"]["AsvPension"] >
                        0 ? (
                          <FormatLigneDepart
                            keyId={index++}
                            MiseEnForme={""}
                            Libelle={"CAVP PCV"}
                            AgesDepart={AgesDepart}
                            InfosDepart={InfosDepart}
                            id={"InfosRetraite"}
                            id2={"Cavp"}
                            id3={"General"}
                            id4={"AsvPension"}
                          />
                        ) : (
                          ""
                        )}
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )}
                {InfosDepart[AgesDepart[0]].TotalRente +
                  InfosDepart[AgesDepart[1]].TotalRente +
                  InfosDepart[AgesDepart[2]].TotalRente +
                  InfosDepart[AgesDepart[3]].TotalRente +
                  InfosDepart[AgesDepart[4]].TotalRente +
                  InfosDepart[AgesDepart[5]].TotalRente >
                  0 && (
                  <React.Fragment>
                    <FormatLigneDepart
                      keyId={index++}
                      MiseEnForme={"light-text"}
                      Libelle={"Régimes facultatifs"}
                      AgesDepart={AgesDepart}
                      InfosDepart={InfosDepart}
                      id={"TotalRente"}
                      id2={""}
                      id3={""}
                      id4={""}
                    />
                    {InfosDepart[AgesDepart[0]].ListeContrat.map((data, i) => {
                      if (data.Sortie === "RenteSimple" || data.Sortie === "Panachage" || data.Sortie === "RenteCapital") {
                        return (
                          <FormatLigneDepart
                            keyId={index++}
                            MiseEnForme={""}
                            Libelle={data.Compagnie}
                            AgesDepart={AgesDepart}
                            InfosDepart={InfosDepart}
                            id={"ListeContrat"}
                            id2={i}
                            id3={"Rente"}
                            id4={""}
                          />
                        );
                      } else {
                        return "";
                      }
                    })}
                  </React.Fragment>
                )}
                <FormatLigneDepart
                  keyId={index++}
                  MiseEnForme={"dark-title"}
                  Libelle={"Pensions nettes"}
                  AgesDepart={AgesDepart}
                  InfosDepart={InfosDepart}
                  id={"TotalPensionRenteNette"}
                  id2={""}
                  id3={""}
                  id4={""}
                />
                {InfosDepart[AgesDepart[0]].TotalCapitalFractionne +
                  InfosDepart[AgesDepart[1]].TotalCapitalFractionne +
                  InfosDepart[AgesDepart[2]].TotalCapitalFractionne +
                  InfosDepart[AgesDepart[3]].TotalCapitalFractionne +
                  InfosDepart[AgesDepart[4]].TotalCapitalFractionne +
                  InfosDepart[AgesDepart[5]].TotalCapitalFractionne >
                  0 && (
                  <React.Fragment>
                    <tr>
                      <td key={"DetaiDepart" + index++} className="align-middle text-primary">
                        Capitaux retraite nets
                      </td>
                      <td className="text-center align-middle text-primary">
                        {applyFormat(Math.max(0, InfosDepart[AgesDepart[0]].TotalCapitalFractionne), "uint", { thousands: " ", decimal: ",", unit: " €" })}
                      </td>
                      <td className="text-center align-middle text-primary">
                        {applyFormat(Math.max(0, InfosDepart[AgesDepart[1]].TotalCapitalFractionne), "uint", { thousands: " ", decimal: ",", unit: " €" })}
                      </td>
                      <td className="text-center align-middle text-primary">
                        {applyFormat(Math.max(0, InfosDepart[AgesDepart[2]].TotalCapitalFractionne), "uint", { thousands: " ", decimal: ",", unit: " €" })}
                      </td>
                      <td className="text-center align-middle text-primary">
                        {applyFormat(Math.max(0, InfosDepart[AgesDepart[3]].TotalCapitalFractionne), "uint", { thousands: " ", decimal: ",", unit: " €" })}
                      </td>
                      <td className="text-center align-middle text-primary">
                        {applyFormat(Math.max(0, InfosDepart[AgesDepart[4]].TotalCapitalFractionne), "uint", { thousands: " ", decimal: ",", unit: " €" })}
                      </td>
                      <td className="text-center align-middle text-primary">
                        {applyFormat(Math.max(0, InfosDepart[AgesDepart[5]].TotalCapitalFractionne), "uint", { thousands: " ", decimal: ",", unit: " €" })}
                      </td>
                    </tr>
                    {InfosDepart[AgesDepart[0]].ListeContrat.map((data, i) => {
                      if (data.Sortie === "Capital" || data.Sortie === "Panachage" || data.Sortie === "RenteCapital") {
                        return (
                          <FormatLigneDepart
                            keyId={index++}
                            MiseEnForme={""}
                            Libelle={data.Compagnie}
                            AgesDepart={AgesDepart}
                            InfosDepart={InfosDepart}
                            id={"ListeContrat"}
                            id2={i}
                            id3={"Capital"}
                            id4={""}
                          />
                        );
                      }
                    })}
                  </React.Fragment>
                )}
              </tbody>
            </table>
          </Row>
        </Column>
      </CardBox>
    </Column>
  );
};

export default DetailDepart;
