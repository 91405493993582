import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";
import { applyFormat } from "components/ui/ui-helpers";
import React from "react";

const FormatLigneDepart = ({ keyId, MiseEnForme, Libelle, AgesDepart, InfosDepart, id, id2, id3, id4 }) => {
  let { InfosDepart0, InfosDepart1, InfosDepart2, InfosDepart3, InfosDepart4, InfosDepart5 } = "";
  if (id4.length > 0) {
    InfosDepart0 = InfosDepart[AgesDepart[0]][id][id2][id3][id4];
    InfosDepart1 = InfosDepart[AgesDepart[1]][id][id2][id3][id4];
    InfosDepart2 = InfosDepart[AgesDepart[2]][id][id2][id3][id4];
    InfosDepart3 = InfosDepart[AgesDepart[3]][id][id2][id3][id4];
    InfosDepart4 = InfosDepart[AgesDepart[4]][id][id2][id3][id4];
    InfosDepart5 = InfosDepart[AgesDepart[5]][id][id2][id3][id4];
  } else if (id3.length > 0) {
    InfosDepart0 = InfosDepart[AgesDepart[0]][id][id2][id3];
    InfosDepart1 = InfosDepart[AgesDepart[1]][id][id2][id3];
    InfosDepart2 = InfosDepart[AgesDepart[2]][id][id2][id3];
    InfosDepart3 = InfosDepart[AgesDepart[3]][id][id2][id3];
    InfosDepart4 = InfosDepart[AgesDepart[4]][id][id2][id3];
    InfosDepart5 = InfosDepart[AgesDepart[5]][id][id2][id3];
  } else if (id2.length > 0) {
    InfosDepart0 = InfosDepart[AgesDepart[0]][id][id2];
    InfosDepart1 = InfosDepart[AgesDepart[1]][id][id2];
    InfosDepart2 = InfosDepart[AgesDepart[2]][id][id2];
    InfosDepart3 = InfosDepart[AgesDepart[3]][id][id2];
    InfosDepart4 = InfosDepart[AgesDepart[4]][id][id2];
    InfosDepart5 = InfosDepart[AgesDepart[5]][id][id2];
  } else {
    InfosDepart0 = InfosDepart[AgesDepart[0]][id];
    InfosDepart1 = InfosDepart[AgesDepart[1]][id];
    InfosDepart2 = InfosDepart[AgesDepart[2]][id];
    InfosDepart3 = InfosDepart[AgesDepart[3]][id];
    InfosDepart4 = InfosDepart[AgesDepart[4]][id];
    InfosDepart5 = InfosDepart[AgesDepart[5]][id];
  }
  return (
    <tr className={MiseEnForme} key={"DetaiDepart" + keyId}>
      <td>{Libelle}</td>
      <td className="text-center">{applyFormat(Math.max(0, InfosDepart0), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
      <td className="text-center">{applyFormat(Math.max(0, InfosDepart1), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
      <td className="text-center">{applyFormat(Math.max(0, InfosDepart2), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
      <td className="text-center">{applyFormat(Math.max(0, InfosDepart3), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
      <td className="text-center">{applyFormat(Math.max(0, InfosDepart4), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
      <td className="text-center">{applyFormat(Math.max(0, InfosDepart5), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
    </tr>
  );
};

const DetailReversion = ({ InfosDepart }) => {
  var AgesDepart = Object.keys(InfosDepart);
  let index = 0;
  return (
    <Column fill>
      <CardBox>
        <Column fill>
          <Row>
            <table>
              <thead>
                <tr key={"DetaiDepart" + index++}>
                  <th>Départ à l’âge de</th>
                  <th className="text-center">{InfosDepart[AgesDepart[0]].Infos.AgeDepartRetraite}</th>
                  <th className="text-center">{InfosDepart[AgesDepart[1]].Infos.AgeDepartRetraite}</th>
                  <th className="text-center">{InfosDepart[AgesDepart[2]].Infos.AgeDepartRetraite}</th>
                  <th className="text-center">{InfosDepart[AgesDepart[3]].Infos.AgeDepartRetraite}</th>
                  <th className="text-center">{InfosDepart[AgesDepart[4]].Infos.AgeDepartRetraite}</th>
                  <th className="text-center">{InfosDepart[AgesDepart[5]].Infos.AgeDepartRetraite}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td key={"DetaiDepart" + index++}>Départ le 01/{InfosDepart[AgesDepart[0]].Infos.MoisDepartRetraite}</td>
                  <td className="text-center">{AgesDepart[0]}</td>
                  <td className="text-center">{AgesDepart[1]}</td>
                  <td className="text-center">{AgesDepart[2]}</td>
                  <td className="text-center">{AgesDepart[3]}</td>
                  <td className="text-center">{AgesDepart[4]}</td>
                  <td className="text-center">{AgesDepart[5]}</td>
                </tr>
                <FormatLigneDepart
                  keyId={index++}
                  MiseEnForme={"dark-title"}
                  Libelle={"Réversions brutes"}
                  AgesDepart={AgesDepart}
                  InfosDepart={InfosDepart}
                  id={"TotalReversionBruteAvecFacultatif"}
                  id2={""}
                  id3={""}
                  id4={""}
                />
                <FormatLigneDepart
                  keyId={index++}
                  MiseEnForme={"light-text"}
                  Libelle={"Régimes de base"}
                  AgesDepart={AgesDepart}
                  InfosDepart={InfosDepart}
                  id={"TotalReversionBase"}
                  id2={""}
                  id3={""}
                  id4={""}
                />
                {"RegimeUnifie" in InfosDepart[AgesDepart[0]].InfosRetraite && (
                  <FormatLigneDepart
                    keyId={index++}
                    MiseEnForme={""}
                    Libelle={"Régime unifié"}
                    AgesDepart={AgesDepart}
                    InfosDepart={InfosDepart}
                    id={"InfosRetraite"}
                    id2={"RegimeUnifie"}
                    id3={"Reversion"}
                    id4={""}
                  />
                )}
                {"Cnavpl" in InfosDepart[AgesDepart[0]].InfosRetraite && (
                  <FormatLigneDepart
                    keyId={index++}
                    MiseEnForme={""}
                    Libelle={"CNAVPL"}
                    AgesDepart={AgesDepart}
                    InfosDepart={InfosDepart}
                    id={"InfosRetraite"}
                    id2={"Cnavpl"}
                    id3={"General"}
                    id4={"Reversion"}
                  />
                )}
                {"FonctionPubliqueBase" in InfosDepart[AgesDepart[0]].InfosRetraite &&
                  (InfosDepart[AgesDepart[0]].InfosRetraite["FonctionPubliqueBase"]["General"]["Reversion"] +
                    InfosDepart[AgesDepart[1]].InfosRetraite["FonctionPubliqueBase"]["General"]["Reversion"] +
                    InfosDepart[AgesDepart[2]].InfosRetraite["FonctionPubliqueBase"]["General"]["Reversion"] +
                    InfosDepart[AgesDepart[3]].InfosRetraite["FonctionPubliqueBase"]["General"]["Reversion"] +
                    InfosDepart[AgesDepart[4]].InfosRetraite["FonctionPubliqueBase"]["General"]["Reversion"] +
                    InfosDepart[AgesDepart[5]].InfosRetraite["FonctionPubliqueBase"]["General"]["Reversion"] >
                  0 ? (
                    <FormatLigneDepart
                      keyId={index++}
                      MiseEnForme={""}
                      Libelle={"Fonction publique"}
                      AgesDepart={AgesDepart}
                      InfosDepart={InfosDepart}
                      id={"InfosRetraite"}
                      id2={"FonctionPubliqueBase"}
                      id3={"General"}
                      id4={"Reversion"}
                    />
                  ) : (
                    ""
                  ))}
                {"Crpcen" in InfosDepart[AgesDepart[0]].InfosRetraite && (
                  <FormatLigneDepart
                    keyId={index++}
                    MiseEnForme={""}
                    Libelle={"CRPCEN"}
                    AgesDepart={AgesDepart}
                    InfosDepart={InfosDepart}
                    id={"InfosRetraite"}
                    id2={"Crpcen"}
                    id3={"General"}
                    id4={"Reversion"}
                  />
                )}
                {"Cnbf" in InfosDepart[AgesDepart[0]].InfosRetraite && (
                  <FormatLigneDepart
                    keyId={index++}
                    MiseEnForme={""}
                    Libelle={"CNBF"}
                    AgesDepart={AgesDepart}
                    InfosDepart={InfosDepart}
                    id={"InfosRetraite"}
                    id2={"Cnbf"}
                    id3={"General"}
                    id4={"Reversion"}
                  />
                )}
                {"Msa" in InfosDepart[AgesDepart[0]].InfosRetraite && (
                  <FormatLigneDepart
                    keyId={index++}
                    MiseEnForme={""}
                    Libelle={"MSA"}
                    AgesDepart={AgesDepart}
                    InfosDepart={InfosDepart}
                    id={"InfosRetraite"}
                    id2={"Msa"}
                    id3={"General"}
                    id4={"Reversion"}
                  />
                )}
                <FormatLigneDepart
                  keyId={index++}
                  MiseEnForme={"light-text"}
                  Libelle={"Régimes complémentaires"}
                  AgesDepart={AgesDepart}
                  InfosDepart={InfosDepart}
                  id={"TotalReversionComplementaire"}
                  id2={""}
                  id3={""}
                  id4={""}
                />
                {"Salarie" in InfosDepart[AgesDepart[0]].InfosRetraite && (
                  <React.Fragment>
                  {InfosDepart[AgesDepart[0]].InfosRetraite["Salarie"]["General"]["CapitalAgircArrco"] === 0 ||
                  InfosDepart[AgesDepart[1]].InfosRetraite["Salarie"]["General"]["CapitalAgircArrco"] === 0 ||
                  InfosDepart[AgesDepart[2]].InfosRetraite["Salarie"]["General"]["CapitalAgircArrco"] === 0 ||
                  InfosDepart[AgesDepart[3]].InfosRetraite["Salarie"]["General"]["CapitalAgircArrco"] === 0 ||
                  InfosDepart[AgesDepart[4]].InfosRetraite["Salarie"]["General"]["CapitalAgircArrco"] === 0 ||
                  InfosDepart[AgesDepart[5]].InfosRetraite["Salarie"]["General"]["CapitalAgircArrco"] === 0 ? (
                    <FormatLigneDepart
                    keyId={index++}
                    MiseEnForme={""}
                    Libelle={"AGIRC-ARRCO"}
                    AgesDepart={AgesDepart}
                    InfosDepart={InfosDepart}
                    id={"InfosRetraite"}
                    id2={"Salarie"}
                    id3={"General"}
                    id4={"AgircArrcoReversionAvecMajo"}
                  />
                  ) : (
                    ""
                  )}
                </React.Fragment>
                )}
                {"Ircantec" in InfosDepart[AgesDepart[0]].InfosRetraite && (
                  <React.Fragment>
                    {InfosDepart[AgesDepart[0]].InfosRetraite["Ircantec"]["General"]["CapitalIrcantec"] === 0 ||
                    InfosDepart[AgesDepart[1]].InfosRetraite["Ircantec"]["General"]["CapitalIrcantec"] === 0 ||
                    InfosDepart[AgesDepart[2]].InfosRetraite["Ircantec"]["General"]["CapitalIrcantec"] === 0 ||
                    InfosDepart[AgesDepart[3]].InfosRetraite["Ircantec"]["General"]["CapitalIrcantec"] === 0 ||
                    InfosDepart[AgesDepart[4]].InfosRetraite["Ircantec"]["General"]["CapitalIrcantec"] === 0 ||
                    InfosDepart[AgesDepart[5]].InfosRetraite["Ircantec"]["General"]["CapitalIrcantec"] === 0 ? (
                      <FormatLigneDepart
                        keyId={index++}
                        MiseEnForme={""}
                        Libelle={"IRCANTEC"}
                        AgesDepart={AgesDepart}
                        InfosDepart={InfosDepart}
                        id={"InfosRetraite"}
                        id2={"Ircantec"}
                        id3={"General"}
                        id4={"Reversion"}
                      />
                    ) : (
                      ""
                    )}
                  </React.Fragment>
                )}
                {"RsiCommercant" in InfosDepart[AgesDepart[0]].InfosRetraite && (
                  <FormatLigneDepart
                    keyId={index++}
                    MiseEnForme={""}
                    Libelle={"RSI Commercant"}
                    AgesDepart={AgesDepart}
                    InfosDepart={InfosDepart}
                    id={"InfosRetraite"}
                    id2={"RsiCommercant"}
                    id3={"General"}
                    id4={"Reversion"}
                  />
                )}
                {"RsiArtisan" in InfosDepart[AgesDepart[0]].InfosRetraite && (
                  <FormatLigneDepart
                    keyId={index++}
                    MiseEnForme={""}
                    Libelle={"RSI Artisan"}
                    AgesDepart={AgesDepart}
                    InfosDepart={InfosDepart}
                    id={"InfosRetraite"}
                    id2={"RsiArtisan"}
                    id3={"General"}
                    id4={"Reversion"}
                  />
                )}
                {"RCI" in InfosDepart[AgesDepart[0]].InfosRetraite && (
                  <FormatLigneDepart
                    keyId={index++}
                    MiseEnForme={""}
                    Libelle={"RCI"}
                    AgesDepart={AgesDepart}
                    InfosDepart={InfosDepart}
                    id={"InfosRetraite"}
                    id2={"RCI"}
                    id3={"General"}
                    id4={"Reversion"}
                  />
                )}
                {"Cipav" in InfosDepart[AgesDepart[0]].InfosRetraite && (
                  <FormatLigneDepart
                    keyId={index++}
                    MiseEnForme={""}
                    Libelle={"CIPAV"}
                    AgesDepart={AgesDepart}
                    InfosDepart={InfosDepart}
                    id={"InfosRetraite"}
                    id2={"Cipav"}
                    id3={"General"}
                    id4={"Reversion"}
                  />
                )}
                {"Cavamac" in InfosDepart[AgesDepart[0]].InfosRetraite && (
                  <FormatLigneDepart
                    keyId={index++}
                    MiseEnForme={""}
                    Libelle={"CAVAMAC"}
                    AgesDepart={AgesDepart}
                    InfosDepart={InfosDepart}
                    id={"InfosRetraite"}
                    id2={"Cavamac"}
                    id3={"General"}
                    id4={"Reversion"}
                  />
                )}
                {"Cavec" in InfosDepart[AgesDepart[0]].InfosRetraite && (
                  <FormatLigneDepart
                    keyId={index++}
                    MiseEnForme={""}
                    Libelle={"CAVEC"}
                    AgesDepart={AgesDepart}
                    InfosDepart={InfosDepart}
                    id={"InfosRetraite"}
                    id2={"Cavec"}
                    id3={"General"}
                    id4={"Reversion"}
                  />
                )}
                {"Carpv" in InfosDepart[AgesDepart[0]].InfosRetraite && (
                  <FormatLigneDepart
                    keyId={index++}
                    MiseEnForme={""}
                    Libelle={"CARPV"}
                    AgesDepart={AgesDepart}
                    InfosDepart={InfosDepart}
                    id={"InfosRetraite"}
                    id2={"Carpv"}
                    id3={"General"}
                    id4={"Reversion"}
                  />
                )}
                {"Carpimko" in InfosDepart[AgesDepart[0]].InfosRetraite && (
                  <FormatLigneDepart
                    keyId={index++}
                    MiseEnForme={""}
                    Libelle={"CARPIMKO"}
                    AgesDepart={AgesDepart}
                    InfosDepart={InfosDepart}
                    id={"InfosRetraite"}
                    id2={"Carpimko"}
                    id3={"General"}
                    id4={"ReversionComp"}
                  />
                )}
                {"Crn" in InfosDepart[AgesDepart[0]].InfosRetraite && (
                  <FormatLigneDepart
                    keyId={index++}
                    MiseEnForme={""}
                    Libelle={"CRN"}
                    AgesDepart={AgesDepart}
                    InfosDepart={InfosDepart}
                    id={"InfosRetraite"}
                    id2={"Crn"}
                    id3={"General"}
                    id4={"Reversion"}
                  />
                )}
                {"Carmf" in InfosDepart[AgesDepart[0]].InfosRetraite && (
                  <FormatLigneDepart
                    keyId={index++}
                    MiseEnForme={""}
                    Libelle={"CARMF"}
                    AgesDepart={AgesDepart}
                    InfosDepart={InfosDepart}
                    id={"InfosRetraite"}
                    id2={"Carmf"}
                    id3={"General"}
                    id4={"ReversionComp"}
                  />
                )}
                {"Carcdsf" in InfosDepart[AgesDepart[0]].InfosRetraite && (
                  <FormatLigneDepart
                    keyId={index++}
                    MiseEnForme={""}
                    Libelle={"CARCD"}
                    AgesDepart={AgesDepart}
                    InfosDepart={InfosDepart}
                    id={"InfosRetraite"}
                    id2={"Carcdsf"}
                    id3={"General"}
                    id4={"ReversionComp"}
                  />
                )}
                {"Carcdsf2" in InfosDepart[AgesDepart[0]].InfosRetraite && (
                  <FormatLigneDepart
                    keyId={index++}
                    MiseEnForme={""}
                    Libelle={"CARSF"}
                    AgesDepart={AgesDepart}
                    InfosDepart={InfosDepart}
                    id={"InfosRetraite"}
                    id2={"Carcdsf2"}
                    id3={"General"}
                    id4={"ReversionComp"}
                  />
                )}
                {"Cavom" in InfosDepart[AgesDepart[0]].InfosRetraite && (
                  <FormatLigneDepart
                    keyId={index++}
                    MiseEnForme={""}
                    Libelle={"CAVOM"}
                    AgesDepart={AgesDepart}
                    InfosDepart={InfosDepart}
                    id={"InfosRetraite"}
                    id2={"Cavom"}
                    id3={"General"}
                    id4={"Reversion"}
                  />
                )}
                {"Cavp" in InfosDepart[AgesDepart[0]].InfosRetraite && (
                  <FormatLigneDepart
                    keyId={index++}
                    MiseEnForme={""}
                    Libelle={"CAVP par capitalisation"}
                    AgesDepart={AgesDepart}
                    InfosDepart={InfosDepart}
                    id={"InfosRetraite"}
                    id2={"Cavp"}
                    id3={"General"}
                    id4={"ReversionCapitalisation"}
                  />
                )}
                {"Cavp" in InfosDepart[AgesDepart[0]].InfosRetraite && (
                  <FormatLigneDepart
                    keyId={index++}
                    MiseEnForme={""}
                    Libelle={"CAVP par répartition"}
                    AgesDepart={AgesDepart}
                    InfosDepart={InfosDepart}
                    id={"InfosRetraite"}
                    id2={"Cavp"}
                    id3={"General"}
                    id4={"ReversionRepartition"}
                  />
                )}
                {"Rafp" in InfosDepart[AgesDepart[0]].InfosRetraite && (
                  <React.Fragment>
                    {InfosDepart[AgesDepart[0]].InfosRetraite["Rafp"]["General"]["TransformationCapitalRafp"] === "Non" ||
                    InfosDepart[AgesDepart[1]].InfosRetraite["Rafp"]["General"]["TransformationCapitalRafp"] === "Non" ||
                    InfosDepart[AgesDepart[2]].InfosRetraite["Rafp"]["General"]["TransformationCapitalRafp"] === "Non" ||
                    InfosDepart[AgesDepart[3]].InfosRetraite["Rafp"]["General"]["TransformationCapitalRafp"] === "Non" ||
                    InfosDepart[AgesDepart[4]].InfosRetraite["Rafp"]["General"]["TransformationCapitalRafp"] === "Non" ||
                    InfosDepart[AgesDepart[5]].InfosRetraite["Rafp"]["General"]["TransformationCapitalRafp"] === "Non" ? (
                      <FormatLigneDepart
                        keyId={index++}
                        MiseEnForme={""}
                        Libelle={"RAFP"}
                        AgesDepart={AgesDepart}
                        InfosDepart={InfosDepart}
                        id={"InfosRetraite"}
                        id2={"Rafp"}
                        id3={"General"}
                        id4={"Reversion"}
                      />
                    ) : (
                      ""
                    )}
                  </React.Fragment>
                )}
                {"Cnbf" in InfosDepart[AgesDepart[0]].InfosRetraite && (
                  <FormatLigneDepart
                    keyId={index++}
                    MiseEnForme={""}
                    Libelle={"CNBF"}
                    AgesDepart={AgesDepart}
                    InfosDepart={InfosDepart}
                    id={"InfosRetraite"}
                    id2={"Cnbf"}
                    id3={"General"}
                    id4={"ReversionComp"}
                  />
                )}
                {"Msa" in InfosDepart[AgesDepart[0]].InfosRetraite && (
                  <FormatLigneDepart
                    keyId={index++}
                    MiseEnForme={""}
                    Libelle={"MSA RCO"}
                    AgesDepart={AgesDepart}
                    InfosDepart={InfosDepart}
                    id={"InfosRetraite"}
                    id2={"Msa"}
                    id3={"General"}
                    id4={"ReversionComp"}
                  />
                )}
                {InfosDepart[AgesDepart[0]].TotalReversionSupplementaire +
                  InfosDepart[AgesDepart[1]].TotalReversionSupplementaire +
                  InfosDepart[AgesDepart[2]].TotalReversionSupplementaire +
                  InfosDepart[AgesDepart[3]].TotalReversionSupplementaire +
                  InfosDepart[AgesDepart[4]].TotalReversionSupplementaire +
                  InfosDepart[AgesDepart[5]].TotalReversionSupplementaire >
                  0 && (
                  <React.Fragment>
                    <FormatLigneDepart
                      keyId={index++}
                      MiseEnForme={"light-text"}
                      Libelle={"Régimes supplémentaires"}
                      AgesDepart={AgesDepart}
                      InfosDepart={InfosDepart}
                      id={"TotalReversionSupplementaire"}
                      id2={""}
                      id3={""}
                      id4={""}
                    />
                    {"Carpimko" in InfosDepart[AgesDepart[0]].InfosRetraite && (
                      <React.Fragment>
                        {InfosDepart[AgesDepart[0]].InfosRetraite["Carpimko"]["General"]["ReversionSupp"] +
                          InfosDepart[AgesDepart[1]].InfosRetraite["Carpimko"]["General"]["ReversionSupp"] +
                          InfosDepart[AgesDepart[2]].InfosRetraite["Carpimko"]["General"]["ReversionSupp"] +
                          InfosDepart[AgesDepart[3]].InfosRetraite["Carpimko"]["General"]["ReversionSupp"] +
                          InfosDepart[AgesDepart[4]].InfosRetraite["Carpimko"]["General"]["ReversionSupp"] +
                          InfosDepart[AgesDepart[5]].InfosRetraite["Carpimko"]["General"]["ReversionSupp"] >
                        0 ? (
                          <FormatLigneDepart
                            keyId={index++}
                            MiseEnForme={""}
                            Libelle={"CARPIMKO"}
                            AgesDepart={AgesDepart}
                            InfosDepart={InfosDepart}
                            id={"InfosRetraite"}
                            id2={"Carpimko"}
                            id3={"General"}
                            id4={"ReversionSupp"}
                          />
                        ) : (
                          ""
                        )}
                      </React.Fragment>
                    )}
                    {"Carmf" in InfosDepart[AgesDepart[0]].InfosRetraite && (
                      <React.Fragment>
                        {InfosDepart[AgesDepart[0]].InfosRetraite["Carmf"]["General"]["ReversionSupp"] +
                          InfosDepart[AgesDepart[1]].InfosRetraite["Carmf"]["General"]["ReversionSupp"] +
                          InfosDepart[AgesDepart[2]].InfosRetraite["Carmf"]["General"]["ReversionSupp"] +
                          InfosDepart[AgesDepart[3]].InfosRetraite["Carmf"]["General"]["ReversionSupp"] +
                          InfosDepart[AgesDepart[4]].InfosRetraite["Carmf"]["General"]["ReversionSupp"] +
                          InfosDepart[AgesDepart[5]].InfosRetraite["Carmf"]["General"]["ReversionSupp"] >
                        0 ? (
                          <FormatLigneDepart
                            keyId={index++}
                            MiseEnForme={""}
                            Libelle={"CARMF"}
                            AgesDepart={AgesDepart}
                            InfosDepart={InfosDepart}
                            id={"InfosRetraite"}
                            id2={"Carmf"}
                            id3={"General"}
                            id4={"ReversionSupp"}
                          />
                        ) : (
                          ""
                        )}
                      </React.Fragment>
                    )}
                    {"Carcdsf" in InfosDepart[AgesDepart[0]].InfosRetraite && (
                      <React.Fragment>
                        {InfosDepart[AgesDepart[0]].InfosRetraite["Carcdsf"]["General"]["ReversionSupp"] +
                          InfosDepart[AgesDepart[1]].InfosRetraite["Carcdsf"]["General"]["ReversionSupp"] +
                          InfosDepart[AgesDepart[2]].InfosRetraite["Carcdsf"]["General"]["ReversionSupp"] +
                          InfosDepart[AgesDepart[3]].InfosRetraite["Carcdsf"]["General"]["ReversionSupp"] +
                          InfosDepart[AgesDepart[4]].InfosRetraite["Carcdsf"]["General"]["ReversionSupp"] +
                          InfosDepart[AgesDepart[5]].InfosRetraite["Carcdsf"]["General"]["ReversionSupp"] >
                        0 ? (
                          <FormatLigneDepart
                            keyId={index++}
                            MiseEnForme={""}
                            Libelle={"CARCDSF"}
                            AgesDepart={AgesDepart}
                            InfosDepart={InfosDepart}
                            id={"InfosRetraite"}
                            id2={"Carcdsf"}
                            id3={"General"}
                            id4={"ReversionSupp"}
                          />
                        ) : (
                          ""
                        )}
                      </React.Fragment>
                    )}
                    {"Cavp" in InfosDepart[AgesDepart[0]].InfosRetraite && (
                      <React.Fragment>
                        {InfosDepart[AgesDepart[0]].InfosRetraite["Cavp"]["General"]["ReversionSupp"] +
                          InfosDepart[AgesDepart[1]].InfosRetraite["Cavp"]["General"]["ReversionSupp"] +
                          InfosDepart[AgesDepart[2]].InfosRetraite["Cavp"]["General"]["ReversionSupp"] +
                          InfosDepart[AgesDepart[3]].InfosRetraite["Cavp"]["General"]["ReversionSupp"] +
                          InfosDepart[AgesDepart[4]].InfosRetraite["Cavp"]["General"]["ReversionSupp"] +
                          InfosDepart[AgesDepart[5]].InfosRetraite["Cavp"]["General"]["ReversionSupp"] >
                        0 ? (
                          <FormatLigneDepart
                            keyId={index++}
                            MiseEnForme={""}
                            Libelle={"CAVP"}
                            AgesDepart={AgesDepart}
                            InfosDepart={InfosDepart}
                            id={"InfosRetraite"}
                            id2={"Cavp"}
                            id3={"General"}
                            id4={"ReversionSupp"}
                          />
                        ) : (
                          ""
                        )}
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )}
                {InfosDepart[AgesDepart[0]].TotalReversionFacultative +
                  InfosDepart[AgesDepart[1]].TotalReversionFacultative +
                  InfosDepart[AgesDepart[2]].TotalReversionFacultative +
                  InfosDepart[AgesDepart[3]].TotalReversionFacultative +
                  InfosDepart[AgesDepart[4]].TotalReversionFacultative +
                  InfosDepart[AgesDepart[5]].TotalReversionFacultative >
                  0 && (
                  <React.Fragment>
                    <FormatLigneDepart
                      keyId={index++}
                      MiseEnForme={"light-text"}
                      Libelle={"Régimes facultatifs"}
                      AgesDepart={AgesDepart}
                      InfosDepart={InfosDepart}
                      id={"TotalReversionFacultative"}
                      id2={""}
                      id3={""}
                      id4={""}
                    />
                    {InfosDepart[AgesDepart[0]].ListeContrat.map((data, i) => {
                      if (data.Sortie === "RenteSimple" || data.Sortie === "Panachage") {
                        return (
                          <FormatLigneDepart
                            keyId={index++}
                            MiseEnForme={""}
                            Libelle={data.Compagnie}
                            AgesDepart={AgesDepart}
                            InfosDepart={InfosDepart}
                            id={"ListeContrat"}
                            id2={i}
                            id3={"Reversion"}
                            id4={""}
                          />
                        );
                      }
                    })}
                  </React.Fragment>
                )}
                <FormatLigneDepart
                  keyId={index++}
                  MiseEnForme={"dark-title"}
                  Libelle={"Réversions nettes"}
                  AgesDepart={AgesDepart}
                  InfosDepart={InfosDepart}
                  id={"TotalReversionNetteAvecFacultatif"}
                  id2={""}
                  id3={""}
                  id4={""}
                />
              </tbody>
            </table>
          </Row>
        </Column>
      </CardBox>
    </Column>
  );
};

export default DetailReversion;
