import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import TextField from "components/ui/controls/text-field/text-field";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import DropDown from "components/ui/controls/drop-down/drop-down";
import Row from "components/ui/layout/row/row";
import { CalculRetraite } from "components/app/Api/Retraite/ApiRetraite";
import PointCnavpl from "./Point/PointCnavpl";
import PointRafp from "./Point/PointRafp";
import PointArrco from "./Point/PointArrco";
import PointAgircTB from "./Point/PointAgircTB";
import PointAgircTC from "./Point/PointAgircTC";
import PointIrcantec from "./Point/PointIrcantec";
import PointMsa from "./Point/PointMsa";
import PointRci from "./Point/PointRci";
import PointCavamac from "./Point/PointCavamac";
import PointCarmf from "./Point/PointCarmf";
import PointCarpimko from "./Point/PointCarpimko";
import PointCavec from "./Point/PointCavec";
import PointCrn from "./Point/PointCrn";
import PointCipav from "./Point/PointCipav";
import PointCavom from "./Point/PointCavom";
import PointCavp from "./Point/PointCavp";
import PointCnbf from "./Point/PointCnbf";
import PointCarcdsf from "./Point/PointCarcdsf";
import PointCarcdsfAsv from "./Point/PointCarcdsfAsv";
import PointCarpimkoAsv from "./Point/PointCarpimkoAsv";
import PointCarmfAsv from "./Point/PointCarmfAsv.";
import PointCavpAsv from "./Point/PointCavpAsv";
import PointCavpCapi from "./Point/PointCavpCapi";
import PointAgircArrco from "./Point/PointAgircArrco";
import PointCarpv from "./Point/PointCarpv";
import PointCarcdsf2 from "./Point/PointCarcdsf2";
import PointCarcdsf2Asv from "./Point/PointCarcdsf2Asv";

const PointRetraite = (props) => {
  const context = useContext(AppContext);
  const [resultRetraite, setResultRetraite] = useState({});
  const handleCalculRetraite = () => {
    CalculRetraite(context.state, "", 0,props.ClientConjoint).then((ResultatRetraite) => {
      setResultRetraite(ResultatRetraite.RetraiteChoixDepartRetraite);
    });
  };
  useEffect(() => {
    handleCalculRetraite();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Column fill>
        <CardBox>
          <Column fill>
            <Row>
              <table className="mono">
                <thead>
                  <tr>
                    <th>Régimes</th>
                    <th>Points acquis</th>
                    <th>Points acquis au 31/12</th>
                    <th>Points projetés</th>
                  </tr>
                </thead>
                <tbody>
                  <PointCnavpl resultRetraite={resultRetraite} CalculRetraite={handleCalculRetraite} ClientConjoint={props.ClientConjoint} />
                  <PointRafp resultRetraite={resultRetraite} CalculRetraite={handleCalculRetraite} ClientConjoint={props.ClientConjoint} />
                  <PointAgircArrco resultRetraite={resultRetraite} CalculRetraite={handleCalculRetraite} ClientConjoint={props.ClientConjoint} />
                  <PointArrco resultRetraite={resultRetraite} CalculRetraite={handleCalculRetraite} ClientConjoint={props.ClientConjoint} />
                  <PointAgircTB resultRetraite={resultRetraite} CalculRetraite={handleCalculRetraite} ClientConjoint={props.ClientConjoint} />
                  <PointAgircTC resultRetraite={resultRetraite} CalculRetraite={handleCalculRetraite} ClientConjoint={props.ClientConjoint} />
                  <PointIrcantec resultRetraite={resultRetraite} CalculRetraite={handleCalculRetraite} ClientConjoint={props.ClientConjoint} />
                  <PointMsa resultRetraite={resultRetraite} CalculRetraite={handleCalculRetraite} ClientConjoint={props.ClientConjoint} />
                  <PointRci resultRetraite={resultRetraite} CalculRetraite={handleCalculRetraite} ClientConjoint={props.ClientConjoint} />
                  <PointCavamac resultRetraite={resultRetraite} CalculRetraite={handleCalculRetraite} ClientConjoint={props.ClientConjoint} />
                  <PointCarcdsf resultRetraite={resultRetraite} CalculRetraite={handleCalculRetraite} ClientConjoint={props.ClientConjoint} />
                  <PointCarcdsf2 resultRetraite={resultRetraite} CalculRetraite={handleCalculRetraite} ClientConjoint={props.ClientConjoint} />
                  <PointCarmf resultRetraite={resultRetraite} CalculRetraite={handleCalculRetraite} ClientConjoint={props.ClientConjoint} />
                  <PointCarpimko resultRetraite={resultRetraite} CalculRetraite={handleCalculRetraite} ClientConjoint={props.ClientConjoint} />
                  <PointCarpv resultRetraite={resultRetraite} CalculRetraite={handleCalculRetraite} ClientConjoint={props.ClientConjoint} />
                  <PointCavec resultRetraite={resultRetraite} CalculRetraite={handleCalculRetraite} ClientConjoint={props.ClientConjoint} />
                  <PointCrn resultRetraite={resultRetraite} CalculRetraite={handleCalculRetraite}ClientConjoint={props.ClientConjoint} />
                  <PointCipav resultRetraite={resultRetraite} CalculRetraite={handleCalculRetraite}ClientConjoint={props.ClientConjoint} />
                  <PointCavom resultRetraite={resultRetraite} CalculRetraite={handleCalculRetraite}ClientConjoint={props.ClientConjoint} />
                  <PointCavp resultRetraite={resultRetraite} CalculRetraite={handleCalculRetraite}ClientConjoint={props.ClientConjoint} />
                  <PointCnbf resultRetraite={resultRetraite} CalculRetraite={handleCalculRetraite}ClientConjoint={props.ClientConjoint} />
                  <PointCarcdsfAsv resultRetraite={resultRetraite} CalculRetraite={handleCalculRetraite}ClientConjoint={props.ClientConjoint} />
                  <PointCarcdsf2Asv resultRetraite={resultRetraite} CalculRetraite={handleCalculRetraite}ClientConjoint={props.ClientConjoint} />
                  <PointCarmfAsv resultRetraite={resultRetraite} CalculRetraite={handleCalculRetraite}ClientConjoint={props.ClientConjoint} />
                  <PointCarpimkoAsv resultRetraite={resultRetraite} CalculRetraite={handleCalculRetraite}ClientConjoint={props.ClientConjoint} />
                  <PointCavpAsv resultRetraite={resultRetraite} CalculRetraite={handleCalculRetraite}ClientConjoint={props.ClientConjoint} />
                </tbody>
              </table>
            </Row>
              <PointCavpCapi resultRetraite={resultRetraite} CalculRetraite={handleCalculRetraite}ClientConjoint={props.ClientConjoint} />
          </Column>
        </CardBox>
      </Column>
    </>
  );
};
export default PointRetraite;
