import "./ParamResultat.css";
import { useContext, useEffect } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import TextField from "components/ui/controls/text-field/text-field";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";
import Pager from "components/ui/controls/pager/pager";
import ParamImpotSocietePageModal from "pages/audit complet/situation professionnelle/modules/parametre impot societe modal";
import Button from "components/ui/controls/button/button";
import { FaCalculator, FaSync } from "react-icons/fa";
import { CalculResultatEntreprise } from "components/app/Api/Remuneration/ApiRemuneration";

function ParamResultat(props) {
  const context = useContext(AppContext);
  useEffect(() => {
    if (context.state.SetSeuilAssuj === false) {
      CalculResultatEntreprise(context.state, "").then((ResultatEntreprise) => {
        context.handleValueChange("SeuilAssuj", ResultatEntreprise.SeuilAssuj);
      });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.state.SetSeuilAssuj, context.state.ResAvtIs]);
  useEffect(() => {
    if (context.state.SetSeuilAssujConjoint === false) {
      CalculResultatEntreprise(context.state, "").then((ResultatEntreprise) => {
        context.handleValueChange("SeuilAssujConjoint", ResultatEntreprise.SeuilAssujConjoint);
      });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.state.SetSeuilAssujConjoint, context.state.ResAvtIs]);
  return (
    <>
      <Pager
        pages={[
          {
            content: (
              <Column pad fill>
                <CardBox>
                  <Column fill>
                    <Row>
                      <h1>Paramétrage du résultat</h1>
                    </Row>
                    <Row tpad>
                      <table id="TableauResultatEntreprise">
                        <tbody>
                          <tr>
                            <td> Résultat avant IS</td>
                            <td>
                              <TextField
                                value={context.state.ResAvtIs}
                                onBlur={(e) => {
                                  context.handleCalculResultatEntreprise("", "ResAvtIs", e);
                                }}
                                dataType="uint"
                                format={{ thousands: " ", unit: " €" }}
                              />
                            </td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>Impôt sur les sociétes</td>
                            <td>
                              <Row>
                                <Column style={{ padding: 0 }}>
                                  <TextField value={context.state.ImpotSoc} disabled dataType="uint" format={{ thousands: " ", unit: " €" }} />
                                </Column>
                                <Column style={{ padding: 0, alignSelf: "center" }}>
                                  <ParamImpotSocietePageModal />
                                </Column>
                              </Row>
                            </td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>Résultat net</td>
                            <td>
                              <TextField value={context.state.ResNet} disabled dataType="uint" format={{ thousands: " ", unit: " €" }} />
                            </td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>Mise en réserve</td>
                            <td>
                              <TextField value={context.state.Reserve} 
                              onBlur={(e)=>{context.handleCalculResultatEntreprise("","Reserve",e)}}
                               dataType="uint" format={{ thousands: " ", unit: " €" }} />
                            </td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>{context.state.InclureConjoint ? "Dividendes bruts du couple " : "Dividendes bruts pour " + context.state.Prenom}</td>
                            <td>
                              <TextField value={context.state.DividendeBrut} disabled dataType="uint" format={{ thousands: " ", unit: " €" }} />
                            </td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>Autres associés</td>
                            <td>
                              <TextField value={context.state.DividendeBrutAutres} disabled dataType="uint" format={{ thousands: " ", unit: " €" }} />
                            </td>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>
                    </Row>
                    <Row tpad>
                      <h1>Régime social des dividendes</h1>
                    </Row>
                    <Row tpad>
                      <Column>
                        <TextField
                          iconBefore={
                            <Button
                              tabIndex="-1"
                              icon
                              onClick={() => {
                                context.handleValueChange("SetSeuilAssuj", false);
                              }}
                            >
                              {context.state.SetSeuilAssuj === false ? <FaCalculator /> : <FaSync style={{ color: "green" }} />}
                            </Button>
                          }
                          label={"Seuil d'assujetissement pour " + props.Prenom}
                          labelStyle={{ whiteSpace: "nowrap" }}
                          value={context.state.SeuilAssuj}
                          onChange={(e) => {
                            context.handleValueChange("SetSeuilAssuj", true);
                            context.handleValueChange("SeuilAssuj", e.target.value);
                          }}
                          dataType="uint"
                          format={{ thousands: " ", unit: " €" }}
                          normal
                        />
                      </Column>
                    </Row>
                    {context.state.InclureConjoint && context.state.StatutConjoint === "TNS Article 62" && (
                      <Row>
                        <Column>
                          <TextField
                            iconBefore={
                              <Button
                                tabIndex="-1"
                                icon
                                onClick={() => {
                                  context.handleValueChange("SetSeuilAssujConjoint", false);
                                }}
                              >
                                {context.state.SetSeuilAssujConjoint === false ? <FaCalculator /> : <FaSync style={{ color: "green" }} />}
                              </Button>
                            }
                            label={"Seuil d'assujetissement pour " + context.state.PrenomConjoint}
                            labelStyle={{ whiteSpace: "nowrap" }}
                            value={context.state.SeuilAssujConjoint}
                            onChange={context.handleInputChange("SeuilAssujConjoint")}
                            dataType="uint"
                            format={{ thousands: " ", unit: " €" }}
                            normal
                          />
                        </Column>
                      </Row>
                    )}
                  </Column>
                </CardBox>
              </Column>
            ),
          },
        ]}
        page={0}
        navButtons={{
          validate: {
            onClick: () => {
              props.onClose();
            },
          },
        }}
      />
    </>
  );
}

export default ParamResultat;
