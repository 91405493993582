import React, { useContext } from "react";
import Column from "components/ui/layout/column/column";
import CardBox from "components/ui/controls/card-box/card-box";
import Row from "components/ui/layout/row/row";
import { applyFormat } from "components/ui/ui-helpers";
import { AppContext } from "components/app/RemExprertProvider";

const DetailDeces = ({ Outil, TabDeces, TabRente }) => {
  const context = useContext(AppContext);
  const handleRenteEducationRO = (DateNaissance) => {
    var today = new Date();
    var birthDate = new Date();
    birthDate.setFullYear(DateNaissance.slice(4), DateNaissance.slice(2, 4) - 1, DateNaissance.slice(0, 2));
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    if (age <= 12) {
      return TabRente[1]["Régime obligatoire"];
    } else if (age <= 18) {
      return TabRente[2]["Régime obligatoire"];
    } else if (age <= 25) {
      return TabRente[3]["Régime obligatoire"];
    } else {
      return 0;
    }
  };
  const handleRenteEducationRF = (DateNaissance) => {
    var today = new Date();
    var birthDate = new Date();
    birthDate.setFullYear(DateNaissance.slice(4), DateNaissance.slice(2, 4) - 1, DateNaissance.slice(0, 2));
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    if (age <= 12) {
      return TabRente[1]["Régime facultatif"];
    } else if (age <= 18) {
      return TabRente[2]["Régime facultatif"];
    } else if (age <= 25) {
      return TabRente[3]["Régime facultatif"];
    } else {
      return 0;
    }
  };
  return (
    <Column fill>
      <CardBox>
        <Column fill>
          <Row>
            <table>
              <thead>
                <tr >
                  <th>Prestations</th>
                  <th className="text-center">Régime(s) de base. </th>
                  <th className="text-center">Régime(s) comp. </th>
                  <th className="text-center">Total</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Capital décès / IAD toutes causes</td>
                  <td className="text-center">{applyFormat(Math.max(0, TabDeces[0]["Régime obligatoire"]), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                  <td className="text-center">{applyFormat(Math.max(0, TabDeces[0]["Régime facultatif"]), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                  <td className="text-center">
                    {applyFormat(Math.max(0, Number(TabDeces[0]["Régime obligatoire"]) + Number(TabDeces[0]["Régime facultatif"])), "uint", { thousands: " ", decimal: ",", unit: " €" })}
                  </td>
                </tr>
                <tr>
                  <td>Capital décès / IAD accidentel</td>
                  <td className="text-center">{applyFormat(Math.max(0, TabDeces[1]["Régime obligatoire"]), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                  <td className="text-center">{applyFormat(Math.max(0, TabDeces[1]["Régime facultatif"]), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                  <td className="text-center">
                    {applyFormat(Math.max(0, Number(TabDeces[1]["Régime obligatoire"]) + Number(TabDeces[1]["Régime facultatif"])), "uint", { thousands: " ", decimal: ",", unit: " €" })}
                  </td>
                </tr>
                <tr>
                  <td>Double effet</td>
                  <td className="text-center">0 €</td>
                  <td className="text-center">
                    {applyFormat(
                      Math.max(
                        0,
                        context.state[Outil + "ContratEnregistre"].reduce((prev, next) => Number(prev) + Number(next.DecesDoubleEffet), 0)
                      ),
                      "uint",
                      { thousands: " ", decimal: ",", unit: " €" }
                    )}
                  </td>
                  <td className="text-center">
                    {applyFormat(
                      Math.max(
                        0,
                        context.state[Outil + "ContratEnregistre"].reduce((prev, next) => Number(prev) + Number(next.DecesDoubleEffet), 0)
                      ),
                      "uint",
                      { thousands: " ", decimal: ",", unit: " €" }
                    )}
                  </td>
                </tr>
                <tr>
                  <td>Frais d'obsèques</td>
                  <td className="text-center">0 €</td>
                  <td className="text-center">
                    {applyFormat(
                      Math.max(
                        0,
                        context.state[Outil + "ContratEnregistre"].reduce((prev, next) => Number(prev) + Number(next.FraisObseque), 0)
                      ),
                      "uint",
                      { thousands: " ", decimal: ",", unit: " €" }
                    )}
                  </td>
                  <td className="text-center">
                    {applyFormat(
                      Math.max(
                        0,
                        context.state[Outil + "ContratEnregistre"].reduce((prev, next) => Number(prev) + Number(next.FraisObseque), 0)
                      ),
                      "uint",
                      { thousands: " ", decimal: ",", unit: " €" }
                    )}
                  </td>
                </tr>
                <tr>
                  <td>Rente conjoint</td>
                  <td className="text-center">{applyFormat(Math.max(0, TabRente[0]["Régime obligatoire"]), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                  <td className="text-center">{applyFormat(Math.max(0, TabRente[0]["Régime facultatif"]), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                  <td className="text-center">{applyFormat(Math.max(0, TabRente[0]["Régime obligatoire"] + TabRente[0]["Régime facultatif"]), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                </tr>
                {context.state.TableauEnfant.map((Enfant, Index) => (
                  Enfant.Charge!=="none" && 
                  <tr key={Index}>
                    <td>Rente éducation pour {Enfant.Prenom}</td>
                    <td className="text-center">{applyFormat(Math.max(0, handleRenteEducationRO(Enfant.DateNaissance,Enfant.Charge)), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                    <td className="text-center">{applyFormat(Math.max(0, handleRenteEducationRF(Enfant.DateNaissance,)), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                    <td className="text-center">{applyFormat(Math.max(0,  handleRenteEducationRO(Enfant.DateNaissance)+handleRenteEducationRF(Enfant.DateNaissance)), "uint", { thousands: " ", decimal: ",", unit: " €" })}</td>
                  </tr>

                ))}
              </tbody>
            </table>
          </Row>
        </Column>
      </CardBox>
    </Column>
  );
};

export default DetailDeces;
