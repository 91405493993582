import { useState, useContext, useEffect } from "react";
import { FaCalculator, FaDatabase, FaFilePowerpoint, FaFileWord, FaIdBadge, FaPrint } from "react-icons/fa";
import Button from "components/ui/controls/button/button";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";
import Modal from "components/ui/modal/modal";
import { AppContext } from "components/app/RemExprertProvider";
import { applyFormat } from "components/ui/ui-helpers";
import { RemExpertResultatTNS } from "components/app/Api/ResultatRemExpertTNSProvider";
import { AuditRetraiteWord, AuditRetraitePptx, ExportData } from "components/app/Api/Rapport/ApiRapport";
import DetailRetraitePageModal from "./detailRetraitePager";
import { Menu, MenuItem } from "@mui/material";
import { CalculPrestationContrat, CalculRetraite } from "components/app/Api/Retraite/ApiRetraite";

const ResultatPageRetraite = (props) => {
  const context = useContext(AppContext);
  const ResultatTNS = useContext(RemExpertResultatTNS);
  const [modalDetail, setModalDetail] = useState(false);
  const ClientConjoint = props.ClientConjoint;
  const Prenom = context.state["Prenom" + ClientConjoint];

  const handleCalculPrestationContrat = () => {
    return new Promise((resolve, reject) => {
      CalculRetraite(context.state, "", 0, ClientConjoint).then((ResultatRetraite) => {});
      resolve(true);
    });
  };
  useEffect(() => {
    // CalculRetraite(context.state, "", 0, ClientConjoint).then((ResultatRetraite) => {
    //   context.state.ListeContrat.forEach((contratRetraite, index) => {
    //     CalculPrestationContrat(context.state, ClientConjoint, ResultatRetraite, contratRetraite.Salaire, contratRetraite).then((Resultat) => {});
    //   });
    // });
    ResultatTNS.GetResultBilanRetraite(context.state, "").then((ResultatRetraite) => {
      context.state.ListeContrat.forEach((contratRetraite, index) => {
        CalculPrestationContrat(context.state, "", ResultatRetraite, contratRetraite.Salaire, contratRetraite).then((Resultat) => {});
      });
    });
    if (context.state.InclureConjoint===true) {
      ResultatTNS.GetResultBilanRetraite(context.state, "Conjoint").then((ResultatRetraite) => {
        context.state.ListeContratConjoint.forEach((contratRetraite, index) => {
          CalculPrestationContrat(context.state, "Conjoint", ResultatRetraite, contratRetraite.Salaire, contratRetraite).then((Resultat) => {});
        });
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.ClientConjoint, context.state.AgeDepartProjection, context.state.AgeDepartProjectionConjoint]);

  const GetFormattedDate = (date) => {
    const dt = new Date(date);
    var year = dt.getFullYear();

    var month = (1 + dt.getMonth()).toString();

    month = month.length > 1 ? month : "0" + month;

    var day = dt.getDate().toString();

    day = day.length > 1 ? day : "0" + day;

    return day + "/" + month + "/" + year;
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Column fill>
        <CardBox style={{ width: "730px", padding: 0 }}>
          <Row tpad bpad>
            <Column>
              <Button onClick={handleClick}>
                <FaPrint />
                <span>Imprimer</span>
              </Button>
              <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <MenuItem
                  onClick={() => {
                    AuditRetraitePptx(context.state, ResultatTNS.state).then((res) => {});
                    handleClose();
                  }}
                  disableRipple
                >
                  <FaFilePowerpoint style={{ color: "#cc4424", marginRight: "10px" }} sy />
                  <div style={{ paddingTop: "4px" }}> Etude simplifiée </div>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    AuditRetraiteWord(context.state, ResultatTNS.state).then((res) => {});
                    handleClose();
                  }}
                  disableRipple
                >
                  <FaFileWord style={{ color: "#2c5698", marginRight: "10px" }} sy />
                  <div style={{ paddingTop: "4px" }}> Etude complète </div>
                </MenuItem>
              </Menu>
            </Column>
            <Column>
              <Button
                onClick={() => {
                  setModalDetail(true);
                }}
              >
                <FaCalculator />
                <span>Détails {Prenom}</span>
              </Button>
            </Column>
            {(context.state.IdUser === 4 || context.state.IdUser === 2000) && (
              <Column>
                <Button
                  onClick={() => {
                    ExportData(context.state, ResultatTNS.state).then((res) => {});
                  }}
                >
                  <FaDatabase />
                  <span>Exporter</span>
                </Button>
              </Column>
            )}
          </Row>
        </CardBox>
        <Row tpad bpad>
          <Column className="rpad">
            <CardBox style={{ width: "345px", height: "200px", padding: 0 }}>
              <Column fill style={{ padding: 0 }}>
                <Row style={{ paddingLeft: "26px", paddingRight: "26px", paddingBottom: "45px" }}>
                  <Column>
                    <Row fill>
                      <h2 style={{ fontWeight: "500" }}> {ResultatTNS.state["Retraite" + ClientConjoint].InfosRetraiteChoixDepart?.Infos?.AgeDepartRetraite}</h2>
                    </Row>
                    <Row fill>soit le {GetFormattedDate(ResultatTNS.state["Retraite" + ClientConjoint].InfosRetraiteChoixDepart?.Infos?.DateDepartRetraite)} </Row>
                  </Column>
                </Row>
                <Row className="light-title" style={{ paddingLeft: "26px", paddingTop: "15px", height: "37%", borderBottomLeftRadius: "4px", borderBottomRightRadius: "4px" }}>
                  Age de départ à la retraite
                </Row>
              </Column>
            </CardBox>
          </Column>
          <Column className="lpad">
            <CardBox style={{ width: "345px", height: "200px", padding: 0 }}>
              <Column fill style={{ padding: 0 }}>
                <Row style={{ paddingLeft: "26px", paddingRight: "26px", paddingBottom: "45px" }}>
                  <Column>
                    <Row fill>
                      <h2 style={{ fontWeight: "500" }}>{ResultatTNS.state["Retraite" + ClientConjoint].InfosRetraiteChoixDepart?.TotalTrimestre}</h2>
                    </Row>
                    <Row fill>Trimestres acquis</Row>
                  </Column>
                </Row>
                <Row className="light-title" style={{ paddingLeft: "26px", paddingTop: "15px", height: "37%", borderBottomLeftRadius: "4px", borderBottomRightRadius: "4px" }}>
                  Durée de référence : {ResultatTNS.state["Retraite" + ClientConjoint].InfosRetraiteChoixDepart?.Infos?.DureeReference} trimestres
                </Row>
              </Column>
            </CardBox>
          </Column>
        </Row>
        <Row tpad bpad>
          <Column className="rpad">
            <CardBox style={{ width: "345px", height: "200px", padding: 0 }}>
              <Column fill style={{ padding: 0 }}>
                <Row style={{ paddingLeft: "26px", paddingRight: "26px", paddingBottom: "45px" }}>
                  <Column>
                    <Row fill>
                      <h2 style={{ fontWeight: "500" }}>
                        {applyFormat(ResultatTNS.state["Retraite" + ClientConjoint].TotalRetraiteNetteChoixDepartRetraite / 12, "uint", { thousands: " ", decimal: ",", unit: " €" })}
                      </h2>
                    </Row>
                    <Row fill>Retraite nette mensuelle</Row>
                  </Column>
                </Row>
                <Row className="light-title" style={{ paddingLeft: "26px", paddingTop: "15px", height: "37%", borderBottomLeftRadius: "4px", borderBottomRightRadius: "4px" }}>
                  Retraite brute annuelle :{" "}
                  {applyFormat(ResultatTNS.state["Retraite" + ClientConjoint].InfosRetraiteChoixDepart?.TotalPensionRenteBrute, "uint", { thousands: " ", decimal: ",", unit: " €" })}
                </Row>
              </Column>
            </CardBox>
          </Column>
          <Column className="lpad">
            <CardBox style={{ width: "345px", height: "200px", padding: 0 }}>
              <Column fill style={{ padding: 0 }}>
                <Row style={{ paddingLeft: "26px", paddingRight: "26px", paddingBottom: "45px" }}>
                  <Column>
                    <Row fill>
                      <h2 style={{ fontWeight: "500" }}>
                        {applyFormat(ResultatTNS.state["Retraite" + ClientConjoint].InfosRetraiteChoixDepart?.TotalCapitalFractionne / 12, "uint", {
                          thousands: " ",
                          decimal: ",",
                          unit: " €",
                        })}
                      </h2>
                    </Row>
                    <Row fill>Epargne nette mensuelle en capital</Row>
                  </Column>
                </Row>
                <Row className="light-title" style={{ paddingLeft: "26px", paddingTop: "15px", height: "37%", borderBottomLeftRadius: "4px", borderBottomRightRadius: "4px" }}>
                  Epargne brute annuelle :{" "}
                  {applyFormat(ResultatTNS.state["Retraite" + ClientConjoint].InfosRetraiteChoixDepart?.TotalCapitalFractionneBrut, "uint", { thousands: " ", decimal: ",", unit: " €" })}
                </Row>
              </Column>
            </CardBox>
          </Column>
        </Row>
        <Row tpad bpad>
          <Column className="rpad">
            <CardBox style={{ width: "345px", height: "200px", padding: 0 }}>
              <Column fill style={{ padding: 0 }}>
                <Row style={{ paddingLeft: "26px", paddingRight: "26px", paddingBottom: "45px" }}>
                  <Column>
                    <Row fill>
                      <h2 style={{ fontWeight: "500" }}>
                        {applyFormat(ResultatTNS.state["Retraite" + ClientConjoint].TauxRempRetraiteNetteChoixDepartRetraite, "uint", { thousands: " ", decimal: ",", unit: "%" })}
                      </h2>
                    </Row>
                    <Row fill>Taux de remplacement</Row>
                  </Column>
                </Row>
                <Row className="light-title" style={{ paddingLeft: "26px", paddingTop: "15px", height: "37%", borderBottomLeftRadius: "4px", borderBottomRightRadius: "4px" }}>
                  Revenu de reférence :{" "}
                  {applyFormat(ResultatTNS.state["Retraite" + ClientConjoint].InfosRetraiteChoixDepart?.SalaireReference, "uint", { thousands: " ", decimal: ",", unit: " €" })}
                </Row>
              </Column>
            </CardBox>
          </Column>
          <Column className="lpad">
            <CardBox style={{ width: "345px", height: "200px", padding: 0 }}>
              <Column fill style={{ padding: 0 }}>
                <Row style={{ paddingLeft: "26px", paddingRight: "26px", paddingBottom: "45px" }}>
                  <Column>
                    <Row fill>
                      <h2 style={{ fontWeight: "500" }}>
                        {applyFormat(ResultatTNS.state["Retraite" + ClientConjoint].InfosRetraiteChoixDepart?.TotalReversionNetteAvecFacultatif / 12, "uint", {
                          thousands: " ",
                          decimal: ",",
                          unit: " €",
                        })}
                      </h2>
                    </Row>
                    <Row fill>Réversion nette mensuelle</Row>
                  </Column>
                </Row>
                <Row className="light-title" style={{ paddingLeft: "26px", paddingTop: "15px", height: "37%", borderBottomLeftRadius: "4px", borderBottomRightRadius: "4px" }}>
                  Réversion brute annuelle :{" "}
                  {applyFormat(ResultatTNS.state["Retraite" + ClientConjoint].InfosRetraiteChoixDepart?.TotalReversionBruteAvecFacultatif, "uint", {
                    thousands: " ",
                    decimal: ",",
                    unit: " €",
                  })}
                </Row>
              </Column>
            </CardBox>
          </Column>
        </Row>
      </Column>

      <Modal
        width="80%"
        height="80%"
        title="Details"
        icon={<FaIdBadge />}
        visible={modalDetail}
        onClose={() => {
          setModalDetail(false);
        }}
      >
        <DetailRetraitePageModal
          Outil=""
          Prenom={Prenom}
          InfosRetraiteChoixDepart={ResultatTNS.state["Retraite" + ClientConjoint].InfosRetraiteChoixDepart}
          InfosRetraite={ResultatTNS.state["Retraite" + ClientConjoint].InfosRetraite}
          ClientConjoint={ClientConjoint}
          visible={modalDetail}
          onClose={() => {
            setModalDetail(false);
          }}
        />
      </Modal>
    </>
  );
};

export default ResultatPageRetraite;
