import { ControlRIS, PdfReader, ReleveRGReader } from "components/app/Api/Retraite/ApiRetraite";
import { AppContext } from "components/app/RemExprertProvider";
import { createContext, useContext, useEffect, useState } from "react";

export const RGContext = createContext();

/**
 * Generates a modal context provider component.
 * @param {ReactDOM.props} props Component properties.
 * @returns Generated component.
 */
const RGProvider = (props) => {
  // Initialize states
  const context = useContext(AppContext);
  const [Results, setResults] = useState([]);
  const [Carriere, setCarriere] = useState([]);
  const [ReleveCarriere, setReleveCarriere] = useState([]);
  const [InfosBlocageRIS, setInfosBlocageRIS] = useState({});
  const [ErrorMessage, setErrorMessage] = useState("");
  const [SuccessMessage, setSuccessMessage] = useState(false);

  useEffect(() => {
    handleReseRGProvider();
  }, [props.visible]);

  const handleReseRGProvider = () => {
    setCarriere([]);
    setReleveCarriere([]);
    setErrorMessage("");
    setSuccessMessage(false);
  };
  const handleUploadRG = (Infos) => (event) => {
    ReleveRGReader(event.target.files[0], Infos.IdUser, context.state["Carriere" + props.ClientConjoint]).then((Res) => {
      if (Res.data[0] === "Error") {
        setErrorMessage(
          "Pour des raisons techniques, le logiciel n'est pas en mesure de lire le relevé de carrière. Seul les impressions en PDF depuis GOOGLE CHROME ou MOZILLA FIREFOX fonctionnent. En dehors de ces cas, il n'est pas nécessaire de contacter la HOTLINE, cette dernière ne pouvant rien faire."
        );
      } else {
        setReleveCarriere(Res.data[1].ReleveCarriere);
        setCarriere(Res.data[1].Carriere);
        setSuccessMessage(true);
      }
    });
  };

  // Render component
  return (
    <RGContext.Provider
      value={{
        InfosBlocageRIS: InfosBlocageRIS,
        ErrorMessage: ErrorMessage,
        SuccessMessage: SuccessMessage,
        handleUploadRG: handleUploadRG,
        Carriere: Carriere,
        ReleveCarriere: ReleveCarriere,
        // handleGestionRIS: handleGestionRIS,
        // handleCorrectionBlocageRIS: handleCorrectionBlocageRIS,
        // ValidationRIS: ValidationRIS,
      }}
    >
      {props.children}
    </RGContext.Provider>
  );
};
export default RGProvider;
