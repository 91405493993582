import React, { useState } from "react";
import { useContext } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import TextField from "components/ui/controls/text-field/text-field";
import { FaGlobe } from "react-icons/fa";
import Pager from "components/ui/controls/pager/pager";
import Column from "components/ui/layout/column/column";
import CardBox from "components/ui/controls/card-box/card-box";
import Row from "components/ui/layout/row/row";
import Modal from "components/ui/modal/modal";

const PointAgircTC = (props) => {
  const {ClientConjoint}=props;
  const context = useContext(AppContext);

  let [openModal,setOpenModal]=useState(false);
  return (
    <>
             {context.state['Carriere'+ClientConjoint].filter(
                    Carriere =>
                      (Carriere.Nature === "ActiviteSalarie" ||
                        Carriere.Nature === "SalarieNonCadre" ||
                        Carriere.Nature === "SalarieCadre" ||
                        Carriere.Nature === "SalarieCadreCFE" ||
                        Carriere.Nature === "SalarieCadreAgricole" ||
                        Carriere.Nature === "CavecSalarie")
                  ).length > 0 && (
                    <tr>
                      <td>AGIRC TC</td>
                      <td>
                        <TextField
                          value={context.state["TableauPoints"+ClientConjoint].RetraiteCompAgircTC}
                          onClick={() => {
                            setOpenModal(true)
                          }}
                          dataType="ufloat"
                          format={{ thousands: " ", precision: 2, decimal: "," }}
                          small
                        />
                      </td>
                      <td>
                       2018
                      </td>
                      <td>
                      </td>
                    </tr>
                  )}


<Modal
        width="30%"
        height="30%"
        title="POINTS AGIRC TC"
        icon={<FaGlobe />}
        visible={openModal}
        onClose={() => {
          setOpenModal(false)
        }}
      >
        <Pager
          pages={[
            {
              content: (
                <Column fill>
                  <CardBox>
                    <Column fill>
                      <Row>
                        <Column>
                        <TextField
                        label="Avant 2012"
                          value={context.state["TableauPoints"+ClientConjoint].RetraiteCompAgircTCAvant2012}
                          onChange={context.handleModalPoint(ClientConjoint,"RetraiteCompAgircTCAvant2012")}
                          dataType="ufloat"
                          format={{ thousands: " ", precision: 2, decimal: "," }}
                          normal
                        />
                        </Column>
                      </Row>
                      <Row>
                        <Column>
                        <TextField
                        label="Entre 2012 et 2015"
                          value={context.state["TableauPoints"+ClientConjoint].RetraiteCompAgircTC20122015}
                          onChange={context.handleModalPoint(ClientConjoint,"RetraiteCompAgircTC20122015")}
                          dataType="ufloat"
                          format={{ thousands: " ", precision: 2, decimal: "," }}
                          normal
                        />
                        </Column>
                      </Row>
                      <Row>
                        <Column>
                        <TextField
                        label="A partir de 2016"
                          value={context.state["TableauPoints"+ClientConjoint].RetraiteCompAgircTC2016}
                          onChange={context.handleModalPoint(ClientConjoint,"RetraiteCompAgircTC2016")}
                          dataType="ufloat"
                          format={{ thousands: " ", precision: 2, decimal: "," }}
                          normal
                        />
                        </Column>
                      </Row>
                    </Column>
                  </CardBox>
                </Column>
              ),
            },
          ]}
          page={0}
          navButtons={{
            validate: {
              onClick: (evt) => {
                setOpenModal(false);
              },
            },
          }}
        />
      </Modal>
    </>
  );
};
export default PointAgircTC;
