import React, { useEffect } from "react";

import { useContext } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";
import Button from "components/ui/controls/button/button";
import TextField from "components/ui/controls/text-field/text-field";
import DropDown from "components/ui/controls/drop-down/drop-down";
import { FaCalculator, FaSync } from "react-icons/fa";
const VersementPer = (props) => {
  const { ClientConjoint } = props;
  const context = useContext(AppContext);
  let Year = new Date().getFullYear();
  useEffect(() => {
    if (Object.keys(context.state.CotisationContratRetraite).length === 0) {
      const Today = new Date();
      let AnneeDebut = Today.getFullYear();
      let AnneeFin = context.state["DateDepartRetraite" + ClientConjoint].slice(-4);
      console.log(context.state.PayeurPrimeRetraite, context.state["FinPeriodeCarriereActuelle" + ClientConjoint]);
      if (context.state.PayeurPrimeRetraite === "Entreprise") {
        AnneeFin = context.state["FinPeriodeCarriereActuelle" + ClientConjoint].slice(-4);
      }
      if (props.AccessRemExpress === true) {
        AnneeFin = AnneeDebut + 1;
      }
      let TableauVersement = {};
      for (var Annee = AnneeDebut; Annee <= Number(AnneeFin); Annee++) {
        let Versement = {
          [Annee]: {
            Versement: 0,
            VersementSaisi: false,
          },
        };
        TableauVersement = { ...TableauVersement, ...Versement };
      }
      context.handleValueChange("CotisationContratRetraite", TableauVersement);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Column fill>
        <CardBox bpad>
          <Column fill>
            <Row>
              {props.AccessRemExpress === false && (
                <Column>
                  <TextField
                    label="Taux d'indexation"
                    value={context.state.TauxIndexationContratRetraite}
                    onChange={context.handleCalculEvolutionContrat}
                    dataType="ufloat"
                    format={{ thousands: " ", unit: "%", decimal: ",", precision: 2 }}
                    normal
                  />
                </Column>
              )}
              <Column hpad>
                <DropDown
                  label="Déduction fiscale des versements"
                  labelStyle={{ whiteSpace: "nowrap" }}
                  value={context.state.DeductionFiscaleVersementContratRetraite}
                  options={[
                    { value: "oui", label: "Oui" },
                    { value: "non", label: "Non" },
                  ]}
                  onChange={context.handleInputChange("DeductionFiscaleVersementContratRetraite")}
                  normal
                />
              </Column>
            </Row>
            <Row tpad>
              <Column fill>
                <table className="mono">
                  <thead>
                    <tr key={"Vyst"}>
                      <th>Année</th>
                      <th>Versement</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.AccessRemExpress === true && Object.keys(context.state.CotisationContratRetraite).length > 0 ? (
                      <tr key={"Vyst-1"}>
                        <td>{Year}</td>
                        <td>
                          <TextField
                            value={context.state.CotisationContratRetraite[Year]["Versement"]}
                            dataType="uint"
                            format={{ thousands: " ", unit: " €" }}
                            onChange={(e) => {
                              context.handleVersementContrat(Year, e.target.value);
                            }}
                          />
                        </td>
                      </tr>
                    ) : (
                      Object.keys(context.state.CotisationContratRetraite).map((Annee, index) => {
                        return (
                          <>
                            <tr key={"Vyst" + index}>
                              <td>{Annee}</td>
                              <td>
                                <TextField
                                  value={context.state.CotisationContratRetraite[Annee]["Versement"]}
                                  dataType="uint"
                                  format={{ thousands: " ", unit: " €" }}
                                  onChange={(e) => {
                                    context.handleVersementContrat(Annee, e.target.value);
                                  }}
                                />
                              </td>
                            </tr>
                          </>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </Column>
            </Row>
          </Column>
        </CardBox>
      </Column>
    </>
  );
};

export default VersementPer;
