import { useState, useContext, useEffect } from "react";
import { FaCalculator, FaEdit, FaGlobe, FaIdBadge, FaMale, FaPlusCircle, FaSync, FaTrashAlt } from "react-icons/fa";
import Button from "components/ui/controls/button/button";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";
import Modal from "components/ui/modal/modal";
import { AppContext } from "components/app/RemExprertProvider";
import CheckBox from "components/ui/controls/check-box/check-box";
import TextField from "components/ui/controls/text-field/text-field";
import { applyFormat } from "components/ui/ui-helpers";
import { CalculPartFiscale } from "components/app/Api/Remuneration/ApiRemuneration";
import ClientPageModal from "pages/extranet/client/client pager";
import DropDown from "components/ui/controls/drop-down/drop-down";
import Dialogs from "components/ui/dialogs/dialogs";

const DossierPage = (props) => {
  const context = useContext(AppContext);
  const [modalOpen, setmodalOpen] = useState(false);
  const correspondanceChargeEnfant = {
    exclusive: "A charge exclusive",
    partagee: "A charge partagée",
    none: "Plus à charge",
  };
  const handlePronom = (civilite) => {
    if (civilite === "Monsieur") {
      return "Il";
    } else {
      return "Elle";
    }
  };
  const handleNe = (civilite) => {
    if (civilite === "Monsieur") {
      return "né";
    } else {
      return "née";
    }
  };
  const handleSituationMaritale = (Civilite, SituationMaritale) => {
    switch (SituationMaritale) {
      case "marie":
        return Civilite === "Monsieur" ? "marié" : "mariée";
      case "pacse":
        return Civilite === "Monsieur" ? "pacsé" : "pacsée";
      case "divorce":
        return Civilite === "Monsieur" ? "divorcé" : "divorcée";
      case "veuf":
        return Civilite === "Monsieur" ? "veuf" : "veuve";

      default:
        return "célibataire";
    }
  };

  useEffect(() => {
    if (context.state.SetPartFiscale === false) {
      CalculPartFiscale(context.state).then((PartFiscale) => {
        context.handleValueChange("PartFiscale", PartFiscale);
      });
    }
    if (context.state.TypeSimulation === "Audit complet" || context.state.TypeSimulation === "Audit retraite") {
      context.GetDateDepartRetraite("", context.state);
      context.CalculMajorationEnfant(context.state.Civilite, context.state.Enfant, context.state.Carriere, context.state.TableauEnfant, "", [
        context.state.CarriereMajorationEnfantSaisi,
        context.state.CarriereMajorationEnfantSaisiFonctionPublique,
        context.state.TrimestreBonificationEnfantSaisiFonctionPublique,
        context.state.CarriereMajorationEnfantSaisiCrpcen,
      ]);
      context.GetDateDepartRetraite("Conjoint", context.state);
      context.CalculMajorationEnfant(context.state.CiviliteConjoint, context.state.Enfant, context.state.CarriereConjoint, context.state.TableauEnfant, "Conjoint", [
        context.state.CarriereMajorationEnfantSaisiConjoint,
        context.state.CarriereMajorationEnfantSaisiFonctionPubliqueConjoint,
        context.state.TrimestreBonificationEnfantSaisiFonctionPubliqueConjoint,
        context.state.CarriereMajorationEnfantSaisiCrpcenConjoint,
      ]);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.state.SetPartFiscale, modalOpen,JSON.stringify(context.state.TableauEnfant),context.state.StatutMarital]);

  return (
    <>
      <Column fill>
        <Row>
          <Column fill>
            <CardBox fill>
              <Column fill>
                <Row>
                  <h1>Paramètres du dossier</h1>
                </Row>
                <Row tpad>
                  <TextField label={"Titre de l'audit pour " + context.state.Prenom} value={context.state.LibelleDossier} onChange={context.handleInputChange("LibelleDossier")} xlarge />
                </Row>
                {(context.state.StatutMarital === "marie" || context.state.StatutMarital === "pacse") &&
                  (context.state.TypeSimulation === "Audit complet" || context.state.TypeSimulation === "Audit retraite") && (
                    <Row tpad>
                      <CheckBox
                        label={"Etudier également la situation pour " + context.state.PrenomConjoint}
                        before
                        inline
                        checked={true}
                        unchecked={false}
                        value={context.state.InclureConjoint}
                        // onChange={context.handleInclureConjoint}
                        onChange={(e) =>
                          e.target.checked === false ? (
                            Dialogs.confirm(
                              "Attention, la suppression du conjoint entraine également la suppression de l'ensemble des données saisies de l'individu et par voie de conséquence des études d'optimisation réalisées.",
                              "Suppression du conjoint",
                              () => {
                                context.handleInclureConjoint(false);
                              },
                              () => {
                                context.handleInclureConjoint(true);
                              }
                            )
                          ) : (
                            <>{context.handleInclureConjoint(e.target.checked)}</>
                          )
                        }
                      />
                    </Row>
                  )}
                {(context.state.StatutMarital === "marie" || context.state.StatutMarital === "pacse") &&
                  (context.state.TypeSimulation === "Audit rémunération" || context.state.TypeSimulation === "Audit prévoyance" ) && (
                    <Row tpad>
                      <DropDown
                        value={context.state.SwitchClient}
                        onChange={context.handleSwitchClient}
                        label="Etude réalisée pour"
                        options={[
                          { value: "client", label: context.state.SwitchPrenom },
                          { value: "conjoint", label: context.state.SwitchPrenomConjoint },
                        ]}
                        xlarge
                      />
                    </Row>
                  )}
              </Column>
            </CardBox>
          </Column>
          <Column fill>
            <CardBox lpad fill>
              <Column fill>
                <Row>
                  <h1>Récapitulatif du client</h1>
                </Row>
                {(context.state.TypeSimulation === "Audit complet" || context.state.TypeSimulation === "Audit retraite") && (
                  <>
                    <Row tpad>
                      {context.state.Prenom + " est " + handleNe(context.state.Civilite) + " le " + applyFormat(context.state.DateNaissance, "date") + "."}
                      {context.state.ApiUser !== true && (
                        <Button
                          onClick={() => {
                            setmodalOpen(true);
                          }}
                          icon
                        >
                          <FaEdit />
                        </Button>
                      )}
                    </Row>
                    {(context.state.StatutMarital === "marie" || context.state.StatutMarital === "pacse") && (
                      <Row tpad>
                        {handlePronom(context.state.Civilite) +
                          " est " +
                          handleSituationMaritale(context.state.Civilite, context.state.StatutMarital) +
                          " à " +
                          context.state.PrenomConjoint +
                          ", " +
                          handleNe(context.state.CiviliteConjoint) +
                          " le " +
                          applyFormat(context.state.DateNaissanceConjoint, "date") +
                          "."}
                      </Row>
                    )}
                    {context.state.Isole === true && <Row tpad>{handlePronom(context.state.Civilite) + "est parent isolé."}</Row>}
                  </>
                )}
                {(context.state.TypeSimulation === "Audit prévoyance" || context.state.TypeSimulation === "Audit rémunération") && context.state.SwitchClient === "client" && (
                  <>
                    <Row tpad>
                      {context.state.SwitchPrenom + " est " + handleNe(context.state.SwitchCivilite) + " le " + applyFormat(context.state.DateNaissance, "date") + "."}
                      {context.state.ApiUser !== true && (
                        <Button
                          onClick={() => {
                            setmodalOpen(true);
                          }}
                          icon
                        >
                          <FaEdit />
                        </Button>
                      )}
                    </Row>
                    {(context.state.StatutMarital === "marie" || context.state.StatutMarital === "pacse") && (
                      <Row tpad>
                        {handlePronom(context.state.SwitchCivilite) +
                          " est " +
                          handleSituationMaritale(context.state.SwitchCivilite, context.state.StatutMarital) +
                          " à " +
                          context.state.PrenomConjoint +
                          ", " +
                          handleNe(context.state.SwitchCiviliteConjoint) +
                          " le " +
                          applyFormat(context.state.DateNaissanceConjoint, "date") +
                          "."}
                      </Row>
                    )}
                    {context.state.Isole === true && <Row tpad>{handlePronom(context.state.Civilite) + "est parent isolé."}</Row>}
                  </>
                )}
                {(context.state.TypeSimulation === "Audit prévoyance" || context.state.TypeSimulation === "Audit rémunération") && context.state.SwitchClient === "conjoint" && (
                  <>
                    <Row tpad>
                      {context.state.SwitchPrenomConjoint + " est " + handleNe(context.state.SwitchCiviliteConjoint) + " le " + applyFormat(context.state.DateNaissanceConjoint, "date") + "."}
                      {context.state.ApiUser !== true && (
                        <Button
                          onClick={() => {
                            setmodalOpen(true);
                          }}
                          icon
                        >
                          <FaEdit />
                        </Button>
                      )}
                    </Row>
                    {(context.state.StatutMarital === "marie" || context.state.StatutMarital === "pacse") && (
                      <Row tpad>
                        {handlePronom(context.state.SwitchCiviliteConjoint) +
                          " est " +
                          handleSituationMaritale(context.state.SwitchCiviliteConjoint, context.state.StatutMarital) +
                          " à " +
                          context.state.SwitchPrenom +
                          ", " +
                          handleNe(context.state.SwitchCivilite) +
                          " le " +
                          applyFormat(context.state.DateNaissance, "date") +
                          "."}
                      </Row>
                    )}
                    {context.state.Isole === true && <Row tpad>{handlePronom(context.state.Civilite) + "est parent isolé."}</Row>}
                  </>
                )}
                <Row tpad>
                  <Column style={{ alignSelf: "center", paddingLeft: "0" }}>Parts fiscales</Column>
                  <Column>
                    <TextField
                      iconBefore={
                        <Button tabIndex="-1" icon onClick={() => context.handleValueChange("SetPartFiscale", false)}>
                          {context.state.SetPartFiscale === false ? <FaCalculator /> : <FaSync style={{ color: "green" }} />}
                        </Button>
                      }
                      value={context.state.PartFiscale}
                      onChange={(e) => {
                        context.handleValueChange("SetPartFiscale", true);
                        context.handleValueChange("PartFiscale", Math.max(1, e.target.value));
                      }}
                      dataType="ufloat"
                      format={{ thousands: " ", precision: 2, decimal: "," }}
                      small
                    />
                  </Column>
                </Row>
              </Column>
            </CardBox>
          </Column>
        </Row>

        <Row tpad>
          <Column fill>
            <CardBox>
              <Column fill>
                <table>
                  <thead>
                    <tr>
                      <th></th>
                      <th>Prénom</th>
                      <th>Date de naissance</th>
                      <th>Situation fiscale</th>
                    </tr>
                  </thead>
                  <tbody>
                    {context.state.TableauEnfant.map((Enfant, Index) => (
                      <tr key={Index}>
                        <td>
                          <FaMale style={{ fontSize: "24px", color: "var(--button-bg-color)" }} />
                        </td>
                        <td>{Enfant.Prenom}</td>
                        <td>{applyFormat(Enfant.DateNaissance, "date")}</td>
                        <td>{correspondanceChargeEnfant[Enfant.Charge]}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Column>
            </CardBox>
          </Column>
        </Row>
      </Column>
      <Modal
        width="80%"
        height="80%"
        title="Fiche client"
        icon={<FaGlobe />}
        visible={modalOpen}
        onClose={() => {
          setmodalOpen(false);
        }}
      >
        <ClientPageModal
          visible={modalOpen}
          onClose={() => {
            setmodalOpen(false);
          }}
        />
      </Modal>
    </>
  );
};

export default DossierPage;
