import React, { useEffect, useState } from "react";

import { useContext } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";
import Button from "components/ui/controls/button/button";
import TextField from "components/ui/controls/text-field/text-field";
import { FaCalculator, FaGlobe, FaSync } from "react-icons/fa";
import { CalculPrestationContrat, CalculRetraite } from "components/app/Api/Retraite/ApiRetraite";
import Modal from "components/ui/modal/modal";
import Pager from "components/ui/controls/pager/pager";
const PrestationsPer = (props) => {
  const { ClientConjoint } = props;
  const context = useContext(AppContext);
  const [AnnneDepartRetraite, SetAnneeDepartRetraite] = useState(0);
  let [idx, setIdx] = useState(0);
  let [openModal, setOpenModal] = useState(false);
  const handleCalculRetraite = () => {
    CalculRetraite(context.state, "", 0, ClientConjoint).then((ResultatRetraite) => {
      CalculPrestationContrat(context.state, ClientConjoint, ResultatRetraite, "").then((Resultat) => {
        // console.log(Resultat,Resultat.TabPrestations,Array.isArray(Resultat.TabPrestations))
        // if (Array.isArray(Resultat.TabPrestations)) {
        context.handleValueChange("TableauSaisieManuelleRente", Resultat.TabPrestations);
        SetAnneeDepartRetraite(Number(ResultatRetraite.RetraiteChoixDepartRetraite.Infos.DateDepartRetraite.slice(0, 4)));
        // }
      });
    });
  };
  useEffect(() => {
    handleCalculRetraite();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const LignePrestation = (Annee) => {
    return (
      context.state.TableauSaisieManuelleRente!==undefined && Object.keys(context.state.TableauSaisieManuelleRente).length > 0 && (
        <tr>
          <td>{context.state.TableauSaisieManuelleRente[Annee]["AnneeDepartRetraiteAffich"]}</td>
          <td>{context.state.TableauSaisieManuelleRente[Annee]["Age"]}</td>
          <td>
            <TextField
              iconBefore={
                <Button
                  icon
                  onClick={(e) => {
                    context.handleSaisieManuelleRente(Annee, "RenteSaisi", e);
                  }}
                >
                  {!context.state.TableauSaisieManuelleRente[Annee]["RenteSaisi"] ? <FaCalculator /> : <FaSync style={{ color: "green" }} />}
                </Button>
              }
              value={context.state.TableauSaisieManuelleRente[Annee]["Rente"]}
              disabled={context.state.SortieContratRetraite === "Capital" ? true : false}
              dataType="uint"
              format={{ thousands: " ", unit: " €" }}
              onChange={(e) => {
                context.handleSaisieManuelleRente(Annee, "Rente", e);
              }}
              normal
            />
          </td>
          <td>
            <TextField
              value={Number(context.state.TableauSaisieManuelleRente[Annee]["PartVersement"]) + Number(context.state.TableauSaisieManuelleRente[Annee]["PartProduit"])}
              onClick={() => {
                setIdx(Annee);
                setOpenModal(true);
              }}
              disabled={context.state.SortieContratRetraite === "RenteSimple" ? true : false}
              dataType="uint"
              format={{ thousands: " ", unit: " €" }}
              normal
            />
          </td>
        </tr>
      )
    );
  };
  return (
    <>
      <Column fill>
        <CardBox bpad>
          <Column fill>
            <Column fill>
              <Row>
                <blockquote>
                  Les prestations sont automatiquement calculées en fonction des paramètres que vous avez renseignés. Vous avez toutefois la possibilité de modifier directement le montant
                  dans les cases.
                </blockquote>
              </Row>
            </Column>
            <Row tpad>
              <Column fill>
                <table className="mono">
                  <thead>
                    <tr>
                      <th>Année</th>
                      <th>Age</th>
                      <th>Rente brute annuelle</th>
                      <th>Capital au terme</th>
                    </tr>
                  </thead>
                  <tbody>
                    {AnnneDepartRetraite > 0 && (
                      <>
                        {LignePrestation(AnnneDepartRetraite)}
                        {LignePrestation(AnnneDepartRetraite + 1)}
                        {LignePrestation(AnnneDepartRetraite + 2)}
                        {LignePrestation(AnnneDepartRetraite + 3)}
                        {LignePrestation(AnnneDepartRetraite + 4)}
                        {LignePrestation("TauxPlein")}
                      </>
                    )}
                    {/* {console.log(context.state.TableauSaisieManuelleRente)} */}
                    {/* {context.state.TableauSaisieManuelleRente.forEach((Infos, Index) => {
                      return (
                        <>
                          <tr>
                            <td>{Infos.AnneeDepartRetraite}</td>
                            <td>{Infos.Age}</td>
                            <td>
                              {(context.state.SortieContratRetraite === "RenteSimple" || context.state.SortieContratRetraite === "Panachage") && (
                                <TextField
                                  iconBefore={
                                    <Button
                                      icon
                                      onClick={(e) => {
                                        context.handleSaisieManuelleRente(Index, "RenteSaisi", e);
                                      }}
                                    >
                                      {!context.state.TableauSaisieManuelleRente[Index]["RenteSaisi"] ? <FaCalculator /> : <FaSync style={{ color: "green" }} />}
                                    </Button>
                                  }
                                  value={context.state.TableauSaisieManuelleRente[Index]["Rente"]}
                                  dataType="uint"
                                  format={{ thousands: " ", unit: " €" }}
                                  onChange={(e) => {
                                    context.handleSaisieManuelleRente(Index, "Rente", e);
                                  }}
                                  normal
                                />
                              )}
                            </td>
                            <td>
                              {(context.state.SortieContratRetraite === "Capital" || context.state.SortieContratRetraite === "Panachage") && (
                                <TextField
                                  value={Number(context.state.TableauSaisieManuelleRente[Index]["PartVersement"]) + Number(context.state.TableauSaisieManuelleRente[Index]["PartProduit"])}
                                  onClick={() => {
                                    setIdx(Index);
                                    setOpenModal(true);
                                  }}
                                  dataType="uint"
                                  format={{ thousands: " ", unit: " €" }}
                                  normal
                                />
                              )}
                            </td>
                          </tr>
                        </>
                      );
                    })} */}
                  </tbody>
                </table>
              </Column>
            </Row>
          </Column>
        </CardBox>
      </Column>

      <Modal
        width="30%"
        height="30%"
        title="EPARGNE ACQUISE"
        icon={<FaGlobe />}
        visible={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
      >
        <Pager
          pages={[
            {
              content: (
                <Column fill>
                  <CardBox>
                    <Column fill>
                      <Row>
                        <Column>
                          <TextField
                            iconBefore={
                              <Button
                                icon
                                onClick={(e) => {
                                  context.handleSaisieManuelleRente(idx, "PartVersementSaisi", e);
                                }}
                              >
                                {!context.state.TableauSaisieManuelleRente?.[idx]?.["PartVersementSaisi"] ? <FaCalculator /> : <FaSync style={{ color: "green" }} />}
                              </Button>
                            }
                            label="PART REPRÉSENTATIVE DES VERSEMENTS"
                            labelStyle={{ whiteSpace: "nowrap" }}
                            value={context.state.TableauSaisieManuelleRente?.[idx]?.["PartVersement"]}
                            onChange={(e) => {
                              context.handleSaisieManuelleRente(idx, "PartVersement", e);
                            }}
                            dataType="uint"
                            format={{ thousands: " ", unit: " €" }}
                            normal
                          />
                        </Column>
                      </Row>
                      <Row>
                        <Column>
                          <TextField
                            iconBefore={
                              <Button
                                icon
                                onClick={(e) => {
                                  context.handleSaisieManuelleRente(idx, "PartProduitSaisi", e);
                                }}
                              >
                                {!context.state.TableauSaisieManuelleRente?.[idx]?.["PartProduitSaisi"] ? <FaCalculator /> : <FaSync style={{ color: "green" }} />}
                              </Button>
                            }
                            label="PART REPRÉSENTATIVE DES PRODUITS"
                            labelStyle={{ whiteSpace: "nowrap" }}
                            value={context.state.TableauSaisieManuelleRente?.[idx]?.["PartProduit"]}
                            onChange={(e) => {
                              context.handleSaisieManuelleRente(idx, "PartProduit", e);
                            }}
                            dataType="uint"
                            format={{ thousands: " ", unit: " €" }}
                            normal
                          />
                        </Column>
                      </Row>
                    </Column>
                  </CardBox>
                </Column>
              ),
            },
          ]}
          page={0}
          navButtons={{
            validate: {
              onClick: (evt) => {
                setOpenModal(false);
              },
            },
          }}
        />
      </Modal>
    </>
  );
};

export default PrestationsPer;
