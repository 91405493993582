import { useCallback, useContext, useEffect, useState } from "react";
import { FaHome } from "react-icons/fa";
import Pager from "components/ui/controls/pager/pager";
import { AppContext } from "components/app/RemExprertProvider";
import DeclarationPER from "components/app/Simulateurs/Declaration PER/DeclarationPER";
import StrategiePER from "components/app/Simulateurs/Strategie PER/strategiePER";
import { RemoveUnecessaryData } from "components/app/Api/ApiData";

const StrategiePERPage = () => {
  const context = useContext(AppContext);
  const [currentPage, setCurrentPage] = useState(1);

  const sendToIframe = useCallback(
    ({ data }) => {
      if (data === "save") {
        let CleanData = RemoveUnecessaryData(context.state);
        delete CleanData.IdUser;
        delete CleanData.IdCabinet;
        let Resultat = {
          DonneesSaisies: {
            Caisse: context.state.StrategiePERCaisse,
            ClasseRetraiteCavp: context.state.StrategiePERClasseRetraiteCavp,
            RemunerationImposable: context.state.StrategiePERRemuImposable,
            AgeDepartRetraite: context.state.StrategiePERAgeDepartRetraite,
            MoisDepartRetraite: context.state.StrategiePERAMoisDepartRetraite,
            RetaiteROBrute: context.state.StrategiePERRetaiteROBrute,
            RetaiteRONette: context.state.StrategiePERRetaiteRONette,
            ChoixStrategie: context.state.StrategiePERChoixStrategie,
            ChoixSortie: context.state.StrategiePERChoixSortie,
            RevenuNetComplementaire: context.state.StrategiePERRevenuNetComplementaire,
            CadreFiscal: context.state.StrategiePERCadreFiscal,
            DisponibleFiscal: context.state.StrategiePERDisponibleFiscal,
            DisponibleTabPlafond163: context.state.StrategiePERDisponibleTabPlafond163,
            DisponiblMutuPlafond: context.state.StrategiePERDisponiblMutuPlafond,
            DisponiblePrevoyanceMadelin: context.state.StrategiePERDisponiblePrevoyanceMadelin,
            DisponibleSanteMadelin: context.state.StrategiePERDisponibleSanteMadelin,
            DisponibleAbondementPERCO: context.state.StrategiePERDisponibleAbondementPERCO,
            DeductionCotisation: context.state.StrategiePERDeductionCotisation,
            Rai: context.state.StrategiePERRai,
            RevenuNetActivite: context.state.StrategiePERRevenuNetActivite,
            RevenuNetRetraite: context.state.StrategiePERRevenuNetRetraite,
            PartFiscaleRetraite: context.state.StrategiePERPartFiscaleRetraite,
            ContratLibelle: context.state.StrategiePERContratLibelle,
            ContratVersementInitial: context.state.StrategiePERContratVersementInitial,
            ContratVersementProgramme: context.state.StrategiePERContratVersementProgramme,
            ContratTauxIndexation: context.state.StrategiePERContratTauxIndexation,
            ContratFraisVersement: context.state.StrategiePERContratFraisVersement,
            ContratGestion: context.state.StrategiePERContratGestion,
            ContratRendementMoyen: context.state.StrategiePERContratRendementMoyen,
            ContratChoixRente: context.state.StrategiePERContratChoixRente,
            ContratTauxConversion: context.state.StrategiePERContratTauxConversion,
            ContratFraisArrerage: context.state.StrategiePERContratFraisArrerage,
            ContratRevalorisationRente: context.state.StrategiePERContratRevalorisationRente,
            ContratDureeFractionnement: context.state.StrategiePERContratDureeFractionnement,
            ContratRevalorisationCapital: context.state.StrategiePERContratRevalorisationCapital,
          },
          Resultat: {
            rente: {
              RegimeObligatoire: context.state.StrategiePERResultat?.ro?.retraiteNetteApresImpot,
              PerIndividuel: context.state.StrategiePERResultat?.sortie?.rente?.renteNetteApresImpot,
              RetraiteNetteTotaleAnn: context.state.StrategiePERResultat?.ro?.retraiteNetteApresImpot + context.state.StrategiePERResultat?.sortie?.rente?.renteNetteApresImpot,
              RetraiteNetteTotaleMens: context.state.StrategiePERResultat?.ro?.retraiteNetteApresImpot / 12 + context.state.StrategiePERResultat?.sortie?.rente?.renteNetteApresImpot / 12,
            },
            capital: {
              RegimeObligatoire: context.state.StrategiePERResultat?.ro?.retraiteNetteApresImpot,
              PerIndividuel: context.state.StrategiePERResultat?.sortie?.capital?.capitalNetApresImpot,
              RetraiteNetteTotaleAnn: context.state.StrategiePERResultat?.ro?.retraiteNetteApresImpot + context.state.StrategiePERResultat?.sortie?.capital?.capitalNetApresImpot,
              RetraiteNetteTotaleMens: context.state.StrategiePERResultat?.ro?.retraiteNetteApresImpot / 12 + context.state.StrategiePERResultat?.sortie?.capital?.capitalNetApresImpot / 12,
            },
          },
        };
        let evt = {
          Action: "save",
          DonneesMako: JSON.stringify(CleanData),
          Resultat: Resultat,
        };
        window.top.postMessage(evt, "*");
      }
    },
    [context.state]
  );

  useEffect(() => {
    window.addEventListener("message", sendToIframe);
    return () => window.removeEventListener("message", sendToIframe);
  }, [sendToIframe]);

  const handleMenu = () => {
    let menu = [
      {
        isTitle: true,
        title: "",
      },
      {
        // label: "Dossier",
        // icon: <FaHome id="DossierAuditCompletPage" />,
        content: <StrategiePER />,
      },
    ];

    return menu;
  };
  return (
    <Pager
      pages={handleMenu()}
      page={currentPage}
      onPageChange={(page) => {
        setCurrentPage(page);
      }}
      // navButtons={{
      //     'cancel' : { onClick: (evt) => { console.log('Cancel click')} },
      //     'previous' : { onClick: (evt) => { console.log('Previous click')} },
      //     'next' : { onClick: (evt) => { console.log('Next click')} },
      //     'validate' : { onClick: (evt) => { console.log('Validate click')} }
      // }}
      // customButtons={
      //     <Row fill>
      //         <Button onClick={(evt) => { console.log('Bouton supp click')}}>
      //             <FaCogs/>
      //             <span>Bouton supplémentaire</span>
      //         </Button>
      //     </Row>
      // }
    />
  );
};

export default StrategiePERPage;
