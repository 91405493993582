import React, { Component } from "react";
import axios from "axios";
import { RevenusFutur } from "components/app/Handlers/RetraiteHandler";
import { CalculRetraite } from "components/app/Api/Retraite/ApiRetraite";
import { CalculArbitrageTns } from "components/app/Api/Remuneration/ApiRemuneration";
import { RemoveUnecessaryData, ApiUrl } from "./ApiData";
import { RapportWORDRachatTrimestre, RapportWORDRetraiteProgressive } from "./Rapport/ApiRapport";
import { User } from "./ApiUser";

const APIURL = ApiUrl;
export const RemExpertResultatTNS = React.createContext();

const InitialRemunerationTNS = {
  CoutEntreprise: 0,
  CoutDividende: 0,
  ImpotSociete: 0,
  TotalChargesSocialesDiv: 0,
  DividendeBrut: 0,

  CoutRemuneration: 0,
  CoutEpargneSalariale: 0,
  CotisationsFacultativesEntreprise: 0,
  CotisationsFacultativesFraisPro: 0,
  RemunerationBrute: 0,
  TotalChargesSocialesRem: 0,
  RemunerationNette: 0,
  RevenuPercu: 0,
  BrutImposable: 0,
  DividendeBrutImposable: 0,
  IrDirigeant: 0,
  VersementFacultatifPerso: 0,
  RevenuImmediat: 0,
  CotisationProductive: 0,
  TauxEfficaciteRiCe: 0,
  TotalChargesSociales: 0,
  DetailCotisations: {},
  DetailCSG: {},
  DetailAssiette: {},
  DetailImpot: {},
  GraphDisponible: {},
  Rapport: {},
  CPRetraiteObligatoire: 0,
  CPRetraiteFacultative: 0,
  CPPEEPERCO: 0,
  CPPrevoyance: 0,
  CPSante: 0,
  CPAvantageNature: 0,
  CPChomage: 0,
};
const InitialPrevTNS = {
  IJMax: 0,
  TauxRempIJMax: 0,
  InvaliditeTotale: 0,
  TauxRempInvaliditeTotale: 0,
  CapitalDeces: 0,
  DoubleEffet: 0,
  FraisObseque: 0,
  TabArretMaladieRegimeObligatoire: [],
  GraphArretMaladieRegimeObligatoire: [],
  GraphInvalRegimeObligatoire: [],
  TabInvalRegimeObligatoire: [],
  TabIJ: [],
  GraphIJ: [],
  PrevRegimeObligatoire: {},
  TabSante: {},
  TabDetailInval: {},
  TabInval: {},
  GraphInval: {},
  TabDeces: {},
  TabRente: {},
  ImgGraphIncap: "",
  ImgGraphInval: "",
};

const InitialRetraite = {
  TotalRetraiteNette: 0,
  TotalRetraiteNetteChoixDepartRetraite: 0,
  TotalRetraiteNetteObligatoireChoixDepartRetraite: 0,
  TotalRetraiteNetteFacultatifChoixDepartRetraite: 0,
  TotalCapitalChoixDepartRetraite: 0,
  TotalCapitalFractionneChoixDepartRetraite: 0,
  TauxRempRetraiteNetteChoixDepartRetraite: 0,
  DateDebutRetraiteProgressive: "01012023",
  DateDepartRetraiteProgressive: "01012023",
  DateDebutRetraiteProgressiveMois: "",
  DateDebutRetraiteProgressiveAnnee: "",
  InfosRetraiteChoixDepart: {},
  InfosRetraite: {},
  InfosRetraiteTauxPlein: {},
  InfosRachat: {},
};

const InitialOptiRemRemuneration = {
  AffichageResultat: {},
  CoutEntreprise: 0,
  CoutDividende: 0,
  ImpotSociete: 0,
  TotalChargesSocialesDiv: 0,
  DividendeBrut: 0,

  CoutRemuneration: 0,
  CoutEpargneSalariale: 0,
  RemunerationBrute: 0,
  TotalChargesSocialesRem: 0,
  RemunerationNette: 0,
  RevenuPercu: 0,
  BrutImposable: 0,
  DividendeBrutImposable: 0,
  IrDirigeant: 0,
  VersementFacultatifPerso: 0,
  RevenuImmediat: 0,
  CotisationProductive: 0,
  TauxEfficaciteRiCe: 0,
  TotalChargesSociales: 0,
  DetailCotisations: {},
  DetailCSG: {},
  DetailAssiette: {},
  DetailImpot: {},
  GraphDisponible: {},
  Rapport: {},
  DetailFicheDePaie: {},
};
let OptiRemRemuClient = { ...InitialOptiRemRemuneration };
let OptiRemRemuConjoint = { ...InitialOptiRemRemuneration };
class ResultatRemExpertTNSProvider extends Component {
  GetResultRemExpert = (Data, ClientConjoint) => {
    return new Promise((resolve, reject) => {
      let CleanData = RemoveUnecessaryData(Data);
      // console.log('DAT',Object.keys(Data).length,Data)
      this.setState({ LoadResultat: true });
      var formData = new FormData();
      formData.append("Data", JSON.stringify(CleanData));
      formData.append("ClientConjoint", ClientConjoint);

      axios({
        method: "post",
        url: `${APIURL}RemunerationTNS.php`,
        data: formData,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        withCredentials: true,
      })
        .then((ResultatRemuneration) => {
          // MODIFICATION DE L'ASSIETTE POUR LA CARRIERE ACTUELLE
          // ATTENTION , Data est passée par référence et la carriere est directement modifié dans les variables de RemExpert

          const PeriodeToDisplay =
            ClientConjoint === ""
              ? CleanData.Carriere.filter((Periode) => Periode.id === "CarriereActuelle")
              : CleanData.CarriereConjoint.filter((Periode) => Periode.id === "CarriereActuelle");

          if (PeriodeToDisplay.length > 0) {
            PeriodeToDisplay.forEach((Periode) => {
              var CurrentYear = new Date().getFullYear();
              const NewDetailRetraiteFutur = RevenusFutur(CurrentYear, Periode.DetailRetraiteFutur, ResultatRemuneration.data.InfosRemuneration.AssietteRetraite, Periode.MontantEvolution);
              Periode.Detail = NewDetailRetraiteFutur;
              Periode.DetailRetraiteFutur = NewDetailRetraiteFutur;
            });

            const NewCarriere = PeriodeToDisplay[0];

            if (ClientConjoint === "") {
              CleanData.Carriere = CleanData.Carriere.filter((Periode) => Periode.id !== "CarriereActuelle");
              CleanData.Carriere = [...CleanData.Carriere, NewCarriere];
              Data.Carriere = CleanData.Carriere;
            } else {
              CleanData.CarriereConjoint = CleanData.CarriereConjoint.filter((Periode) => Periode.id !== "CarriereActuelle");
              CleanData.CarriereConjoint = [...CleanData.CarriereConjoint, NewCarriere];
              Data.CarriereConjoint = CleanData.CarriereConjoint;
            }
          }

          let SalaireReference = ResultatRemuneration.data.AffichageResultat.RemunerationNette;
          const annee = new Date().getFullYear();
          if (ClientConjoint === "") {
            for (const periode of Data.Carriere) {
              if (annee in periode.Detail && periode.id !== "CarriereActuelle") {
                SalaireReference += Number(periode.Detail[annee]["Salaire"]);
              }
            }
            if (CleanData.CarriereSalaireReferenceSaisi === false) {
              Data.CarriereSalaireReference = SalaireReference;
              Data.CarriereSalaireReferenceCalcule = SalaireReference;
              CleanData.CarriereSalaireReference = SalaireReference;
            }
          } else {
            for (const periode of Data.CarriereConjoint) {
              if (annee in periode.Detail && periode.id !== "CarriereActuelle") {
                SalaireReference += Number(periode.Detail[annee]["Salaire"]);
              }
              if (CleanData.CarriereSalaireReferenceSaisiConjoint === false) {
                Data.CarriereSalaireReferenceConjoint = SalaireReference;
                Data.CarriereSalaireReferenceCalculeConjoint = SalaireReference;
                CleanData.CarriereSalaireReferenceConjoint = SalaireReference;
              }
            }
          }
          // var formData = new FormData();
          formData.Data = JSON.stringify(CleanData);
          this.setState(
            {
              [ClientConjoint !== "" ? "RemunerationTNSConjoint" : "RemunerationTNS"]: {
                CoutEntreprise: ResultatRemuneration.data.AffichageResultat.CoutEntreprise,
                CoutDividende: ResultatRemuneration.data.AffichageResultat.CoutDividende,
                ImpotSociete: ResultatRemuneration.data.AffichageResultat.ImpotSociete,
                TotalChargesSocialesDiv: ResultatRemuneration.data.AffichageResultat.TotalChargesSocialesDiv,
                DividendeBrut: ResultatRemuneration.data.AffichageResultat.DividendeBrut,
                DividendeNet: ResultatRemuneration.data.AffichageResultat.DividendeNet,

                CoutRemuneration: ResultatRemuneration.data.AffichageResultat.CoutRemuneration,
                CoutEpargneSalariale: ResultatRemuneration.data.AffichageResultat.CoutEpargneSalariale,
                CotisationsFacultativesEntreprise: ResultatRemuneration.data.AffichageResultat.CotisationsFacultativesEntreprise,
                CotisationsFacultativesFraisPro: ResultatRemuneration.data.AffichageResultat.CotisationsFacultativesFraisPro,
                RemunerationBrute: ResultatRemuneration.data.AffichageResultat.RemunerationBrute,
                TotalChargesSocialesRem: ResultatRemuneration.data.AffichageResultat.TotalChargesSocialesRem,
                RemunerationNette: ResultatRemuneration.data.AffichageResultat.RemunerationNette,
                RevenuPercu: ResultatRemuneration.data.AffichageResultat.RevenuPercu,
                BrutImposable: ResultatRemuneration.data.AffichageResultat.BrutImposable,
                DividendeBrutImposable: ResultatRemuneration.data.AffichageResultat.DividendeBrutImposable,
                IrDirigeant: ResultatRemuneration.data.AffichageResultat.IrDirigeant,
                VersementFacultatifPerso: ResultatRemuneration.data.AffichageResultat.VersementFacultatifPerso,
                RevenuImmediat: ResultatRemuneration.data.AffichageResultat.RevenuImmediat,
                CotisationProductive: ResultatRemuneration.data.AffichageResultat.CotisationProductive,
                TauxEfficaciteRiCe: ResultatRemuneration.data.AffichageResultat.TauxEfficaciteRiCe,
                TotalChargesSociales: ResultatRemuneration.data.InfosRemuneration.TotalChargesSociales,
                DetailCotisations: ResultatRemuneration.data.InfosRemuneration.DetailCotisations,
                DetailCSG: ResultatRemuneration.data.InfosRemuneration.DetailCSG,
                DetailAssiette: ResultatRemuneration.data.InfosRemuneration.DetailAssiette,
                DetailImpot: ResultatRemuneration.data.InfosRemuneration.DetailImpot,
                GraphDisponible: ResultatRemuneration.data.GraphDisponible,
                Rapport: ResultatRemuneration.data.InfosRemuneration.Rapport,
              },
            },
            () => {
              CalculRetraite(CleanData, "", SalaireReference, ClientConjoint).then((ResultatRetraite) => {
                this.setState({
                  [ClientConjoint !== "" ? "RetraiteConjoint" : "Retraite"]: {
                    TotalRetraiteNetteChoixDepartRetraite: ResultatRetraite.TotalRetraiteNetteChoixDepartRetraite,
                    TauxRempRetraiteNetteChoixDepartRetraite: ResultatRetraite.TauxRempRetraiteNetteChoixDepartRetraite,
                    TotalRetraiteNetteObligatoireChoixDepartRetraite: ResultatRetraite.TotalRetraiteNetteObligatoireChoixDepartRetraite,
                    TotalRetraiteNetteFacultatifChoixDepartRetraite: ResultatRetraite.TotalRetraiteNetteFacultatifChoixDepartRetraite,
                    TotalCapitalChoixDepartRetraite: ResultatRetraite.TotalCapitalChoixDepartRetraite,
                    TotalCapitalFractionneChoixDepartRetraite: ResultatRetraite.TotalCapitalFractionneChoixDepartRetraite,
                    InfosRetraiteChoixDepart: ResultatRetraite.RetraiteChoixDepartRetraite,
                    InfosRetraite: ResultatRetraite.Retraite,
                    InfosRetraiteTauxPlein: ResultatRetraite.RetraiteTauxPlein,
                    DateDebutRetraiteProgressive: ResultatRetraite.DateDebutRetraiteProgressive,
                    DateDepartRetraiteProgressive: ResultatRetraite.DateDepartRetraiteProgressive,
                    DateDebutRetraiteProgressiveMois: ResultatRetraite.DateDebutRetraiteProgressiveMois,
                    DateDebutRetraiteProgressiveAnnee: ResultatRetraite.DateDebutRetraiteProgressiveAnnee,
                  },
                });
              });
            }
          );
          formData.append("AssietteCalcul", ResultatRemuneration.data.InfosRemuneration.DetailAssiette.DetailAssietteTotale);
          formData.append("Revenus", ResultatRemuneration.data.AffichageResultat.RemunerationNette);
          formData.append("AssietteMadelin", ResultatRemuneration.data.AffichageResultat.BrutImposable);
        })
        .catch(function (error) {
          if (parseInt(error.response.request.status) === 401) {
            var event = new CustomEvent("session-expired", {
              detail: {
                logoutMessage: decodeURI(error.response.request.statusText).replace(/\+/g, " "),
              },
            });
            window.dispatchEvent(event);
          } else {
            User(
              {
                UserName: Data.Conseiller,
                ClientName: Data.Prenom + " " + Data.Nom,
                ClientDossier: Data.LibelleDossier,
                TypeError: "ERREUR REMU TNS",
              },
              "SendMailToSupport"
            );
            alert("Erreur interne. Merci de sauvegarder votre dossier et de contacter le support.");
          }
        })
        .then((response) => {
          axios({
            method: "post",
            url: `${APIURL}PrevoyanceTNS.php`,
            data: formData,
            headers: {
              "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
            },
            withCredentials: true,
          })
            .then((response) => {
              this.setState(
                {
                  LoadResultat: false,
                  [ClientConjoint !== "" ? "PrevTNSConjoint" : "PrevTNS"]: {
                    IJMax: response.data.AffichageResultat.IJMax,
                    TauxRempIJMax: response.data.AffichageResultat.TauxRempIJMax,
                    InvaliditeTotale: response.data.AffichageResultat.InvaliditeTotale,
                    TauxRempInvaliditeTotale: response.data.AffichageResultat.TauxRempInvaliditeTotale,
                    CapitalDeces: response.data.AffichageResultat.CapitalDeces,
                    TabArretMaladieRegimeObligatoire: response.data.TabArretMaladieRegimeObligatoire,
                    GraphArretMaladieRegimeObligatoire: response.data.GraphArretMaladieRegimeObligatoire,
                    GraphInvalRegimeObligatoire: response.data.GraphInvalRegimeObligatoire,
                    TabInvalRegimeObligatoire: response.data.TabInvalRegimeObligatoire,
                    TabIJ: response.data.TabIJ,
                    GraphIJ: response.data.GraphIJ,
                    PrevRegimeObligatoire: response.data.PrevRegimeObligatoire,
                    TabSante: response.data.TabSante,
                    TabDetailInval: response.data.TabDetailInval,
                    TabInval: response.data.TabInval,
                    GraphInval: response.data.GraphInval,
                    TabDeces: response.data.TabDeces,
                    TabRente: response.data.TabRente,
                    ImgGraphIncap: response.data.ImgGraphIncap,
                    ImgGraphInval: response.data.ImgGraphInval,
                    DoubleEffet: response.data.DoubleEffet,
                    FraisObseque: response.data.FraisObseque,
                  },
                },
                () => {
                  resolve(this.state[ClientConjoint !== "" ? "RemunerationTNSConjoint" : "RemunerationTNS"].BrutImposable);
                }
              );
            })
            .catch(function (error) {
              if (parseInt(error.response.request.status) === 401) {
                var event = new CustomEvent("session-expired", {
                  detail: {
                    logoutMessage: decodeURI(error.response.request.statusText).replace(/\+/g, " "),
                  },
                });
                window.dispatchEvent(event);
              } else {
                User(
                  {
                    UserName: Data.Conseiller,
                    ClientName: Data.Prenom + " " + Data.Nom,
                    ClientDossier: Data.LibelleDossier,
                    TypeError: "ERREUR PREV TNS",
                  },
                  "SendMailToSupport"
                );
                alert("Erreur interne. Merci de sauvegarder votre dossier et de contacter le support.");
              }
            });
        });
    });
  };

  GetImpot = (Outil, Data, BrutImposableClient, BrutImposableConjoint, DividendeBrut) => {
    return new Promise((resolve, reject) => {
      let CleanData = RemoveUnecessaryData(Data);
      this.setState({ LoadResultat: true });
      var formData = new FormData();
      formData.append("Data", JSON.stringify(CleanData));
      formData.append("Outil", Outil);
      formData.append("BrutImposableClient", BrutImposableClient);
      formData.append("BrutImposableConjoint", BrutImposableConjoint);
      formData.append("DividendeBrut", DividendeBrut);

      axios({
        method: "post",
        url: `${APIURL}Impot.php`,
        data: formData,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        withCredentials: true,
      })
        .then((DetailImpot) => {
          this.setState(
            {
              [Outil + "DetailImpot"]: DetailImpot.data,
            },
            () => {
              resolve(DetailImpot.data);
            }
          );
        })
        .catch(function (error) {
          if (parseInt(error.response.request.status) === 401) {
            var event = new CustomEvent("session-expired", {
              detail: {
                logoutMessage: decodeURI(error.response.request.statusText).replace(/\+/g, " "),
              },
            });
            window.dispatchEvent(event);
          } else {
            User(
              {
                UserName: Data.Conseiller,
                ClientName: Data.Prenom + " " + Data.Nom,
                ClientDossier: Data.LibelleDossier,
                TypeError: "ERREUR IMPOT",
              },
              "SendMailToSupport"
            );
            alert("Erreur interne. Merci de sauvegarder votre dossier et de contacter le support.");
          }
        });
    });
  };

  CalculPrevoyanceTNS = (Data, Outil, ClientConjoint, AssietteCalcul, Revenus, AssietteMadelin) => {
    return new Promise((resolve, reject) => {
      let CleanData = RemoveUnecessaryData(Data);
      this.setState({ LoadResultat: true });
      var formData = new FormData();
      formData.append("Data", JSON.stringify(CleanData));
      formData.append("ClientConjoint", ClientConjoint);
      formData.append("Outil", Outil);
      formData.append("AssietteCalcul", AssietteCalcul);
      formData.append("Revenus", Revenus);
      formData.append("AssietteMadelin", AssietteMadelin);
      axios({
        method: "post",
        url: `${APIURL}PrevoyanceTNS.php`,
        data: formData,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        withCredentials: true,
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch(function (error) {
          if (parseInt(error.response.request.status) === 401) {
            var event = new CustomEvent("session-expired", {
              detail: {
                logoutMessage: decodeURI(error.response.request.statusText).replace(/\+/g, " "),
              },
            });
            window.dispatchEvent(event);
          } else {
            User(
              {
                UserName: Data.Conseiller,
                ClientName: Data.Prenom + " " + Data.Nom,
                ClientDossier: Data.LibelleDossier,
                TypeError: "ERREUR PREV TNS",
              },
              "SendMailToSupport"
            );
            alert("Erreur interne. Merci de sauvegarder votre dossier et de contacter le support.");
          }
        });
    });
  };

  CalculPrevoyanceSalarie = (Data, Outil, ClientConjoint, Prevoyance83Salariale, Prevoyance83Patronale, DepassementSocial83, BaseSociale, RevenusBrut, RevenusNet, AssietteMadelin) => {
    return new Promise((resolve, reject) => {
      let CleanData = RemoveUnecessaryData(Data);
      this.setState({ LoadResultat: true });
      var formData = new FormData();
      formData.append("Data", JSON.stringify(CleanData));
      formData.append("ClientConjoint", ClientConjoint);
      formData.append("Outil", Outil);

      formData.append("Prevoyance83Salariale", Prevoyance83Salariale);
      formData.append("Prevoyance83Patronale", Prevoyance83Patronale);
      formData.append("DepassementSocial83", DepassementSocial83);
      formData.append("BaseSociale", BaseSociale);
      formData.append("RevenusBrut", RevenusBrut);
      formData.append("RevenusNet", RevenusNet);
      formData.append("AssietteMadelin", AssietteMadelin);

      axios({
        method: "post",
        url: `${APIURL}PrevoyanceSALARIE.php`,
        data: formData,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        withCredentials: true,
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch(function (error) {
          if (parseInt(error.response.request.status) === 401) {
            var event = new CustomEvent("session-expired", {
              detail: {
                logoutMessage: decodeURI(error.response.request.statusText).replace(/\+/g, " "),
              },
            });
            window.dispatchEvent(event);
          } else {
            User(
              {
                UserName: Data.Conseiller,
                ClientName: Data.Prenom + " " + Data.Nom,
                ClientDossier: Data.LibelleDossier,
                TypeError: "ERREUR PREV SALARIE",
              },
              "SendMailToSupport"
            );
            alert("Erreur interne. Merci de sauvegarder votre dossier et de contacter le support.");
          }
        });
    });
  };
  GetResultRemExpress = (Data, RemunerationNette, DividendeBrut) => {
    return new Promise((resolve, reject) => {
      let CleanData = RemoveUnecessaryData(Data);
      let Div = DividendeBrut;
      if (CleanData.Statut === "TNS individuel") {
        Div = 0;
      }
      if (CleanData.RemExpressTypeCalcul.length === 0) {
        this.setState({ RemunerationTNS: { ...InitialRemunerationTNS } });
      } else {
        this.setState({ LoadResultat: true });
        var formData = new FormData();
        formData.append("Data", JSON.stringify(CleanData));
        formData.append("RemunerationNette", RemunerationNette);
        formData.append("DividendeBrut", Div);

        axios({
          method: "post",
          url: `${APIURL}RemExpress.php`,
          data: formData,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          withCredentials: true,
        })
          .then((ResultatRemuneration) => {
            const RemExpressRemuClient = {
              AffichageResultat: ResultatRemuneration.data.Client.AffichageResultat,
              CoutEntreprise: ResultatRemuneration.data.Client.AffichageResultat.CoutEntreprise,
              CoutDividende: ResultatRemuneration.data.Client.AffichageResultat.CoutDividende,
              ImpotSociete: ResultatRemuneration.data.Client.AffichageResultat.ImpotSociete,
              TotalChargesSocialesDiv: ResultatRemuneration.data.Client.AffichageResultat.TotalChargesSocialesDiv,
              DividendeBrut: ResultatRemuneration.data.Client.AffichageResultat.DividendeBrut,
              DividendeNet: ResultatRemuneration.data.Client.AffichageResultat.DividendeNet,
              AssietteRetraite: ResultatRemuneration.data.Client.InfosRemuneration.AssietteRetraite,
              CoutRemuneration: ResultatRemuneration.data.Client.AffichageResultat.CoutRemuneration,
              CoutEpargneSalariale: ResultatRemuneration.data.Client.AffichageResultat.CoutEpargneSalariale,
              RemunerationBrute: ResultatRemuneration.data.Client.AffichageResultat.RemunerationBrute,
              TotalChargesSocialesRem: ResultatRemuneration.data.Client.AffichageResultat.TotalChargesSocialesRem,
              RemunerationNette: ResultatRemuneration.data.Client.AffichageResultat.RemunerationNette,
              RevenuPercu: ResultatRemuneration.data.Client.AffichageResultat.RevenuPercu,
              BrutImposable: ResultatRemuneration.data.Client.AffichageResultat.BrutImposable,
              DividendeBrutImposable: ResultatRemuneration.data.Client.AffichageResultat.DividendeBrutImposable,
              IrDirigeant: ResultatRemuneration.data.Client.AffichageResultat.IrDirigeant,
              VersementFacultatifPerso: ResultatRemuneration.data.Client.AffichageResultat.VersementFacultatifPerso,
              RevenuImmediat: ResultatRemuneration.data.Client.AffichageResultat.RevenuImmediat,
              CotisationProductive: ResultatRemuneration.data.Client.AffichageResultat.CotisationProductive,
              TauxEfficaciteRiCe: ResultatRemuneration.data.Client.AffichageResultat.TauxEfficaciteRiCe,
              TotalChargesSociales: ResultatRemuneration.data.Client.InfosRemuneration.TotalChargesSociales,
              CotisationsFacultativesEntreprise: ResultatRemuneration.data.Client.AffichageResultat.CotisationsFacultativesEntreprise,
              CotisationsFacultativesFraisPro: ResultatRemuneration.data.Client.AffichageResultat.CotisationsFacultativesFraisPro,
              DetailCotisations: ResultatRemuneration.data.Client.InfosRemuneration.DetailCotisations,
              DetailCSG: ResultatRemuneration.data.Client.InfosRemuneration.DetailCSG,
              DetailAssiette: ResultatRemuneration.data.Client.InfosRemuneration.DetailAssiette,
              DetailImpot: ResultatRemuneration.data.Client.InfosRemuneration.DetailImpot,
              GraphDisponible: ResultatRemuneration.data.Client.GraphDisponible,
              DetailFicheDePaie: ResultatRemuneration.data.Client.InfosRemuneration.DetailFicheDePaie,
              Rapport: ResultatRemuneration.data.Client.InfosRemuneration.Rapport,
              InfosRemuneration: ResultatRemuneration.data.Client.InfosRemuneration,
            };
            this.setState(
              {
                LoadResultat: false,
                RemExpressRemuneration: RemExpressRemuClient,
                // RemunerationTNS: {
                //   CoutEntreprise: ResultatRemuneration.data.AffichageResultat.CoutEntreprise,
                //   CoutDividende: ResultatRemuneration.data.AffichageResultat.CoutDividende,
                //   ImpotSociete: ResultatRemuneration.data.AffichageResultat.ImpotSociete,
                //   TotalChargesSocialesDiv: ResultatRemuneration.data.AffichageResultat.TotalChargesSocialesDiv,
                //   DividendeBrut: ResultatRemuneration.data.AffichageResultat.DividendeBrut,
                //   DividendeNet: ResultatRemuneration.data.AffichageResultat.DividendeNet,

                //   CoutRemuneration: ResultatRemuneration.data.AffichageResultat.CoutRemuneration,
                //   CoutEpargneSalariale: ResultatRemuneration.data.AffichageResultat.CoutEpargneSalariale,
                //   RemunerationBrute: ResultatRemuneration.data.AffichageResultat.RemunerationBrute,
                //   TotalChargesSocialesRem: ResultatRemuneration.data.AffichageResultat.TotalChargesSocialesRem,
                //   RemunerationNette: ResultatRemuneration.data.AffichageResultat.RemunerationNette,
                //   RevenuPercu: ResultatRemuneration.data.AffichageResultat.RevenuPercu,
                //   BrutImposable: ResultatRemuneration.data.AffichageResultat.BrutImposable,
                //   DividendeBrutImposable: ResultatRemuneration.data.AffichageResultat.DividendeBrutImposable,
                //   IrDirigeant: ResultatRemuneration.data.AffichageResultat.IrDirigeant,
                //   VersementFacultatifPerso: ResultatRemuneration.data.AffichageResultat.VersementFacultatifPerso,
                //   RevenuImmediat: ResultatRemuneration.data.AffichageResultat.RevenuImmediat,
                //   CotisationProductive: ResultatRemuneration.data.AffichageResultat.CotisationProductive,
                //   TauxEfficaciteRiCe: ResultatRemuneration.data.AffichageResultat.TauxEfficaciteRiCe,
                //   TotalChargesSociales: ResultatRemuneration.data.InfosRemuneration.TotalChargesSociales,
                //   DetailCotisations: ResultatRemuneration.data.InfosRemuneration.DetailCotisations,
                //   DetailCSG: ResultatRemuneration.data.InfosRemuneration.DetailCSG,
                //   DetailAssiette: ResultatRemuneration.data.InfosRemuneration.DetailAssiette,
                //   DetailImpot: ResultatRemuneration.data.InfosRemuneration.DetailImpot,
                //   GraphDisponible: ResultatRemuneration.data.GraphDisponible,
                //   Rapport: ResultatRemuneration.data.InfosRemuneration.Rapport,
                //   CPRetraiteObligatoire: ResultatRemuneration.data.InfosRemuneration.Rapport.RetraiteObligatoire,
                //   CPRetraiteFacultative: ResultatRemuneration.data.InfosRemuneration.Rapport.RetraiteFacultative,
                //   CPPEEPERCO: ResultatRemuneration.data.InfosRemuneration.Rapport.TotalPEEProductif + ResultatRemuneration.data.InfosRemuneration.Rapport.TotalPERCOProductif,
                //   CPPrevoyance: ResultatRemuneration.data.InfosRemuneration.Rapport.TotalPrevoyance,
                //   CPSante: ResultatRemuneration.data.InfosRemuneration.Rapport.TotalSante,
                //   CPAvantageNature: ResultatRemuneration.data.InfosRemuneration.DetailAssiette.DetailAvantageNature,
                //   CPChomage: 0,
                // },
              },
              () => {
                resolve({
                  RemunerationClient: this.state.RemExpressRemuneration,
                  ResAvtIs: ResultatRemuneration.data.ResAvtIs,
                  ImpotSoc: ResultatRemuneration.data.ImpotSoc,
                  DivBruts: ResultatRemuneration.data.DivBruts,
                  DivBrutsAutres: ResultatRemuneration.data.DivBrutsAutres,
                });
              }
            );
          })
          .catch(function (error) {
            if (parseInt(error.response.request.status) === 401) {
              var event = new CustomEvent("session-expired", {
                detail: {
                  logoutMessage: decodeURI(error.response.request.statusText).replace(/\+/g, " "),
                },
              });
              window.dispatchEvent(event);
            } else {
              User(
                {
                  UserName: Data.Conseiller,
                  ClientName: Data.Prenom + " " + Data.Nom,
                  ClientDossier: Data.LibelleDossier,
                  TypeError: "ERREUR REM EXPRESS",
                },
                "SendMailToSupport"
              );
              alert("Erreur interne. Merci de sauvegarder votre dossier et de contacter le support.");
            }
          });
      }
    });
  };

  GetResultOptiRem = (Data, ClientConjoint, CalculCDS, RemunerationNette, DividendeBrut, OptionPfu) => {
    return new Promise((resolve, reject) => {
      let CleanData = RemoveUnecessaryData(Data);
      // this.setState({ LoadResultat: true });
      var formData = new FormData();
      formData.append("Data", JSON.stringify(CleanData));
      formData.append("ClientConjoint", ClientConjoint);
      formData.append("CalculCDS", CalculCDS);
      formData.append("RemunerationNette", RemunerationNette);
      formData.append("DividendeBrut", DividendeBrut);
      formData.append("OptionPfu", OptionPfu);
      formData.append("Outil", "OptiRem");

      axios({
        method: "post",
        url: `${APIURL}OptiRem.php`,
        data: formData,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        withCredentials: true,
      })
        .then((ResultatRemuneration) => {
          if (ClientConjoint !== "Conjoint") {
            OptiRemRemuClient = {
              AffichageResultat: ResultatRemuneration.data.Client.AffichageResultat,
              CoutEntreprise: ResultatRemuneration.data.Client.AffichageResultat.CoutEntreprise,
              CoutDividende: ResultatRemuneration.data.Client.AffichageResultat.CoutDividende,
              ImpotSociete: ResultatRemuneration.data.Client.AffichageResultat.ImpotSociete,
              TotalChargesSocialesDiv: ResultatRemuneration.data.Client.AffichageResultat.TotalChargesSocialesDiv,
              DividendeBrut: ResultatRemuneration.data.Client.AffichageResultat.DividendeBrut,
              DividendeNet: ResultatRemuneration.data.Client.AffichageResultat.DividendeNet,
              AssietteRetraite: ResultatRemuneration.data.Client.InfosRemuneration.AssietteRetraite,
              CoutRemuneration: ResultatRemuneration.data.Client.AffichageResultat.CoutRemuneration,
              CoutEpargneSalariale: ResultatRemuneration.data.Client.AffichageResultat.CoutEpargneSalariale,
              RemunerationBrute: ResultatRemuneration.data.Client.AffichageResultat.RemunerationBrute,
              TotalChargesSocialesRem: ResultatRemuneration.data.Client.AffichageResultat.TotalChargesSocialesRem,
              RemunerationNette: ResultatRemuneration.data.Client.AffichageResultat.RemunerationNette,
              RevenuPercu: ResultatRemuneration.data.Client.AffichageResultat.RevenuPercu,
              BrutImposable: ResultatRemuneration.data.Client.AffichageResultat.BrutImposable,
              DividendeBrutImposable: ResultatRemuneration.data.Client.AffichageResultat.DividendeBrutImposable,
              IrDirigeant: ResultatRemuneration.data.Client.AffichageResultat.IrDirigeant,
              VersementFacultatifPerso: ResultatRemuneration.data.Client.AffichageResultat.VersementFacultatifPerso,
              RevenuImmediat: ResultatRemuneration.data.Client.AffichageResultat.RevenuImmediat,
              CotisationProductive: ResultatRemuneration.data.Client.AffichageResultat.CotisationProductive,
              TauxEfficaciteRiCe: ResultatRemuneration.data.Client.AffichageResultat.TauxEfficaciteRiCe,
              TotalChargesSociales: ResultatRemuneration.data.Client.InfosRemuneration.TotalChargesSociales,
              CotisationsFacultativesEntreprise: ResultatRemuneration.data.Client.AffichageResultat.CotisationsFacultativesEntreprise,
              CotisationsFacultativesFraisPro: ResultatRemuneration.data.Client.AffichageResultat.CotisationsFacultativesFraisPro,
              DetailCotisations: ResultatRemuneration.data.Client.InfosRemuneration.DetailCotisations,
              DetailCSG: ResultatRemuneration.data.Client.InfosRemuneration.DetailCSG,
              DetailAssiette: ResultatRemuneration.data.Client.InfosRemuneration.DetailAssiette,
              DetailImpot: ResultatRemuneration.data.Client.InfosRemuneration.DetailImpot,
              GraphDisponible: ResultatRemuneration.data.Client.GraphDisponible,
              DetailFicheDePaie: ResultatRemuneration.data.Client.InfosRemuneration.DetailFicheDePaie,
              Rapport: ResultatRemuneration.data.Client.InfosRemuneration.Rapport,
              InfosRemuneration: ResultatRemuneration.data.Client.InfosRemuneration,
            };
          }

          if (ClientConjoint === "Conjoint") {
            OptiRemRemuConjoint = {
              AffichageResultat: ResultatRemuneration.data.Client.AffichageResultat,
              CoutEntreprise: ResultatRemuneration.data.Client.AffichageResultat.CoutEntreprise,
              CoutDividende: ResultatRemuneration.data.Client.AffichageResultat.CoutDividende,
              ImpotSociete: ResultatRemuneration.data.Client.AffichageResultat.ImpotSociete,
              TotalChargesSocialesDiv: ResultatRemuneration.data.Client.AffichageResultat.TotalChargesSocialesDiv,
              DividendeBrut: ResultatRemuneration.data.Client.AffichageResultat.DividendeBrut,
              DividendeNet: ResultatRemuneration.data.Client.AffichageResultat.DividendeNet,
              AssietteRetraite: ResultatRemuneration.data.Client.InfosRemuneration.AssietteRetraite,
              CoutRemuneration: ResultatRemuneration.data.Client.AffichageResultat.CoutRemuneration,
              CoutEpargneSalariale: ResultatRemuneration.data.Client.AffichageResultat.CoutEpargneSalariale,
              RemunerationBrute: ResultatRemuneration.data.Client.AffichageResultat.RemunerationBrute,
              TotalChargesSocialesRem: ResultatRemuneration.data.Client.AffichageResultat.TotalChargesSocialesRem,
              RemunerationNette: ResultatRemuneration.data.Client.AffichageResultat.RemunerationNette,
              RevenuPercu: ResultatRemuneration.data.Client.AffichageResultat.RevenuPercu,
              BrutImposable: ResultatRemuneration.data.Client.AffichageResultat.BrutImposable,
              DividendeBrutImposable: ResultatRemuneration.data.Client.AffichageResultat.DividendeBrutImposable,
              IrDirigeant: ResultatRemuneration.data.Client.AffichageResultat.IrDirigeant,
              VersementFacultatifPerso: ResultatRemuneration.data.Client.AffichageResultat.VersementFacultatifPerso,
              RevenuImmediat: ResultatRemuneration.data.Client.AffichageResultat.RevenuImmediat,
              CotisationProductive: ResultatRemuneration.data.Client.AffichageResultat.CotisationProductive,
              TauxEfficaciteRiCe: ResultatRemuneration.data.Client.AffichageResultat.TauxEfficaciteRiCe,
              TotalChargesSociales: ResultatRemuneration.data.Client.InfosRemuneration.TotalChargesSociales,
              CotisationsFacultativesEntreprise: ResultatRemuneration.data.Client.AffichageResultat.CotisationsFacultativesEntreprise,
              CotisationsFacultativesFraisPro: ResultatRemuneration.data.Client.AffichageResultat.CotisationsFacultativesFraisPro,
              DetailCotisations: ResultatRemuneration.data.Client.InfosRemuneration.DetailCotisations,
              DetailCSG: ResultatRemuneration.data.Client.InfosRemuneration.DetailCSG,
              DetailAssiette: ResultatRemuneration.data.Client.InfosRemuneration.DetailAssiette,
              DetailImpot: ResultatRemuneration.data.Client.InfosRemuneration.DetailImpot,
              GraphDisponible: ResultatRemuneration.data.Client.GraphDisponible,
              DetailFicheDePaie: ResultatRemuneration.data.Client.InfosRemuneration.DetailFicheDePaie,
              Rapport: ResultatRemuneration.data.Client.InfosRemuneration.Rapport,
              InfosRemuneration: ResultatRemuneration.data.Client.InfosRemuneration,
            };
          }
          if (ResultatRemuneration.data.Conjoint !== null) {
            OptiRemRemuConjoint = {
              AffichageResultat: ResultatRemuneration.data.Conjoint.AffichageResultat,
              CoutEntreprise: ResultatRemuneration.data.Conjoint.AffichageResultat.CoutEntreprise,
              CoutDividende: ResultatRemuneration.data.Conjoint.AffichageResultat.CoutDividende,
              ImpotSociete: ResultatRemuneration.data.Conjoint.AffichageResultat.ImpotSociete,
              TotalChargesSocialesDiv: ResultatRemuneration.data.Conjoint.AffichageResultat.TotalChargesSocialesDiv,
              DividendeBrut: ResultatRemuneration.data.Conjoint.AffichageResultat.DividendeBrut,
              DividendeNet: ResultatRemuneration.data.Conjoint.AffichageResultat.DividendeNet,
              AssietteRetraite: ResultatRemuneration.data.Conjoint.InfosRemuneration.AssietteRetraite,
              CoutRemuneration: ResultatRemuneration.data.Conjoint.AffichageResultat.CoutRemuneration,
              CoutEpargneSalariale: ResultatRemuneration.data.Conjoint.AffichageResultat.CoutEpargneSalariale,
              RemunerationBrute: ResultatRemuneration.data.Conjoint.AffichageResultat.RemunerationBrute,
              TotalChargesSocialesRem: ResultatRemuneration.data.Conjoint.AffichageResultat.TotalChargesSocialesRem,
              RemunerationNette: ResultatRemuneration.data.Conjoint.AffichageResultat.RemunerationNette,
              RevenuPercu: ResultatRemuneration.data.Conjoint.AffichageResultat.RevenuPercu,
              BrutImposable: ResultatRemuneration.data.Conjoint.AffichageResultat.BrutImposable,
              DividendeBrutImposable: ResultatRemuneration.data.Conjoint.AffichageResultat.DividendeBrutImposable,
              IrDirigeant: ResultatRemuneration.data.Conjoint.AffichageResultat.IrDirigeant,
              VersementFacultatifPerso: ResultatRemuneration.data.Conjoint.AffichageResultat.VersementFacultatifPerso,
              RevenuImmediat: ResultatRemuneration.data.Conjoint.AffichageResultat.RevenuImmediat,
              CotisationProductive: ResultatRemuneration.data.Conjoint.AffichageResultat.CotisationProductive,
              TauxEfficaciteRiCe: ResultatRemuneration.data.Conjoint.AffichageResultat.TauxEfficaciteRiCe,
              TotalChargesSociales: ResultatRemuneration.data.Conjoint.InfosRemuneration.TotalChargesSociales,
              CotisationsFacultativesEntreprise: ResultatRemuneration.data.Conjoint.AffichageResultat.CotisationsFacultativesEntreprise,
              CotisationsFacultativesFraisPro: ResultatRemuneration.data.Conjoint.AffichageResultat.CotisationsFacultativesFraisPro,
              DetailCotisations: ResultatRemuneration.data.Conjoint.InfosRemuneration.DetailCotisations,
              DetailCSG: ResultatRemuneration.data.Conjoint.InfosRemuneration.DetailCSG,
              DetailAssiette: ResultatRemuneration.data.Conjoint.InfosRemuneration.DetailAssiette,
              DetailImpot: ResultatRemuneration.data.Conjoint.InfosRemuneration.DetailImpot,
              GraphDisponible: ResultatRemuneration.data.Conjoint.GraphDisponible,
              DetailFicheDePaie: ResultatRemuneration.data.Conjoint.InfosRemuneration.DetailFicheDePaie,
              Rapport: ResultatRemuneration.data.Conjoint.InfosRemuneration.Rapport,
              InfosRemuneration: ResultatRemuneration.data.Conjoint.InfosRemuneration,
            };
          }
          this.setState(
            {
              OptiRemRemuneration: OptiRemRemuClient,
              OptiRemRemunerationConjoint: OptiRemRemuConjoint,
            },
            () => {
              resolve({
                RemunerationClient: this.state.OptiRemRemuneration,
                RemunerationConjoint: this.state.OptiRemRemunerationConjoint,
                ResAvtIs: ResultatRemuneration.data.ResAvtIs,
                ImpotSoc: ResultatRemuneration.data.ImpotSoc,
                DivBruts: ResultatRemuneration.data.DivBruts,
                DivBrutsAutres: ResultatRemuneration.data.DivBrutsAutres,
                OptionPfu: ResultatRemuneration.data.OptionPfu,
              });
            }
          );
        })
        .catch(function (error) {
          if (parseInt(error.response.request.status) === 401) {
            var event = new CustomEvent("session-expired", {
              detail: {
                logoutMessage: decodeURI(error.response.request.statusText).replace(/\+/g, " "),
              },
            });
            window.dispatchEvent(event);
          }
        });
    });
  };

  GetResultBilanRetraite = (Data, ClientConjoint) => {
    return new Promise((resolve, reject) => {
      let CleanData = RemoveUnecessaryData(Data);
      this.setState({ LoadResultat: true });
      var formData = new FormData();
      formData.append("Data", JSON.stringify(CleanData));
      formData.append("ClientConjoint", ClientConjoint);
      let SalaireReference = 0;
      if (Data.TypeSimulation !== "Audit retraite") {
        if (ClientConjoint !== "") {
          SalaireReference = this.state.RemunerationTNSConjoint.RemunerationNette;
        } else {
          SalaireReference = this.state.RemunerationTNS.RemunerationNette;
        }
      }
      let annee = new Date().getFullYear();
      for (const periode of Data["Carriere" + ClientConjoint]) {
        if (annee in periode.Detail) {
          SalaireReference += Number(periode.Detail[annee]["Salaire"]);
        }
      }
      if (CleanData.CarriereSalaireReferenceSaisi === false) {
        Data.CarriereSalaireReference = SalaireReference;
        Data.CarriereSalaireReferenceCalcule = SalaireReference;
        CleanData.CarriereSalaireReferenceCalcule = SalaireReference;
        CleanData.CarriereSalaireReference = SalaireReference;
      }
      // CalculRetraite(Data, ResultatRemuneration.data.AffichageResultat.RemunerationNette, "").then(ResultatRetraite => {
      CalculRetraite(CleanData, "", SalaireReference, ClientConjoint).then((ResultatRetraite) => {
        this.setState(
          {
            LoadResultat: false,
            [ClientConjoint !== "" ? "RetraiteConjoint" : "Retraite"]: {
              TotalRetraiteNetteChoixDepartRetraite: ResultatRetraite.TotalRetraiteNetteChoixDepartRetraite,
              TauxRempRetraiteNetteChoixDepartRetraite: ResultatRetraite.TauxRempRetraiteNetteChoixDepartRetraite,
              TotalRetraiteNetteObligatoireChoixDepartRetraite: ResultatRetraite.TotalRetraiteNetteObligatoireChoixDepartRetraite,
              TotalRetraiteNetteFacultatifChoixDepartRetraite: ResultatRetraite.TotalRetraiteNetteFacultatifChoixDepartRetraite,
              TotalCapitalChoixDepartRetraite: ResultatRetraite.TotalCapitalChoixDepartRetraite,
              TotalCapitalFractionneChoixDepartRetraite: ResultatRetraite.TotalCapitalFractionneChoixDepartRetraite,
              InfosRetraiteChoixDepart: ResultatRetraite.RetraiteChoixDepartRetraite,
              InfosRetraite: ResultatRetraite.Retraite,
              InfosRetraiteTauxPlein: ResultatRetraite.RetraiteTauxPlein,
              DateDebutRetraiteProgressive: ResultatRetraite.DateDebutRetraiteProgressive,
              DateDepartRetraiteProgressive: ResultatRetraite.DateDepartRetraiteProgressive,
              DateDebutRetraiteProgressiveMois: ResultatRetraite.DateDebutRetraiteProgressiveMois,
              DateDebutRetraiteProgressiveAnnee: ResultatRetraite.DateDebutRetraiteProgressiveAnnee,
            },
          },
          () => {
            resolve(ResultatRetraite);
          }
        );
      });
    });
  };
  GetResultPrevTns = (Data) => {
    let CleanData = RemoveUnecessaryData(Data);
    this.setState({ LoadResultat: true });
    var formData = new FormData();
    formData.append("AssietteCalcul", Data.Remuneration);
    formData.append("Outil", "PrevTNS");
    formData.append("ClientConjoint", "");
    formData.append("Revenus", Data.Remuneration);
    formData.append("Data", JSON.stringify(CleanData));

    axios({
      method: "post",
      url: `${APIURL}PrevoyanceTNS.php`,
      data: formData,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      withCredentials: true,
    })
      .then((response) => {
        this.setState(
          {
            PrevTNS: {
              IJMax: response.data.AffichageResultat.IJMax,
              TauxRempIJMax: response.data.AffichageResultat.TauxRempIJMax,
              InvaliditeTotale: response.data.AffichageResultat.InvaliditeTotale,
              TauxRempInvaliditeTotale: response.data.AffichageResultat.TauxRempInvaliditeTotale,
              CapitalDeces: response.data.AffichageResultat.CapitalDeces,
              TabArretMaladieRegimeObligatoire: response.data.TabArretMaladieRegimeObligatoire,
              GraphArretMaladieRegimeObligatoire: response.data.GraphArretMaladieRegimeObligatoire,
              GraphInvalRegimeObligatoire: response.data.GraphInvalRegimeObligatoire,
              TabInvalRegimeObligatoire: response.data.TabInvalRegimeObligatoire,
              TabIJ: response.data.TabIJ,
              GraphIJ: response.data.GraphIJ,
              PrevRegimeObligatoire: response.data.PrevRegimeObligatoire,
              TabSante: response.data.TabSante,
              TabDetailInval: response.data.TabDetailInval,
              TabInval: response.data.TabInval,
              GraphInval: response.data.GraphInval,
              TabDeces: response.data.TabDeces,
              TabRente: response.data.TabRente,
              ImgGraphIncap: response.data.ImgGraphIncap,
              ImgGraphInval: response.data.ImgGraphInval,
              DoubleEffet: response.data.DoubleEffet,
              FraisObseque: response.data.FraisObseque,
            },
          },
          () => {
            CleanData.ContratEnregistre = CleanData.PrevTNSPreco1ContratEnregistre;
            CleanData.ContratFraisPro = CleanData.PrevTNSPreco1ContratFraisPro;
            var formData = new FormData();
            formData.append("ClientConjoint", "");
            formData.append("AssietteCalcul", Data.Remuneration);
            formData.append("Revenus", Data.Remuneration);
            formData.append("Data", JSON.stringify(CleanData));
            axios({
              method: "post",
              url: `${APIURL}PrevoyanceTNS.php`,
              data: formData,
              headers: {
                "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
              },
              withCredentials: true,
            })
              .then((response) => {
                this.setState(
                  {
                    PrevTNSPreco1: {
                      IJMax: response.data.AffichageResultat.IJMax,
                      TauxRempIJMax: response.data.AffichageResultat.TauxRempIJMax,
                      InvaliditeTotale: response.data.AffichageResultat.InvaliditeTotale,
                      TauxRempInvaliditeTotale: response.data.AffichageResultat.TauxRempInvaliditeTotale,
                      CapitalDeces: response.data.AffichageResultat.CapitalDeces,
                      TabArretMaladieRegimeObligatoire: response.data.TabArretMaladieRegimeObligatoire,
                      GraphArretMaladieRegimeObligatoire: response.data.GraphArretMaladieRegimeObligatoire,
                      GraphInvalRegimeObligatoire: response.data.GraphInvalRegimeObligatoire,
                      TabInvalRegimeObligatoire: response.data.TabInvalRegimeObligatoire,
                      TabIJ: response.data.TabIJ,
                      GraphIJ: response.data.GraphIJ,
                      PrevRegimeObligatoire: response.data.PrevRegimeObligatoire,
                      TabSante: response.data.TabSante,
                      TabDetailInval: response.data.TabDetailInval,
                      TabInval: response.data.TabInval,
                      GraphInval: response.data.GraphInval,
                      TabDeces: response.data.TabDeces,
                      TabRente: response.data.TabRente,
                      ImgGraphIncap: response.data.ImgGraphIncap,
                      ImgGraphInval: response.data.ImgGraphInval,
                      DoubleEffet: response.data.DoubleEffet,
                      FraisObseque: response.data.FraisObseque,
                    },
                  },
                  () => {
                    CleanData.ContratEnregistre = CleanData.PrevTNSPreco2ContratEnregistre;
                    CleanData.ContratFraisPro = CleanData.PrevTNSPreco2ContratFraisPro;
                    var formData = new FormData();
                    formData.append("ClientConjoint", "");
                    formData.append("AssietteCalcul", Data.Remuneration);
                    formData.append("Revenus", Data.Remuneration);
                    formData.append("Data", JSON.stringify(CleanData));
                    axios({
                      method: "post",
                      url: `${APIURL}PrevoyanceTNS.php`,
                      data: formData,
                      headers: {
                        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
                      },
                      withCredentials: true,
                    })
                      .then((response) => {
                        this.setState(
                          {
                            PrevTNSPreco2: {
                              IJMax: response.data.AffichageResultat.IJMax,
                              TauxRempIJMax: response.data.AffichageResultat.TauxRempIJMax,
                              InvaliditeTotale: response.data.AffichageResultat.InvaliditeTotale,
                              TauxRempInvaliditeTotale: response.data.AffichageResultat.TauxRempInvaliditeTotale,
                              CapitalDeces: response.data.AffichageResultat.CapitalDeces,
                              TabArretMaladieRegimeObligatoire: response.data.TabArretMaladieRegimeObligatoire,
                              GraphArretMaladieRegimeObligatoire: response.data.GraphArretMaladieRegimeObligatoire,
                              GraphInvalRegimeObligatoire: response.data.GraphInvalRegimeObligatoire,
                              TabInvalRegimeObligatoire: response.data.TabInvalRegimeObligatoire,
                              TabIJ: response.data.TabIJ,
                              GraphIJ: response.data.GraphIJ,
                              PrevRegimeObligatoire: response.data.PrevRegimeObligatoire,
                              TabSante: response.data.TabSante,
                              TabDetailInval: response.data.TabDetailInval,
                              TabInval: response.data.TabInval,
                              GraphInval: response.data.GraphInval,
                              TabDeces: response.data.TabDeces,
                              TabRente: response.data.TabRente,
                              ImgGraphIncap: response.data.ImgGraphIncap,
                              ImgGraphInval: response.data.ImgGraphInval,
                              DoubleEffet: response.data.DoubleEffet,
                              FraisObseque: response.data.FraisObseque,
                            },
                          },
                          () => {
                            CleanData.ContratEnregistre = CleanData.PrevTNSPreco3ContratEnregistre;
                            CleanData.ContratFraisPro = CleanData.PrevTNSPreco3ContratFraisPro;
                            var formData = new FormData();
                            formData.append("ClientConjoint", "");
                            formData.append("AssietteCalcul", Data.Remuneration);
                            formData.append("Revenus", Data.Remuneration);
                            formData.append("Data", JSON.stringify(CleanData));
                            axios({
                              method: "post",
                              url: `${APIURL}PrevoyanceTNS.php`,
                              data: formData,
                              headers: {
                                "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
                              },
                              withCredentials: true,
                            })
                       
                              .then((response) => {
                                this.setState({
                                  LoadResultat: false,
                                  PrevTNSPreco3: {
                                    IJMax: response.data.AffichageResultat.IJMax,
                                    TauxRempIJMax: response.data.AffichageResultat.TauxRempIJMax,
                                    InvaliditeTotale: response.data.AffichageResultat.InvaliditeTotale,
                                    TauxRempInvaliditeTotale: response.data.AffichageResultat.TauxRempInvaliditeTotale,
                                    CapitalDeces: response.data.AffichageResultat.CapitalDeces,
                                    TabArretMaladieRegimeObligatoire: response.data.TabArretMaladieRegimeObligatoire,
                                    GraphArretMaladieRegimeObligatoire: response.data.GraphArretMaladieRegimeObligatoire,
                                    GraphInvalRegimeObligatoire: response.data.GraphInvalRegimeObligatoire,
                                    TabInvalRegimeObligatoire: response.data.TabInvalRegimeObligatoire,
                                    TabIJ: response.data.TabIJ,
                                    GraphIJ: response.data.GraphIJ,
                                    PrevRegimeObligatoire: response.data.PrevRegimeObligatoire,
                                    TabSante: response.data.TabSante,
                                    TabDetailInval: response.data.TabDetailInval,
                                    TabInval: response.data.TabInval,
                                    GraphInval: response.data.GraphInval,
                                    TabDeces: response.data.TabDeces,
                                    TabRente: response.data.TabRente,
                                    ImgGraphIncap: response.data.ImgGraphIncap,
                                    ImgGraphInval: response.data.ImgGraphInval,
                                    DoubleEffet: response.data.DoubleEffet,
                                    FraisObseque: response.data.FraisObseque,
                                  },
                                });
                              })
                              .catch(function (error) {
                                if (parseInt(error.response.request.status) === 401) {
                                  var event = new CustomEvent("session-expired", {
                                    detail: {
                                      logoutMessage: decodeURI(error.response.request.statusText).replace(/\+/g, " "),
                                    },
                                  });
                                  window.dispatchEvent(event);
                                }
                              })
                          }
                        );
                      })
                      .catch(function (error) {
                        if (parseInt(error.response.request.status) === 401) {
                          var event = new CustomEvent("session-expired", {
                            detail: {
                              logoutMessage: decodeURI(error.response.request.statusText).replace(/\+/g, " "),
                            },
                          });
                          window.dispatchEvent(event);
                        }
                      });
                  }
                );
              })
              .catch(function (error) {
                if (parseInt(error.response.request.status) === 401) {
                  var event = new CustomEvent("session-expired", {
                    detail: {
                      logoutMessage: decodeURI(error.response.request.statusText).replace(/\+/g, " "),
                    },
                  });
                  window.dispatchEvent(event);
                }
              });
          }
        );
      })
      .catch(function (error) {
        if (parseInt(error.response.request.status) === 401) {
          var event = new CustomEvent("session-expired", {
            detail: {
              logoutMessage: decodeURI(error.response.request.statusText).replace(/\+/g, " "),
            },
          });
          window.dispatchEvent(event);
        }
      });
  };

  GetResultRachatTrimestre = (Data, SalaireReference, ClientConjoint) => {
    let CleanData = RemoveUnecessaryData(Data);
    this.setState({ LoadResultatRachat: true });
    // CalculRetraite(Data, ResultatRemuneration.data.AffichageResultat.RemunerationNette, "").then(ResultatRetraite => {
    CalculRetraite(CleanData, "RachatTrimestre", SalaireReference, ClientConjoint).then((ResultatRetraite) => {
      this.setState(
        {
          [ClientConjoint !== "" ? "RetraiteConjoint" : "Retraite"]: {
            TotalRetraiteNetteChoixDepartRetraite: ResultatRetraite.TotalRetraiteNetteChoixDepartRetraite,
            TauxRempRetraiteNetteChoixDepartRetraite: ResultatRetraite.TauxRempRetraiteNetteChoixDepartRetraite,
            TotalRetraiteNetteObligatoireChoixDepartRetraite: ResultatRetraite.TotalRetraiteNetteObligatoireChoixDepartRetraite,
            TotalRetraiteNetteFacultatifChoixDepartRetraite: ResultatRetraite.TotalRetraiteNetteFacultatifChoixDepartRetraite,
            TotalCapitalChoixDepartRetraite: ResultatRetraite.TotalCapitalChoixDepartRetraite,
            TotalCapitalFractionneChoixDepartRetraite: ResultatRetraite.TotalCapitalFractionneChoixDepartRetraite,
            InfosRetraiteChoixDepart: ResultatRetraite.RetraiteChoixDepartRetraite,
            InfosRetraite: ResultatRetraite.Retraite,
            InfosRetraiteTauxPlein: ResultatRetraite.RetraiteTauxPlein,
            InfosRachat: ResultatRetraite.InfosRachat,
            DateDebutRetraiteProgressive: ResultatRetraite.DateDebutRetraiteProgressive,
            DateDepartRetraiteProgressive: ResultatRetraite.DateDepartRetraiteProgressive,
            DateDebutRetraiteProgressiveMois: ResultatRetraite.DateDebutRetraiteProgressiveMois,
            DateDebutRetraiteProgressiveAnnee: ResultatRetraite.DateDebutRetraiteProgressiveAnnee,
          },
        },
        () => {
          RapportWORDRachatTrimestre(CleanData, ClientConjoint, this.state["Retraite" + ClientConjoint]);
        }
      );
    });
  };

  GetResultRetraiteProgressive = (Data, PeriodeRetraiteProgressive, SalaireReference, ClientConjoint) => {
    let CleanData = RemoveUnecessaryData(Data);
    CleanData.PeriodeRetraiteProgressive = PeriodeRetraiteProgressive;
    this.setState({ LoadResultatRachat: true });
    // CalculRetraite(Data, ResultatRemuneration.data.AffichageResultat.RemunerationNette, "").then(ResultatRetraite => {
    CalculRetraite(CleanData, "RetraiteProgressive", SalaireReference, ClientConjoint).then((ResultatRetraite) => {
      this.setState(
        {
          [ClientConjoint !== "" ? "RetraiteConjoint" : "Retraite"]: {
            TotalRetraiteNetteChoixDepartRetraite: ResultatRetraite.TotalRetraiteNetteChoixDepartRetraite,
            TauxRempRetraiteNetteChoixDepartRetraite: ResultatRetraite.TauxRempRetraiteNetteChoixDepartRetraite,
            TotalRetraiteNetteObligatoireChoixDepartRetraite: ResultatRetraite.TotalRetraiteNetteObligatoireChoixDepartRetraite,
            TotalRetraiteNetteFacultatifChoixDepartRetraite: ResultatRetraite.TotalRetraiteNetteFacultatifChoixDepartRetraite,
            TotalCapitalChoixDepartRetraite: ResultatRetraite.TotalCapitalChoixDepartRetraite,
            TotalCapitalFractionneChoixDepartRetraite: ResultatRetraite.TotalCapitalFractionneChoixDepartRetraite,
            InfosRetraiteChoixDepart: ResultatRetraite.RetraiteChoixDepartRetraite,
            InfosRetraite: ResultatRetraite.Retraite,
            InfosRetraiteTauxPlein: ResultatRetraite.RetraiteTauxPlein,
            InfosAvantRetraiteProgressive: ResultatRetraite.ResultAvantRetraiteProgressive,
            InfosApresRetraiteProgressive: ResultatRetraite.ResultApresRetraiteProgressive,
            DateDebutRetraiteProgressive: ResultatRetraite.DateDebutRetraiteProgressive,
            DateDepartRetraiteProgressive: ResultatRetraite.DateDepartRetraiteProgressive,
            DateDebutRetraiteProgressiveMois: ResultatRetraite.DateDebutRetraiteProgressiveMois,
            DateDebutRetraiteProgressiveAnnee: ResultatRetraite.DateDebutRetraiteProgressiveAnnee,
          },
        },
        () => {
          RapportWORDRetraiteProgressive(CleanData,ClientConjoint, this.state['Retraite'+ClientConjoint]);
        }
      );
    });
  };

  constructor(props) {
    super(props);
    this.state = {
      LoadResultat: false,
      LoadResultatRachat: false,
      RemunerationTNS: { ...InitialRemunerationTNS },
      DetailImpot: {},
      RemunerationTNSConjoint: { ...InitialRemunerationTNS },
      GSRemunerationTNS: { ...InitialRemunerationTNS },
      PrevTNS: { ...InitialPrevTNS },
      PrevTNSConjoint: { ...InitialPrevTNS },
      Retraite: {
        ...InitialRetraite,
      },
      RetraiteConjoint: {
        ...InitialRetraite,
      },
      OptiRemRemuneration: {
        CoutEntreprise: 0,
        CoutDividende: 0,
        ImpotSociete: 0,
        TotalChargesSocialesDiv: 0,
        DividendeBrut: 0,

        CoutRemuneration: 0,
        CoutEpargneSalariale: 0,
        RemunerationBrute: 0,
        TotalChargesSocialesRem: 0,
        RemunerationNette: 0,
        RevenuPercu: 0,
        BrutImposable: 0,
        DividendeBrutImposable: 0,
        IrDirigeant: 0,
        VersementFacultatifPerso: 0,
        RevenuImmediat: 0,
        CotisationProductive: 0,
        TauxEfficaciteRiCe: 0,
        TotalChargesSociales: 0,
        DetailCotisations: {},
        DetailCSG: {},
        DetailAssiette: {},
        DetailImpot: {},
        GraphDisponible: {},
        Rapport: {},
        DetailFicheDePaie: {},
      },
      OptiRemRemunerationConjoint: {
        ...InitialOptiRemRemuneration,
      },
      CDSRemunerationTNS: {
        CoutEntreprise: 0,
        CoutDividende: 0,
        ImpotSociete: 0,
        TotalChargesSocialesDiv: 0,
        DividendeBrut: 0,

        CoutRemuneration: 0,
        CoutEpargneSalariale: 0,
        RemunerationBrute: 0,
        TotalChargesSocialesRem: 0,
        RemunerationNette: 0,
        RevenuPercu: 0,
        BrutImposable: 0,
        DividendeBrutImposable: 0,
        IrDirigeant: 0,
        VersementFacultatifPerso: 0,
        RevenuImmediat: 0,
        CotisationProductive: 0,
        TauxEfficaciteRiCe: 0,
        TotalChargesSociales: 0,
        DetailCotisations: {},
        DetailCSG: {},
        DetailAssiette: {},
        DetailImpot: {},
        GraphDisponible: {},
        Rapport: {},
      },
      OptiRemRetraite: {
        TotalRetraiteNette: 0,
        TotalRetraiteNetteChoixDepartRetraite: 0,
        TotalRetraiteNetteObligatoireChoixDepartRetraite: 0,
        TotalRetraiteNetteFacultatifChoixDepartRetraite: 0,
        TotalCapitalChoixDepartRetraite: 0,
        TotalCapitalFractionneChoixDepartRetraite: 0,
        TauxRempRetraiteNetteChoixDepartRetraite: 0,
        InfosRetraiteChoixDepart: {},
        InfosRetraite: {},
        InfosRetraiteTauxPlein: {},
      },
      CDSRetraite: {
        TotalRetraiteNette: 0,
        TotalRetraiteNetteChoixDepartRetraite: 0,
        TotalRetraiteNetteObligatoireChoixDepartRetraite: 0,
        TotalRetraiteNetteFacultatifChoixDepartRetraite: 0,
        TotalCapitalChoixDepartRetraite: 0,
        TotalCapitalFractionneChoixDepartRetraite: 0,
        TauxRempRetraiteNetteChoixDepartRetraite: 0,
        InfosRetraiteChoixDepart: {},
        InfosRetraite: {},
        InfosRetraiteTauxPlein: {},
      },
      OptiRemPrevTNS: {
        IJMax: 0,
        TauxRempIJMax: 0,
        InvaliditeTotale: 0,
        TauxRempInvaliditeTotale: 0,
        CapitalDeces: 0,
        TabArretMaladieRegimeObligatoire: [],
        GraphArretMaladieRegimeObligatoire: [],
        TabIJ: [],
        GraphIJ: [],
        PrevRegimeObligatoire: {},
        TabSante: {},
        TabDetailInval: {},
        TabInval: {},
        GraphInval: {},
        TabDeces: {},
        TabRente: {},
      },
      CDSPrevTNS: {
        IJMax: 0,
        TauxRempIJMax: 0,
        InvaliditeTotale: 0,
        TauxRempInvaliditeTotale: 0,
        CapitalDeces: 0,
        TabArretMaladieRegimeObligatoire: [],
        GraphArretMaladieRegimeObligatoire: [],
        TabIJ: [],
        GraphIJ: [],
        PrevRegimeObligatoire: {},
        TabSante: {},
        TabDetailInval: {},
        TabInval: {},
        GraphInval: {},
        TabDeces: {},
        TabRente: {},
      },
      PrevTNSPreco1: {
        IJMax: 0,
        TauxRempIJMax: 0,
        InvaliditeTotale: 0,
        TauxRempInvaliditeTotale: 0,
        CapitalDeces: 0,
        TabArretMaladieRegimeObligatoire: [],
        GraphArretMaladieRegimeObligatoire: [],
        TabIJ: [],
        GraphIJ: [],
        PrevRegimeObligatoire: {},
        TabSante: {},
        TabDetailInval: {},
        TabInval: {},
        GraphInval: {},
        TabDeces: {},
        TabRente: {},
      },
      PrevTNSPreco2: {
        IJMax: 0,
        TauxRempIJMax: 0,
        InvaliditeTotale: 0,
        TauxRempInvaliditeTotale: 0,
        CapitalDeces: 0,
        TabArretMaladieRegimeObligatoire: [],
        GraphArretMaladieRegimeObligatoire: [],
        TabIJ: [],
        GraphIJ: [],
        PrevRegimeObligatoire: {},
        TabSante: {},
        TabDetailInval: {},
        TabInval: {},
        GraphInval: {},
        TabDeces: {},
        TabRente: {},
      },
      PrevTNSPreco3: {
        IJMax: 0,
        TauxRempIJMax: 0,
        InvaliditeTotale: 0,
        TauxRempInvaliditeTotale: 0,
        CapitalDeces: 0,
        TabArretMaladieRegimeObligatoire: [],
        GraphArretMaladieRegimeObligatoire: [],
        TabIJ: [],
        GraphIJ: [],
        PrevRegimeObligatoire: {},
        TabSante: {},
        TabDetailInval: {},
        TabInval: {},
        GraphInval: {},
        TabDeces: {},
        TabRente: {},
      },
    };
  }

  render() {
    return (
      <RemExpertResultatTNS.Provider
        value={{
          state: this.state,
          GetResultRemExpert: this.GetResultRemExpert,
          GetImpot: this.GetImpot,
          CalculPrevoyanceTNS: this.CalculPrevoyanceTNS,
          CalculPrevoyanceSalarie: this.CalculPrevoyanceSalarie,
          GetResultRemExpress: this.GetResultRemExpress,
          GetResultGSTns: this.GetResultGSTns,
          GetResultOptiRem: this.GetResultOptiRem,
          GetDateDepartRetraite: this.GetDateDepartRetraite,
          GetResultBilanRetraite: this.GetResultBilanRetraite,
          GetResultPrevTns: this.GetResultPrevTns,
          GetPrevROIJ: this.GetPrevROIJ,
          GetResultRachatTrimestre: this.GetResultRachatTrimestre,
          GetResultRetraiteProgressive: this.GetResultRetraiteProgressive,
        }}
      >
        {this.props.children}
      </RemExpertResultatTNS.Provider>
    );
  }
}

export default ResultatRemExpertTNSProvider;
