import "./../../../../pages/audit complet/simulation/simulation.css";
import "./CorrectionPER.css";
import { useState, useContext, useEffect } from "react";
import { FaCog, FaEdit, FaGlobe, FaPrint, FaUndo } from "react-icons/fa";
import Button from "components/ui/controls/button/button";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import Row from "components/ui/layout/row/row";
import Modal from "components/ui/modal/modal";
import { AppContext } from "components/app/RemExprertProvider";
import TextField from "components/ui/controls/text-field/text-field";
import DropDown from "components/ui/controls/drop-down/drop-down";
import { SyntheseCorrectionPER } from "components/app/Api/Rapport/ApiRapport";
import _ from "lodash";
import { GetCalculCorrectionPER } from "components/app/Api/ApiSimulateur";
import Pager from "components/ui/controls/pager/pager";
import CorrrectionPERAnnee from "./CorrectionPERAnnee";

const CorrectionPER = (props) => {
  const dt = new Date();
  const yearM1 = dt.getFullYear() - 1;
  const yearM2 = dt.getFullYear() - 2;
  const yearM3 = dt.getFullYear() - 3;
  const year = dt.getFullYear();

  const context = useContext(AppContext);
  const [paramModal, setModalParam] = useState(false);
  const [clientConjoint, setClientConjoint] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [yearToModify, setYearToModify] = useState(0);
  const [plafondClient, setPlafondClient] = useState(0);
  const [plafondConjoint, setPlafondConjoint] = useState(0);
  const initialValues = {
    DeclaPERRemunerationImposable: 0,
    DeclaPERRegimeFiscal: "tns",
    DeclaPERAbondementPERCO: 0,
    DeclaPERRetraiteMadelin: 0,
    DeclaPERPrevoyanceMadelin: 0,
    DeclaPERRetraite83: 0,
    DeclaPERRetraite163: 0,
    DeclaPERAssietteDisponible: "exclu",
    DispoPERCorrige: 0,
    DeclaPERRemunerationImposableConjoint: 0,
    DeclaPERRegimeFiscalConjoint: "tns",
    DeclaPERAbondementPERCOConjoint: 0,
    DeclaPERRetraiteMadelinConjoint: 0,
    DeclaPERPrevoyanceMadelinConjoint: 0,
    DeclaPERRetraite83Conjoint: 0,
    DeclaPERRetraite163Conjoint: 0,
    DeclaPERAssietteDisponibleConjoint: "exclu",
    DispoPERCorrigeConjoint: 0,
  };

  //INIT DU TABLEAU DES CORRECTIONS PER
  const [tabCorrectionPER, setTabCorrectionPER] = useState({});
  useEffect(() => {
    let InitValues = _.cloneDeep(context.state.CorrectionPER);
    if (!(year in InitValues)) {
      InitValues[year] = { ...initialValues };
    }
    if (!(yearM1 in InitValues)) {
      InitValues[yearM1] = { ...initialValues };
    }
    if (!(yearM2 in InitValues)) {
      InitValues[yearM2] = { ...initialValues };
    }
    if (!(yearM3 in InitValues)) {
      InitValues[yearM3] = { ...initialValues };
    }
    setTabCorrectionPER(InitValues);
    handleCalculPlafondDeduction(InitValues, context.state.CorrectionPERMutuPlafond);
  }, []);

  const handleResetCorrectionPER = () => {
    let InitValues = [];
    InitValues[year] = { ...initialValues };
    InitValues[yearM1] = { ...initialValues };
    InitValues[yearM2] = { ...initialValues };
    InitValues[yearM3] = { ...initialValues };
    setTabCorrectionPER(InitValues);
    handleCalculPlafondDeduction(InitValues, context.state.CorrectionPERMutuPlafond);
  };

  const handleChangeValue = (Year, Property, Value) => {
    let NewValues = _.cloneDeep(tabCorrectionPER);
    if (Property === "DeclaPERAssietteDisponible" || Property === "DeclaPERAssietteDisponibleConjoint") {
      NewValues[yearM3][Property] = Value;
      NewValues[yearM2][Property] = Value;
      NewValues[yearM1][Property] = Value;
      NewValues[year][Property] = Value;
    } else {
      NewValues[Year][Property] = Value;
    }
    setTabCorrectionPER(NewValues);
    handleCalculPlafondDeduction(NewValues, context.state.CorrectionPERMutuPlafond);
  };

  const handleCalcul = (Year, ClientConjoint) => {
    GetCalculCorrectionPER(tabCorrectionPER[Year], Year, ClientConjoint, "CalculCorrectionPER").then((res) => {
      if (typeof res === "object") {
        handleChangeValue(Year, "DispoPERCorrige" + ClientConjoint, res.DispoPERCorrige);
      }
    });
  };

  const handleCalculAll = (Property, Value, ClientConjoint) => {
    let NewValues = _.cloneDeep(tabCorrectionPER);
    if (Property === "DeclaPERAssietteDisponible" || Property === "DeclaPERAssietteDisponibleConjoint") {
      NewValues[yearM3][Property] = Value;
      NewValues[yearM2][Property] = Value;
      NewValues[yearM1][Property] = Value;
      NewValues[year][Property] = Value;
    }
    GetCalculCorrectionPER(NewValues, "", ClientConjoint, "CalculAllCorrectionPER").then((res) => {
      if (typeof res === "object") {
        NewValues[yearM3]["DispoPERCorrige" + ClientConjoint] = res[yearM3];
        NewValues[yearM2]["DispoPERCorrige" + ClientConjoint] = res[yearM2];
        NewValues[yearM1]["DispoPERCorrige" + ClientConjoint] = res[yearM1];
        NewValues[year]["DispoPERCorrige" + ClientConjoint] = res[year];
        setTabCorrectionPER(NewValues);
        handleCalculPlafondDeduction(NewValues, context.state.CorrectionPERMutuPlafond);
      }
    });
  };

  const handleMutuPlafond = (value) => {
    context.handleValueChange("CorrectionPERMutuPlafond", value);
    handleCalculPlafondDeduction(tabCorrectionPER, value);
  };
  const handleCalculPlafondDeduction = (Values, MutuPlafond) => {
    let PlafondClient =
      Number(Values[year]["DispoPERCorrige"]) + Number(Values[yearM1]["DispoPERCorrige"]) + Number(Values[yearM2]["DispoPERCorrige"]) + Number(Values[yearM3]["DispoPERCorrige"]);
    let PlafondConjoint =
      Number(Values[year]["DispoPERCorrigeConjoint"]) +
      Number(Values[yearM1]["DispoPERCorrigeConjoint"]) +
      Number(Values[yearM2]["DispoPERCorrigeConjoint"]) +
      Number(Values[yearM3]["DispoPERCorrigeConjoint"]);
    if (MutuPlafond === "oui") {
      PlafondClient += PlafondConjoint;
      PlafondConjoint = 0;
    }
    setPlafondClient(PlafondClient);
    setPlafondConjoint(PlafondConjoint);
    context.handleValueChange("CorrectionPER", Values);
    context.handleValueChange("CorrectionPERPlafondClient", PlafondClient);
    context.handleValueChange("CorrectionPERPlafondConjoint", PlafondConjoint);
  };

  return (
    <>
      <Column fill>
        <Row>
          <Column fill style={{ minWidth: "640px", maxWidth: "820px" }}>
            <CardBox bpad className="dark-title blockRoundedForce">
              <table className="simulation">
                <tbody>
                  <tr>
                    <td>Versements PER déductibles dans le cadre de l'article 163 quatervicies du CGI pour {year}</td>
                    <td>
                      <Button
                        className="optiIcon"
                        style={{ height: "30px", minHeight: "30px", minWidth: "30px", fontSize: "0.675rem", padding: "0" }}
                        onClick={() => {
                          handleResetCorrectionPER();
                        }}
                      >
                        <FaUndo />
                      </Button>
                    </td>
                    <td>
                      <Button
                        className="optiIcon"
                        style={{ height: "30px", minHeight: "30px", minWidth: "30px", fontSize: "0.675rem", padding: "0" }}
                        onClick={() => {
                          SyntheseCorrectionPER(context.state, { dispos: tabCorrectionPER, plafondClient: plafondClient, plafondConjoint });
                        }}
                      >
                        <FaPrint title="Imprimer" />
                      </Button>
                    </td>
                    <td>
                      <Button
                        className="optiIcon"
                        style={{ height: "30px", minHeight: "30px", minWidth: "30px", fontSize: "0.675rem", padding: "0" }}
                        onClick={() => {
                          setModalParam(true);
                        }}
                      >
                        <FaCog title="Paramétrage" />
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </CardBox>

            <CardBox className="blockRoundedForce" bpad>
              <table className="correctPER">
                <tbody>
                  <tr>
                    <td></td>
                    <td style={{ paddingLeft: "70px" }}>Client</td>
                    <td style={{ paddingLeft: "70px" }}>Conjoint</td>
                  </tr>
                  <tr>
                    <td>Plafond non utilisé pour les revenus de {yearM3}</td>
                    <td>
                      <TextField
                        className="CorrecPERInput"
                        value={tabCorrectionPER[yearM3]?.DispoPERCorrige}
                        onChange={(e) => {
                          handleChangeValue(yearM3, "DispoPERCorrige", e.target.value);
                        }}
                        dataType="uint"
                        format={{ thousands: " ", unit: " €" }}
                        iconAfter={
                          <Button
                            tabIndex="-1"
                            icon
                            onClick={() => {
                              setYearToModify(yearM3);
                              setClientConjoint("");
                              setOpenModal(true);
                            }}
                          >
                            <FaEdit />
                          </Button>
                        }
                      />
                    </td>
                    <td>
                      <TextField
                        className="CorrecPERInput"
                        value={tabCorrectionPER[yearM3]?.DispoPERCorrigeConjoint}
                        onChange={(e) => {
                          handleChangeValue(yearM3, "DispoPERCorrigeConjoint", e.target.value);
                        }}
                        dataType="uint"
                        format={{ thousands: " ", unit: " €" }}
                        iconAfter={
                          <Button
                            tabIndex="-1"
                            icon
                            onClick={() => {
                              setYearToModify(yearM3);
                              setClientConjoint("Conjoint");
                              setOpenModal(true);
                            }}
                          >
                            <FaEdit />
                          </Button>
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Plafond non utilisé pour les revenus de {yearM2}</td>
                    <td>
                      <TextField
                        className="CorrecPERInput"
                        value={tabCorrectionPER[yearM2]?.DispoPERCorrige}
                        onChange={(e) => {
                          handleChangeValue(yearM2, "DispoPERCorrige", e.target.value);
                        }}
                        dataType="uint"
                        format={{ thousands: " ", unit: " €" }}
                        iconAfter={
                          <Button
                            tabIndex="-1"
                            icon
                            onClick={() => {
                              setYearToModify(yearM2);
                              setClientConjoint("");
                              setOpenModal(true);
                            }}
                          >
                            <FaEdit />
                          </Button>
                        }
                      />
                    </td>
                    <td>
                      <TextField
                        className="CorrecPERInput"
                        value={tabCorrectionPER[yearM2]?.DispoPERCorrigeConjoint}
                        onChange={(e) => {
                          handleChangeValue(yearM2, "DispoPERCorrigeConjoint", e.target.value);
                        }}
                        dataType="uint"
                        format={{ thousands: " ", unit: " €" }}
                        iconAfter={
                          <Button
                            tabIndex="-1"
                            icon
                            onClick={() => {
                              setYearToModify(yearM2);
                              setClientConjoint("Conjoint");
                              setOpenModal(true);
                            }}
                          >
                            <FaEdit />
                          </Button>
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Plafond non utilisé pour les revenus de {yearM1}</td>
                    <td>
                      <TextField
                        className="CorrecPERInput"
                        value={tabCorrectionPER[yearM1]?.DispoPERCorrige}
                        onChange={(e) => {
                          handleChangeValue(yearM1, "DispoPERCorrige", e.target.value);
                        }}
                        dataType="uint"
                        format={{ thousands: " ", unit: " €" }}
                        iconAfter={
                          <Button
                            tabIndex="-1"
                            icon
                            onClick={() => {
                              setYearToModify(yearM1);
                              setClientConjoint("");
                              setOpenModal(true);
                            }}
                          >
                            <FaEdit />
                          </Button>
                        }
                      />
                    </td>
                    <td>
                      <TextField
                        className="CorrecPERInput"
                        value={tabCorrectionPER[yearM1]?.DispoPERCorrigeConjoint}
                        onChange={(e) => {
                          handleChangeValue(yearM1, "DispoPERCorrigeConjoint", e.target.value);
                        }}
                        dataType="uint"
                        format={{ thousands: " ", unit: " €" }}
                        iconAfter={
                          <Button
                            tabIndex="-1"
                            icon
                            onClick={() => {
                              setYearToModify(yearM1);
                              setClientConjoint("Conjoint");
                              setOpenModal(true);
                            }}
                          >
                            <FaEdit />
                          </Button>
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Plafond calculé sur les revenus de {yearM1}</td>
                    <td>
                      <TextField
                        className="CorrecPERInput"
                        value={tabCorrectionPER[year]?.DispoPERCorrige}
                        onChange={(e) => {
                          handleChangeValue(yearM1, "DispoPERCorrige", e.target.value);
                        }}
                        dataType="uint"
                        format={{ thousands: " ", unit: " €" }}
                        iconAfter={
                          <Button
                            tabIndex="-1"
                            icon
                            onClick={() => {
                              setYearToModify(year);
                              setClientConjoint("");
                              setOpenModal(true);
                            }}
                          >
                            <FaEdit />
                          </Button>
                        }
                      />
                    </td>
                    <td>
                      <TextField
                        className="CorrecPERInput"
                        value={tabCorrectionPER[year]?.DispoPERCorrigeConjoint}
                        onChange={(e) => {
                          handleChangeValue(year, "DispoPERCorrigeConjoint", e.target.value);
                        }}
                        dataType="uint"
                        format={{ thousands: " ", unit: " €" }}
                        iconAfter={
                          <Button
                            tabIndex="-1"
                            icon
                            onClick={() => {
                              setYearToModify(year);
                              setClientConjoint("Conjoint");
                              setOpenModal(true);
                            }}
                          >
                            <FaEdit />
                          </Button>
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Mutualisation des plafonds</td>
                    <td>
                      <DropDown
                        className="CorrecPERInput"
                        value={context.state.CorrectionPERMutuPlafond}
                        onChange={(e) => {
                          handleMutuPlafond(e.target.value);
                        }}
                        options={[
                          { value: "non", label: "Non" },
                          { value: "oui", label: "Oui" },
                        ]}
                      />
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Plafond de déduction {year}</td>
                    <td>
                      <TextField value={plafondClient} disabled dataType="uint" format={{ thousands: " ", unit: " €" }} />
                    </td>
                    <td>
                      <TextField value={plafondConjoint} disabled dataType="uint" format={{ thousands: " ", unit: " €" }} />
                    </td>
                  </tr>
                </tbody>
              </table>
            </CardBox>
          </Column>

          <CorrrectionPERAnnee
            Year={yearToModify}
            TabCorrectionPER={tabCorrectionPER}
            ClientConjoint={clientConjoint}
            ChangeValue={handleChangeValue}
            CalculCorrectionPER={handleCalcul}
            visible={openModal}
            onClose={() => {
              setOpenModal(false);
            }}
          />
          {/* MODAL PARAM RETRAITE */}
          <Modal
            width="750px"
            height="30%"
            title=" Paramétrage du disponible PER 163 quatervicies"
            icon={<FaGlobe />}
            visible={paramModal}
            onClose={() => {
              setModalParam(false);
            }}
          >
            <Pager
              pages={[
                {
                  content: (
                    <Column fill>
                      <CardBox>
                        <table className="correctPER">
                          <tbody>
                            <tr>
                              <td></td>
                              <td style={{ paddingLeft: "120px" }}>Client</td>
                              <td style={{ paddingLeft: "120px" }}>Conjoint</td>
                            </tr>
                            <tr>
                              <td>Assiette du disponible</td>
                              <td>
                                <DropDown
                                  value={tabCorrectionPER[yearM3]?.DeclaPERAssietteDisponible}
                                  onChange={(e) => {
                                    handleCalculAll("DeclaPERAssietteDisponible", e.target.value, "");
                                  }}
                                  options={[
                                    { value: "compris", label: "Bénéfice imposable (Madelin compris)" },
                                    { value: "exclu", label: "Bénéfice imposable (hors Madelin)" },
                                  ]}
                                />
                              </td>
                              <td>
                                <DropDown
                                  value={tabCorrectionPER[yearM3]?.DeclaPERAssietteDisponibleConjoint}
                                  onChange={(e) => {
                                    handleCalculAll("DeclaPERAssietteDisponibleConjoint", e.target.value, "Conjoint");
                                  }}
                                  options={[
                                    { value: "compris", label: "Bénéfice imposable (Madelin compris)" },
                                    { value: "exclu", label: "Bénéfice imposable (hors Madelin)" },
                                  ]}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </CardBox>
                    </Column>
                  ),
                },
              ]}
              page={0}
              navButtons={
                {
                  // validate: {
                  //   onClick: (evt) => {
                  //     setModalParam(false);
                  //     // props?.CalculCorrectionPER(Year,ClientConjoint);
                  //   },
                  // },
                }
              }
            />
          </Modal>
        </Row>
      </Column>
    </>
  );
};

export default CorrectionPER;
