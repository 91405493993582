import { useState, useContext, useEffect } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import { FaBookDead, FaCalendarDay, FaCoins, FaEuroSign, FaHistory, FaHouseUser, FaHSquare, FaLandmark, FaPiggyBank, FaSun, FaWheelchair } from "react-icons/fa";
import Pager from "components/ui/controls/pager/pager";
import DetailImpot from "./DetailImpot";
import DetailFicheDePaie from "./Salarie/DetailFicheDePaie";
import DetailPension from "../retraite/DetailPension";
import DetailRAM from "../retraite/DetailRAM";
import DetailSAMCRPCEN from "../retraite/DetailSAMCRPCEN";
import DetailTrimestre from "../retraite/DetailTrimestre";
import DetailDepart from "../retraite/DetailDepart";
import DetailReversion from "../retraite/DetailReversion";
import DetailDisponibleSalarie from "../disponible/DetailDisponibleSalarie";
import DetailIncapacite from "../prevoyance/DetailIncapacite";
import DetailDeces from "../prevoyance/DetailDeces";
import DetailInvaliditeSalarie from "../prevoyance/Salarie/DetailInvalidite";

const DetailSalariePageModal = (props) => {
  const context = useContext(AppContext);
  const [currentPage, setCurrentPage] = useState(0);

  const handleMenu = () => {
    let menu = [
      {
        label: "Cotisations",
        icon: <FaEuroSign />,
        content: <DetailFicheDePaie DetailFicheDePaie={props.DetailFicheDePaie} />,
      },
      {
        label: "Impôt",
        icon: <FaLandmark />,
        content: <DetailImpot DetailImpot={props.DetailImpot} DetailAssiette={props.DetailAssietteClient} DetailAssietteConjoint={props.DetailAssietteConjoint} />,
      },
      {
        label: "Disponibles",
        icon: <FaPiggyBank />,
        content: <DetailDisponibleSalarie GraphDisponible={props.GraphDisponible} GraphPerp={props.GraphPerp} />,
      },
      {
        label: "Incapacité",
        icon: <FaHSquare />,
        content: <DetailIncapacite Outil={""} Affichage={""} TabIJ={props.TabIJ} GraphIJ={props.GraphIJ} />,
      },
      {
        label: "Invalidité",
        icon: <FaWheelchair />,
        content: <DetailInvaliditeSalarie Outil={""} Affichage={""} TabInval={props.TabInval} GraphInval={props.GraphInval} />,
      },
      {
        label: "Décès",
        icon: <FaBookDead />,
        content: <DetailDeces Outil={""} Affichage={""} TabDeces={props.TabDeces} TabRente={props.TabRente} />,
      },
      {
        label: "Retraite",
        icon: <FaSun />,
        content: <DetailPension Outil={""} InfosPension={props.InfosRetraiteChoixDepart} />,
      },
      {
        label: "RAM",
        icon: <FaCoins />,
        content: <DetailRAM Outil={""} InfosRAM={props.InfosRetraiteChoixDepart?.InfosRetraite} />,
      },
      {
        label: "SAM CRPCEN",
        icon: <FaCoins />,
        content: <DetailSAMCRPCEN Outil={""} InfosRAM={props.InfosRetraiteChoixDepart?.InfosRetraite} />,
      },
      {
        label: "Trimestres",
        icon: <FaHistory />,
        content: <DetailTrimestre Outil={""} InfosTrimestres={props.InfosRetraiteChoixDepart} />,
      },
      {
        label: "Départ",
        icon: <FaCalendarDay />,
        content: <DetailDepart Outil={""} InfosDepart={props.InfosRetraite} ClientConjoint={props.ClientConjoint} />,
      },
      {
        label: "Réversions",
        icon: <FaHouseUser />,
        content: <DetailReversion Outil={""} InfosDepart={props.InfosRetraite} />,
      },
    ];

    if (!Boolean(props.InfosRetraiteChoixDepart?.InfosRetraite?.RegimeUnifie)) {
      menu = menu.filter((k) => k.label !== "RAM");
    }
    if (!Boolean(props.InfosRetraiteChoixDepart?.InfosRetraite?.Crpcen?.General?.DetailSAMCrpcen)) {
        menu = menu.filter((k) => k.label !== "SAM CRPCEN");
    }
    if (Boolean(props.InfosRetraiteChoixDepart?.InfosRetraite?.Crpcen?.General?.DetailSAMCrpcen) && props.InfosRetraiteChoixDepart?.InfosRetraite?.Crpcen?.General?.SAM===0) {
      menu = menu.filter((k) => k.label !== "SAM CRPCEN");
    }
    // if (context.state.StatutMarital !== "marie") {
    //   menu = menu.filter((k) => k.label !== "Réversions");
    // }

    if('AccessRemExpress' in props){
      menu=menu.slice(0,3)
    }
    if (props.InfosRetraiteChoixDepart?.TotalPensionBrute===0) {
      
      menu = menu.filter((k) => k.label !== "Départ");
      menu = menu.filter((k) => k.label !== "Réversions");
    }
    return menu;
  };
  useEffect(() => {
    setCurrentPage(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.visible]);
  return (
    <>
      <Pager
        withMenu
        pages={handleMenu()}
        page={currentPage}
        onPageChange={(page) => {
          setCurrentPage(page);
        }}
        navButtons={{
          previous: {},
          next: {},
          validate: {
            onClick: (evt) => {
              props.onClose();
            },
          },
        }}
      />
    </>
  );
};

export default DetailSalariePageModal;
