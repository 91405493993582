import React from "react";
import { useContext } from "react";
import { AppContext } from "components/app/RemExprertProvider";
import TextField from "components/ui/controls/text-field/text-field";
import CardBox from "components/ui/controls/card-box/card-box";
import Column from "components/ui/layout/column/column";
import DropDown from "components/ui/controls/drop-down/drop-down";
import Row from "components/ui/layout/row/row";
import StateBox from "components/ui/controls/state-box/state-box";
import CheckBox from "components/ui/controls/check-box/check-box";

const classe_retraiteCarpv = [
  {
    value: "SS1",
    label: "SS1",
  },
  {
    value: "SS2",
    label: "SS2",
  },
  {
    value: "S1",
    label: "S1",
  },
  {
    value: "S2",
    label: "S2",
  },
  {
    value: "A",
    label: "A",
  },
  {
    value: "B",
    label: "B",
  },
  {
    value: "C",
    label: "C",
  },
  {
    value: "D",
    label: "D",
  },
];
const ClasseRetraiteCnbf = [
  {
    value: "C1",
    label: "C1",
  },
  {
    value: "C2",
    label: "C2",
  },
  {
    value: "C3",
    label: "C3",
  },
  {
    value: "C3+",
    label: "C3+",
  },
];
const ClasseRetr = [
  {
    value: "NC",
    label: "NC",
  },
  {
    value: "A",
    label: "A",
  },
  {
    value: "B",
    label: "B",
  },
  {
    value: "C",
    label: "C",
  },
  {
    value: "D",
    label: "D",
  },
  {
    value: "E",
    label: "E",
  },
  {
    value: "F",
    label: "F",
  },
  {
    value: "G",
    label: "G",
  },
  {
    value: "H",
    label: "H",
  },
];
const Conventionne = [
  {
    value: "oui",
    label: "Oui",
  },
  {
    value: "non",
    label: "Non",
  },
];
const ConventionneCarmf = [
  {
    value: "non",
    label: "Non",
  },
  {
    value: "Secteur1",
    label: "Secteur 1",
  },
  {
    value: "Secteur2",
    label: "Secteur 2",
  },
];
const BiologisteConventionne = [
  {
    value: "oui",
    label: "Oui",
  },
  {
    value: "non",
    label: "Non",
  },
];
const CotisationCapi = [
  {
    value: "classe3",
    label: "3",
  },
  {
    value: "classe4",
    label: "4",
  },
  {
    value: "classe5",
    label: "5",
  },
  {
    value: "classe6",
    label: "6",
  },
  {
    value: "classe7",
    label: "7",
  },
  {
    value: "classe8",
    label: "8",
  },
  {
    value: "classe9",
    label: "9",
  },
  {
    value: "classe10",
    label: "10",
  },
  {
    value: "classe11",
    label: "11",
  },
  {
    value: "classe12",
    label: "12",
  },
  {
    value: "classe13",
    label: "13",
  },
];
const CategorieFonctionPublique = [
  {
    value: "sedentaire",
    label: "Sédentaire",
  },
  {
    value: "active",
    label: "Active",
  },
];

function Parametres(props) {
  const context = useContext(AppContext);
  let AnneeDebutPeriode = 0;
  let AnneeFinPeriode = 0;
  if (context.state.RetraiteDebutActivite.length === 8 && context.state.RetraiteFinActivite.length === 8) {
    AnneeDebutPeriode = context.state.RetraiteDebutActivite.slice(4);
    AnneeFinPeriode = context.state.RetraiteFinActivite.slice(4);
  }
  return (
    <>
      <Column fill>
        <CardBox>
          <Column fill>
            {(context.state.RetraiteActivite === "SalarieNonCadre" ||
              context.state.RetraiteActivite === "SalarieCadre" ||
              context.state.RetraiteActivite === "SalarieNonCadreCFE" ||
              context.state.RetraiteActivite === "SalarieCadreCFE" ||
              context.state.RetraiteActivite === "SalarieNonCadreAgricole" ||
              context.state.RetraiteActivite === "SalarieCadreAgricole" ||
              context.state.RetraiteActivite === "SalarieIrcantec" ||
              context.state.RetraiteActivite === "CavecSalarie" ||
              context.state.RetraiteActivite === "FonctionPublique") && (
              <Row>
                <Column>
                  <TextField
                    label="Taux d'activité"
                    value={context.state.TauxActivite}
                    onKeyUp={context.handleInputChange("TauxActivite")}
                    dataType="float"
                    format={{ thousands: " ", unit: "%", decimal: ",", precision: 2 }}
                    normal
                  />
                </Column>
              </Row>
            )}
            {(context.state.RetraiteActivite === "SalarieNonCadre" ||
              context.state.RetraiteActivite === "SalarieCadre" ||
              context.state.RetraiteActivite === "SalarieNonCadreCFE" ||
              context.state.RetraiteActivite === "SalarieCadreCFE" ||
              context.state.RetraiteActivite === "SalarieNonCadreAgricole" ||
              context.state.RetraiteActivite === "SalarieCadreAgricole" ||
              context.state.RetraiteActivite === "CavecSalarie") && (
              <>
                <Row tpad>
                  <h1>COTISATIONS RETRAITE AGIRC ARRCO</h1>
                </Row>
                <Row tpad>
                  <Column>
                    <table className="mono">
                      <thead>
                        <tr>
                          <th></th>
                          <th style={{ textAlign: "center" }}>Salarié</th>
                          <th style={{ textAlign: "center" }}>Patronale</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Tranche 1</td>
                          <td>
                            <TextField
                              value={context.state.ParamCotisations["AgircArrcoT1Sal"]}
                              onChange={context.handleParametrePeriode("AgircArrcoT1Sal")}
                              dataType="ufloat"
                              format={{ thousands: " ", unit: "%", decimal: ",", precision: 2 }}
                              normal
                              style={{ textAlign: "center" }}
                            />
                          </td>
                          <td>
                            <TextField
                              value={context.state.ParamCotisations["AgircArrcoT1Pat"]}
                              onChange={context.handleParametrePeriode("AgircArrcoT1Pat")}
                              dataType="ufloat"
                              format={{ thousands: " ", unit: "%", decimal: ",", precision: 2 }}
                              normal
                              style={{ textAlign: "center" }}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Tranche 2</td>
                          <td>
                            <TextField
                              value={context.state.ParamCotisations["AgircArrcoT2Sal"]}
                              onChange={context.handleParametrePeriode("AgircArrcoT2Sal")}
                              dataType="ufloat"
                              format={{ thousands: " ", unit: "%", decimal: ",", precision: 2 }}
                              normal
                              style={{ textAlign: "center" }}
                            />
                          </td>
                          <td>
                            <TextField
                              value={context.state.ParamCotisations["AgircArrcoT2Pat"]}
                              onChange={context.handleParametrePeriode("AgircArrcoT2Pat")}
                              dataType="ufloat"
                              format={{ thousands: " ", unit: "%", decimal: ",", precision: 2 }}
                              normal
                              style={{ textAlign: "center" }}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Column>
                </Row>
              </>
            )}

            {(context.state.RetraiteActivite === "SalarieNonCadreCFE" || context.state.RetraiteActivite === "SalarieCadreCFE") && (
              <Row tpad>
                <Column>
                  <DropDown
                    label="Affiliation AGIRC-ARRCO"
                    value={context.state.CFEAgircArrco}
                    onChange={context.handleInputChange("CFEAgircArrco")}
                    options={[
                      { value: "Oui", label: "Oui" },
                      { value: "Non", label: "Non" },
                    ]}
                    normal
                  />
                </Column>
              </Row>
            )}

            {context.state.RetraiteActivite === "SalarieIrcantec" && (
              <>
                <Row>
                  <Column>
                    <DropDown
                      label="Statut"
                      value={context.state.ParamCotisations["StatutIrcantec"]}
                      onChange={context.handleParametrePeriode("StatutIrcantec")}
                      options={[
                        { value: "1_100", label: "Salarié" },
                        { value: "2_66", label: "Praticien hospitalier temps plein avec activité libérale" },
                        { value: "3_100", label: " Praticien hospitalier temps plein sans activité libérale" },
                        { value: "4_100", label: "Praticien attaché et praticien attaché associé à temps plein" },
                        { value: "5_70", label: "Praticien attaché et praticien attaché associé à temps partiel" },
                        { value: "6_100", label: "Assistant des hôpitaux et assistant associé" },
                        { value: "7_100", label: "Praticien contractuel à temps plein" },
                        { value: "8_70", label: "Praticien contractuel à temps partiel" },
                      ]}
                      normal
                    />
                  </Column>
                </Row>
                <Row tpad>
                  <h1>COTISATIONS RETRAITE IRCANTEC (taux théoriques)</h1>
                </Row>
                <Row tpad>
                  <table className="mono">
                    <thead>
                      <tr>
                        <th></th>
                        <th style={{ textAlign: "center" }}>Salarié</th>
                        <th style={{ textAlign: "center" }}>Patronale</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Tranche A</td>
                        <td>
                          <TextField
                            value={context.state.ParamCotisations["IrcantecT1Sal"]}
                            onChange={context.handleParametrePeriode("IrcantecT1Sal")}
                            dataType="ufloat"
                            format={{ thousands: " ", unit: "%", decimal: ",", precision: 2 }}
                            normal
                            style={{ textAlign: "center" }}
                          />
                        </td>
                        <td>
                          <TextField
                            value={context.state.ParamCotisations["IrcantecT1Pat"]}
                            onChange={context.handleParametrePeriode("IrcantecT1Pat")}
                            dataType="ufloat"
                            format={{ thousands: " ", unit: "%", decimal: ",", precision: 2 }}
                            normal
                            style={{ textAlign: "center" }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Tranche B</td>
                        <td>
                          <TextField
                            value={context.state.ParamCotisations["IrcantecT2Sal"]}
                            onChange={context.handleParametrePeriode("IrcantecT2Sal")}
                            dataType="ufloat"
                            format={{ thousands: " ", unit: "%", decimal: ",", precision: 2 }}
                            normal
                            style={{ textAlign: "center" }}
                          />
                        </td>
                        <td>
                          <TextField
                            value={context.state.ParamCotisations["IrcantecT2Pat"]}
                            onChange={context.handleParametrePeriode("IrcantecT2Pat")}
                            dataType="ufloat"
                            format={{ thousands: " ", unit: "%", decimal: ",", precision: 2 }}
                            normal
                            style={{ textAlign: "center" }}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Row>
              </>
            )}

            {context.state.RetraiteActivite === "TrimSpecifique" && (
              <>
              <Row>
                <DropDown
                  label="Type de trimestre"
                  value={context.state.ParamCotisations["OptionTrimSpecifique"]}
                  onChange={context.handleParametrePeriode("OptionTrimSpecifique")}
                  options={[
                    { value: "option1", label: "Taux de pension" },
                    { value: "option2", label: "Taux de pension + durée d'assurance" },
                  ]}
                  normal
                />
              </Row>
              <Row>
                <DropDown
                  label="Type de trimestre"
                  value={context.state.ParamCotisations["TypeTrimSpecifique"]}
                  onChange={context.handleParametrePeriode("TypeTrimSpecifique")}
                  options={[
                    { value: "assimile", label: "Assimilé" },
                    { value: "cotise", label: "Cotisé" },
                  ]}
                  normal
                />
              </Row>
              </>
            )}

            {context.state.RetraiteActivite === "Cavamac" && (
              <>
                <table className="project-list-table table remove-table-border mt-4">
                  <thead>
                    <tr>
                      <th scope="col">Année</th>
                      <th scope="col">Commissions brutes</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(context.state.DetailRetraiteFutur).length > 0 &&
                      Object.keys(context.state.DetailRetraiteFutur).map((Annee, index) => {
                        return (
                          <tr key={"Cavamac" + index}>
                            <td>{Annee}</td>
                            <td>
                              <TextField
                                value={
                                  Boolean(context.state.ParamCotisations["TabCommissionBruteCavamac"][Annee])
                                    ? context.state.ParamCotisations["TabCommissionBruteCavamac"][Annee]["CommissionBrute"]
                                    : 0
                                }
                                onChange={(e) => {
                                  context.handleTableauCommission(index, Annee, e);
                                }}
                                dataType="uint"
                                format={{ thousands: " ", unit: " €" }}
                              />
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </>
            )}
            {context.state.RetraiteActivite === "Carpv" && (
              <Row>
                <DropDown
                  label="Classe retraite"
                  value={context.state.ParamCotisations["classe_retraiteCarpv"]}
                  onChange={context.handleParametrePeriode("classe_retraiteCarpv")}
                  options={classe_retraiteCarpv}
                  normal
                />
              </Row>
            )}

            {(context.state.RetraiteActivite === "Cnbf" || context.state.RetraiteActivite === "CnbfSalarie") && Number(context.state.RetraiteFinActivite.slice(4)) >= 2021 && (
              <Row>
                <DropDown
                  label="Classe retraite"
                  value={context.state.ParamCotisations["ClasseRetraiteCnbf"]}
                  onChange={context.handleParametrePeriode("ClasseRetraiteCnbf")}
                  options={ClasseRetraiteCnbf}
                  normal
                />
              </Row>
            )}
            {context.state.RetraiteActivite === "Cipav" && (
              <Row>
                <DropDown
                  label="Classe retraite jusqu'au 31/12/2022"
                  value={context.state.ParamCotisations["ClasseRetr"]}
                  onChange={context.handleParametrePeriode("ClasseRetr")}
                  options={ClasseRetr}
                  normal
                />
              </Row>
            )}
            {context.state.RetraiteActivite === "Cavec" && (
              <Row>
                <DropDown label="Classe retraite" value={context.state.ParamCotisations["ClasseRetr"]} onChange={context.handleParametrePeriode("ClasseRetr")} options={ClasseRetr} normal />
              </Row>
            )}
            {context.state.RetraiteActivite === "CavecSalarie" && (
              <>
                <Row tpad>
                  <h1>COTISATIONS CAVEC</h1>
                </Row>
                <Row tpad>
                  <DropDown
                    label="Classe retraite"
                    value={context.state.ParamCotisations["CavecSalarie"]}
                    onChange={context.handleParametrePeriode("CavecSalarie")}
                    options={[
                      { value: "C", label: "C" },
                      { value: "D", label: "D" },
                    ]}
                    normal
                  />
                </Row>
              </>
            )}
            {context.state.RetraiteActivite === "Crn" && (
              <>
                <Row>
                  <DropDown
                    label="Classe retraite"
                    value={context.state.ParamCotisations["ClasseRetraiteCrn"]}
                    onChange={context.handleParametrePeriode("ClasseRetraiteCrn")}
                    options={[
                      { value: "NC", label: "NC" },
                      { value: "1", label: "1" },
                      { value: "2", label: "2" },
                      { value: "3", label: "3" },
                      { value: "4", label: "4" },
                      { value: "5", label: "5" },
                      { value: "6", label: "6" },
                      { value: "7", label: "7" },
                      { value: "8", label: "8" },
                    ]}
                    normal
                  />
                </Row>
                <Row tpad>
                  <DropDown
                    label="Prestation de serment avant 2014"
                    labelStyle={{ width: "260px" }}
                    value={context.state.ParamCotisations["CrnSermentAvt2014"]}
                    onChange={context.handleParametrePeriode("CrnSermentAvt2014")}
                    options={[
                      {
                        value: false,
                        label: "Non",
                      },
                      {
                        value: true,
                        label: "Oui",
                      },
                    ]}
                    normal
                  />
                  <DropDown
                    hpad
                    label="Régime de Colmar et Metz"
                    value={context.state.ParamCotisations["CrnColmarMetz"]}
                    onChange={context.handleParametrePeriode("CrnColmarMetz")}
                    options={[
                      {
                        value: false,
                        label: "Non",
                      },
                      {
                        value: true,
                        label: "Oui",
                      },
                    ]}
                    normal
                  />
                </Row>
                <Row tpad>
                  <table className="project-list-table table remove-table-border mt-4">
                    <thead>
                      <tr>
                        <th>Année</th>
                        <th>Moyenne des produits</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.keys(context.state.DetailRetraiteFutur).length > 0 &&
                        Object.keys(context.state.DetailRetraiteFutur).map((Annee, index) => {
                          return (
                            <tr key={"Crn" + index}>
                              <td>{Annee}</td>
                              <td>
                                <TextField
                                  value={
                                    Boolean(context.state.ParamCotisations["TabMoyenneProduitEtudeCrn"][Annee])
                                      ? context.state.ParamCotisations["TabMoyenneProduitEtudeCrn"][Annee]["MoyenneProduit"]
                                      : 0
                                  }
                                  onChange={(e) => {
                                    context.handleTableauMoyenneProduit(index, Annee, e);
                                  }}
                                  dataType="uint"
                                  format={{ thousands: " ", unit: " €" }}
                                />
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </Row>
              </>
            )}
            {context.state.RetraiteActivite === "Chomage" && (
              <>
                <Row>
                  <DropDown
                    label="Cotisations AGIRC-ARRCO"
                    value={context.state.ParamCotisations["ChomageAgirArrco"]}
                    onChange={context.handleParametrePeriode("ChomageAgirArrco")}
                    options={[
                      { value: "Non", label: "Non" },
                      { value: "Oui", label: "Oui" },
                    ]}
                    normal
                  />
                </Row>

                {context.state.ParamCotisations["ChomageAgirArrco"] === "Oui" && (
                  <Row tpad>
                    <TextField
                      label="SALAIRE JOURNALIER DE REFERENCE"
                      value={context.state.ParamCotisations["ChomageSJR"]}
                      onChange={context.handleParametrePeriode("ChomageSJR")}
                      dataType="uint"
                      format={{ thousands: " ", unit: " €" }}
                      normal
                    />
                  </Row>
                )}
              </>
            )}
            {(context.state.RetraiteActivite === "Carcdsf" || context.state.RetraiteActivite === "Carcdsf2" || context.state.RetraiteActivite === "Carpimko") && (
              <>
                <Row>
                  <DropDown
                    label="Conventionné"
                    value={context.state.ParamCotisations["Conventionne"]}
                    onChange={context.handleParametrePeriode("Conventionne")}
                    options={Conventionne}
                    normal
                  />
                </Row>
                {context.state.ParamCotisations["Conventionne"] === "oui" && (
                   <Row tpad>
                    <TextField
                      value={context.state.ParamCotisations["TauxCpam"]}
                      onChange={context.handleParametrePeriode("TauxCpam")}
                      label="Taux de prise en charge CPAM"
                      dataType="ufloat"
                      format={{ thousands: " ", unit: "%", decimal: ",", precision: 2 }}
                      normal
                    />
                  </Row>
                )}
              </>
            )}
            {context.state.RetraiteActivite === "Carmf" && (
              <>
                <Row>
                  <DropDown
                    label="Conventionné"
                    value={context.state.ParamCotisations["ConventionneCarmf"]}
                    onChange={context.handleParametrePeriode("ConventionneCarmf")}
                    options={ConventionneCarmf}
                    normal
                  />
                </Row>
                {context.state.ParamCotisations["ConventionneCarmf"] !== "non" && (
                   <Row tpad>
                    <TextField
                      value={context.state.ParamCotisations["TauxCpam"]}
                      onChange={context.handleParametrePeriode("TauxCpam")}
                      label="Taux de prise en charge CPAM"
                      dataType="ufloat"
                      format={{ thousands: " ", unit: "%", decimal: ",", precision: 2 }}
                      normal
                    />
                  </Row>
                )}
              </>
            )}
            {context.state.RetraiteActivite === "Cavp" && (
              <>
                <Row>
                  <DropDown
                    label="Biologiste conventionné"
                    value={context.state.ParamCotisations["BiologisteConventionne"]}
                    onChange={context.handleParametrePeriode("BiologisteConventionne")}
                    options={BiologisteConventionne}
                    normal
                  />
                </Row>
                <Row tpad>
                  <DropDown
                    label="Classe retraite"
                    value={context.state.ParamCotisations["CotisationCapi"]}
                    onChange={context.handleParametrePeriode("CotisationCapi")}
                    options={CotisationCapi}
                    normal
                  />
                </Row>
                <Row tpad>
                  <TextField
                    label="Taux de rendement capi"
                    value={context.state[props.Outil + "TauxRendementCapi" + props.ClientConjoint]}
                    onChange={context.handleInputChange("TauxRendementCapi" + props.ClientConjoint)}
                    dataType="ufloat"
                    format={{ thousands: " ", precision: 2, decimal: ",", unit: "%" }}
                    normal
                  />
                </Row>
                <Row tpad>
                  <TextField
                    label="Taux de conversion capi"
                    value={context.state[props.Outil + "TauxConversionCapi" + props.ClientConjoint]}
                    onChange={context.handleInputChange("TauxConversionCapi" + props.ClientConjoint)}
                    dataType="ufloat"
                    format={{ thousands: " ", precision: 2, decimal: ",", unit: "%" }}
                    normal
                  />
                </Row>
              </>
            )}
            {context.state.RetraiteActivite === "FonctionPublique" && (
              <>
                <Row tpad>
                  <h1>Carrière</h1>
                </Row>
                <Row tpad>
                  <Column>
                    <DropDown
                      label="Catégorie"
                      value={context.state[props.Outil + "CategorieFonctionPublique" + props.ClientConjoint]}
                      onChange={context.handleInputChange("CategorieFonctionPublique" + props.ClientConjoint)}
                      options={CategorieFonctionPublique}
                      normal
                    />
                  </Column>
                </Row>

                <Row tpad>
                  <Column>
                    <CheckBox
                      label="Limite d'âge personnalisée"
                      before
                      inline
                      checked={true}
                      unchecked={false}
                      value={context.state[props.Outil + "LimiteAgePerso" + props.ClientConjoint]}
                      onChange={context.handleCheckChange("LimiteAgePerso" + props.ClientConjoint)}
                    />
                  </Column>
                </Row>
                <Row tpad>
                  <Column>
                    <TextField
                      value={context.state[props.Outil + "LimiteAgeAgePerso" + props.ClientConjoint]}
                      onChange={context.handleInputChange("LimiteAgeAgePerso" + props.ClientConjoint)}
                      disabled={context.state['LimiteAgePerso' + props.ClientConjoint] === true ? false : true}
                      dataType="uint"
                      format={{ thousands: " ", unit: " ans" }}
                      normal
                    />
                  </Column>
                  <Column>
                    <TextField
                      value={context.state[props.Outil + "LimiteAgeMoisPerso" + props.ClientConjoint]}
                      onChange={context.handleInputChange("LimiteAgeMoisPerso" + props.ClientConjoint)}
                      disabled={context.state['LimiteAgePerso' + props.ClientConjoint] === true ? false : true}
                      dataType="uint"
                      format={{ thousands: " ", unit: " mois" }}
                      normal
                    />
                  </Column>
                </Row>
                <Row tpad>
                  <h1>Majoration de trimestres</h1>
                </Row>
                <Row tpad>
                  <Column>
                    <TextField
                      label="Bonification (hors majoration enfant)"
                      labelStyle={{ width: "250px" }}
                      value={context.state[props.Outil + "Bonification" + props.ClientConjoint]}
                      onChange={context.handleInputChange("Bonification" + props.ClientConjoint)}
                      dataType="uint"
                      normal
                    />
                  </Column>
                </Row>
                <Row tpad>
                  <Column>
                    <TextField
                      label="MDA fonction publique hospitalière"
                      labelStyle={{ width: "250px" }}
                      value={context.state[props.Outil + "MDA" + props.ClientConjoint]}
                      onChange={context.handleInputChange("MDA" + props.ClientConjoint)}
                      dataType="uint"
                      normal
                    />
                  </Column>
                </Row>
                <Row tpad>
                  <h1>Rémunération</h1>
                </Row>
                <Row>
                  <Column>
                    <CheckBox
                      label="Indice majoré de fin de carrière"
                      before
                      inline
                      checked={true}
                      unchecked={false}
                      value={context.state[props.Outil + "IndiceMajorePerso" + props.ClientConjoint]}
                      onChange={context.handleCheckChange("IndiceMajorePerso" + props.ClientConjoint)}
                    />
                  </Column>
                  <Column>
                    <TextField
                      value={context.state[props.Outil + "IndiceMajoreValeur" + props.ClientConjoint]}
                      onChange={context.handleInputChange("IndiceMajoreValeur" + props.ClientConjoint)}
                      disabled={context.state[props.Outil + "IndiceMajorePerso" + props.ClientConjoint] === true ? false : true}
                      normal
                    />
                  </Column>
                </Row>

                <Row>
                  <Column>
                    <DropDown
                      label="ASSIETTE DE LA RAFP"
                      value={context.state[props.Outil + "AssietteRafp" + props.ClientConjoint]}
                      onChange={context.handleInputChange("AssietteRafp" + props.ClientConjoint)}
                      options={[
                        { value: "pourcentage", label: "% du traitement brut" },
                        { value: "forfaitaire", label: "Forfaitaire" },
                      ]}
                      normal
                    />
                  </Column>
                </Row>
                {context.state.AssietteRafp === "pourcentage" ? (
                  <Row>
                    <Column>
                      <TextField
                        label="Taux"
                        value={context.state[props.Outil + "TauxPrimeRAFP" + props.ClientConjoint]}
                        onChange={context.handleInputChange("TauxPrimeRAFP" + props.ClientConjoint)}
                        dataType="ufloat"
                        format={{ thousands: " ", unit: "%", decimal: ",", precision: 2 }}
                        normal
                      />
                    </Column>
                  </Row>
                ) : (
                  <Row>
                    <Column>
                      <TextField
                        label="Montant"
                        value={context.state[props.Outil + "MontantPrimeRAFP" + props.ClientConjoint]}
                        onChange={context.handleInputChange("MontantPrimeRAFP" + props.ClientConjoint)}
                        dataType="uint"
                        format={{ thousands: " ", unit: " €" }}
                        normal
                      />
                    </Column>
                  </Row>
                )}
              </>
            )}
          </Column>
        </CardBox>
      </Column>
    </>
  );
}
export default Parametres;
